import { FormModel } from 'common/form-state-manager/FormModel'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsOptional } from 'class-validator'

export class SurveyThanksFormModel extends FormModel {

    @IsNotEmpty()
    thanksMessage: string

    @IsOptional()
    thanksCtaUrl: string

    @IsOptional()
    thanksCtaLabel: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
