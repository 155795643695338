import React, { useState } from 'react'
import { PageHeaderCP } from 'common/components/page-header/PageHeaderCP'
import styled from 'styled-components'
import { LoggedUserDataCardCP } from 'modules/user/components/logged-user-data-card/LoggedUserDataCardCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { ModalChangePasswordCP } from 'modules/user/components/modal-change-password/ModalChangePasswordCP'

/**
 * TELA perfil.
 */
export function UserProfileScreen(): JSX.Element {

    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState<boolean>(false)

    return (
        <>
            <PageHeaderCP title={'Meu Perfil'}>
                <ButtonSCP>
                    <ButtonCP
                        onClick={() => setIsPasswordModalVisible(true)}
                        disabled={AppStateUtils.getLoggedUserData()!.user.code === 1}
                    >
                        Alterar senha
                    </ButtonCP>
                </ButtonSCP>
            </PageHeaderCP>

            <ContentWrapperSCP>
                <CardContainer>

                    <LoggedUserDataCardCP/>

                    <ModalChangePasswordCP
                        visible={isPasswordModalVisible}
                        onClose={() => setIsPasswordModalVisible(false)}
                    />

                </CardContainer>
            </ContentWrapperSCP>
        </>
    )
}

const ContentWrapperSCP = styled.div`
    display: flex;
    overflow-x: auto;
    justify-content: center;
`

const CardContainer = styled.div`
    width: 800px;
`

const ButtonSCP = styled.div`

`
