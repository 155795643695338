import React from 'react'
import styled from 'styled-components'
import { HelpCP } from 'common/components/help/HelpCP'
import { IReferralProgramListItemResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramListItemResponseDTO'
import { ChartLineOrBarCP, ChartSeriesTP } from 'submodules/nerit-framework-ui/common/components/chart/ChartLineOrBarCP'
import { TitleCP } from 'common/components/title/TitleCP'
import { FlexCP } from 'common/components/flex/FlexCP'

interface ICsatItemPreviewICPProps {
    referralProgram: IReferralProgramListItemResponseDTO
}

/**
 * Campos de cada item da listagem de campanhas de CSAT.
 */
export function ReferralProgramItemPreviewICP(props: ICsatItemPreviewICPProps): JSX.Element {

    const chartData: ChartSeriesTP[] = props.referralProgram.goalPerformedList.map((performance) => ({
        data: [performance.value],
        name: performance.name,
    }))

    return (
        <PreviewSCP>

            <TitleCP textSize={'normal'} underLine={true}>
                <FlexCP alignItems={'center'} justifyContent={'space-between'}>
                    <>{ `Programa de Indicação: ${props.referralProgram.title}` }</>
                    <HelpCP
                        text={'A barra mostra o número de pessoas que alcançaram cada uma das metas'}
                        type={'tooltip'}
                    />
                </FlexCP>
            </TitleCP>

            <ChartLineOrBarCP
                xAxis={['']}
                height={'100px'}
                stacked={true}
                ySeries={chartData}
                type={'bar'}
            />
        </PreviewSCP>
    )

}

const PreviewSCP = styled.div`
  padding: 20px 20px 0 20px;
  flex: 1;
  background: white;
  border-right: solid 1px #dedede;
`
