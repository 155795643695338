import React from 'react'
import { RowCP } from 'common/components/grid/RowCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { CreateAccountPersonFormModel } from 'modules/admin/components/wizard-create-account/inner/CreateAccountPersonFormModel'

interface ITrialPersonContentStepICPProps {
    formStateManager: IFormStateManager<CreateAccountPersonFormModel>
}

/**
 * COMPONENTE da etapa de dados do politico.
 */
export function CreateAccountPersonContentStepICP(props: ITrialPersonContentStepICPProps): JSX.Element | null {

    return (
        <>
            <RowCP>
                <InputCP
                    label={'Nome'}
                    formStateManager={props.formStateManager}
                    required={true}
                    fieldName={'name'}
                />
            </RowCP>
            <RowCP>
                <InputCP
                    label={'E-mail'}
                    formStateManager={props.formStateManager}
                    required={true}
                    fieldName={'email'}
                />
            </RowCP>
            <RowCP>
                <InputCP
                    label={'Celular'}
                    formStateManager={props.formStateManager}
                    required={true}
                    fieldName={'cellphone'}
                    mask={InputMaskTypeEnum.PHONE}
                />
            </RowCP>
        </>
    )
}
