import { Layout } from 'antd'
import styled from 'styled-components'
import React from 'react'

const CLASS_MAIN_LAYOUT = 'layout_main'

interface ILayoutCPProps {
    mainLayout?: boolean
    header?: JSX.Element
    content?: JSX.Element | false
    sider?: JSX.Element | false
    footer?: JSX.Element
}

/**
 * Componente que renderiza o layout padrao do sistema.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function LayoutCP(props: ILayoutCPProps): JSX.Element {
    return (
        <WrapperSCP className={!!props.mainLayout ? CLASS_MAIN_LAYOUT : ''}>
            <Layout>
                {props.header}

                <Layout>
                    {props.sider}
                    {props.content}
                </Layout>

                {props.footer}
            </Layout>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

    &,
    .ant-layout {
        overflow: hidden;
        height: 100%;
        width: 100%;
    }

    &.${CLASS_MAIN_LAYOUT} {
        > .ant-layout {
            height: 100vh;
            width: 100vw;
        }
    }
`
