import React from 'react'
import styled from 'styled-components'
import { LoadingIconCP } from 'common/components/icons/LoadingIconCP'

interface ILoadingCPProps {
    show?: boolean
}

/**
 * Componente loading.
 */
export function LoadingCP(props: ILoadingCPProps): JSX.Element {

    if (props.show !== undefined && !props.show)
        return <></>

    return (
        <LoadingWrapperSCP>
            <LoadingIconCP/>
        </LoadingWrapperSCP>
    )

}

const LoadingWrapperSCP = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

