import React, { useEffect, useState } from 'react'
import { UserProjectResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/UserProjectResponseDTO'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TableProjectUsersICP } from 'modules/admin/components/table-project-users/inner/TableProjectUsersICP'
import { ModalEditProjectUserCP } from 'modules/admin/components/modal-edit-project-user/ModalEditProjectUserCP'

interface ICPProps {
    projectCode: number
    reloadData: number
}

/**
 * Tabela de usuarios de um projeto.
 */
export function TableProjectUsersCP(props: ICPProps): JSX.Element {

    const [reloadTable, setReloadTable] = useState<number>()
    useEffect(() => setReloadTable(props.reloadData), [props.reloadData])

    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false)
    const [selectedProjectUser, setSelectedProjectUser] = useState<UserProjectResponseDTO>()

    return (
        <>
            <TableProjectUsersICP
                projectCode={props.projectCode}
                reloadData={reloadTable}
                onDelete={() => setReloadTable(TableUtils.getReloadNumber())}
                onEditProjectUser={(projectUser) => {
                    setSelectedProjectUser(projectUser)
                    setIsEditModalVisible(true)
                }}
            />

            {
                !!selectedProjectUser &&
                <ModalEditProjectUserCP
                    visible={isEditModalVisible}
                    onSave={() => {
                        setReloadTable(TableUtils.getReloadNumber())
                        setIsEditModalVisible(false)
                    }}
                    onCancel={() => setIsEditModalVisible(false)}
                    projectUser={selectedProjectUser}
                />
            }
        </>
    )
}
