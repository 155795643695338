import React from 'react'
import { TextAreaCP } from 'common/components/form-fields/text-area/TextAreaCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { ReferralProgramMainDataFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramMainDataFormModel'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { AutocompleteFranchiseCP } from 'modules/admin/components/autocomplete-franchise/AutocompleteFranchiseCP'

interface ICPProps {
    formStateManager: IFormStateManager<ReferralProgramMainDataFormModel>
}

/**
 * Dados principais do programa.
 */
export function ReferralProgramMainDataStepICP(props: ICPProps): JSX.Element {

    return (
        <>
            <InputCP
                label={'Nome do Programa'}
                required={true}
                formStateManager={props.formStateManager}
                fieldName={'name'}
                hint={{ type: 'tooltip', text: 'O nome será utilizado apenas internamente, para sua organização.' }}
            />

            <InputCP
                label={'Url da logo do Programa'}
                required={true}
                formStateManager={props.formStateManager}
                fieldName={'logoUrl'}
            />

            <TextAreaCP
                label={'Título'}
                required={true}
                formStateManager={props.formStateManager}
                fieldName={'title'}
            />

            <TextAreaCP
                label={'Subtítulo'}
                required={true}
                formStateManager={props.formStateManager}
                fieldName={'subtitle'}
            />

            <InputCP
                label={'Url para o regulamento'}
                required={true}
                formStateManager={props.formStateManager}
                fieldName={'consentTermUrl'}
            />

            <AutocompleteFranchiseCP
                label={'Unidades'}
                formStateManager={props.formStateManager}
                fieldName={'franchiseCodes'}
                isMultiple={true}
                required={true}
            />
        </>
    )
}
