import { FormModel } from 'common/form-state-manager/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { ChannelConfigTP } from 'submodules/space4leads-sdk/services/surveys/surveys/types/ChannelConfigTP'

export class SurveyChannelsFormModel extends FormModel {

    @IsRequired()
    channels: ChannelConfigTP[]

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
