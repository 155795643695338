/**
 * Classe para auxiliar na manipulacao do intercom
 */
export class IntercomUtils {

    static setIntercomVisibility(show: boolean): void {

        if (!(window as any).Intercom)
            return

        (window as any).Intercom(
            'update',
            {
                'hide_default_launcher': !show	// eslint-disable-line @typescript-eslint/naming-convention
            }
        )
    }
}
