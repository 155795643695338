import * as React from 'react'
import styled from 'styled-components'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { LoadingIconCP } from 'common/components/icons/LoadingIconCP'

type _ScpPropsTP = {
    size?: FontSizeTP,
    color?: string,
}

interface ILinkCPProps extends _ScpPropsTP {
    text: string
    onClick?: () => void
    loading?: boolean
}

/**
 * COMPONENTE: Link.
 *
 * @author StellaMCR
 * @author hjcostabr
 */
export function LinkCP(props: ILinkCPProps): JSX.Element {
    return (
        <WrapperSCP
            size={props.size}
            color={props.color}
            onClick={props.onClick}
        >
            {
                props.loading &&
                <LoadingIconCP/>
            }
            {props.text}
        </WrapperSCP>
    )
}

const WrapperSCP = styled.button<_ScpPropsTP>`
    
    color: ${props => props.color ?? props.theme.primaryColor};
    font-size: ${props => props.theme.fontSizes[props.size ?? 'normal']};
    cursor: pointer;
    opacity: 1;
    transition: opacity .3s;
    background: transparent;
    border: 0 none;
    padding: 0;
    
    &:hover {
        text-decoration: underline;
        opacity: .7;
    }
    
    display: flex;
    i {
      margin-right: 10px;
    }
`
