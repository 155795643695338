import React, { useEffect } from 'react'
import { CardDashboardWidgetCP } from 'modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { TitleCP } from 'common/components/title/TitleCP'
import styled from 'styled-components'
import { FlexCP } from 'common/components/flex/FlexCP'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { IReferralProgramStatusReportRequestDTO } from 'modules/reports/services/referral-program-reports/dtos/requests/IReferralProgramStatusReportRequestDTO'
import { ReferralProgramReportRequests } from 'modules/reports/services/referral-program-reports/ReferralProgramReportRequests'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { IWidgetResponseDTO } from 'modules/dashboard/services/dtos/response/IWidgetResponseDTO'
import { LoadingCP } from 'common/components/loading/LoadingCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { ReportsCommonFiltersFormModel } from 'modules/reports/components/drawer-referral-program-report-filters/inner/ReportsCommonFiltersFormModel'
import { DtoUtils } from 'common/dtos/DtoUtils'

interface ICPProps {
    filters: IFormStateManager<ReportsCommonFiltersFormModel>
    onLoadReport: () => void
    shouldLoadReport: boolean
}

/**
 * Analise do programa de indicacao
 */
export function WidgetsRowEventsConversionCP(props: ICPProps): JSX.Element {

    const getConversionsByEventReportRequest = useRequest<IGenericListResponseDTO<IWidgetResponseDTO>>()
    useEffect(onGetConversionsByEventReportRequestChange, [getConversionsByEventReportRequest.isAwaiting])

    useEffect(init, [props.shouldLoadReport])

    /**
     * Requisicao API.
     */
    function init(): void {

        if (!props.shouldLoadReport)
            return

        const filterValues = props.filters.getFormValues()
        const requestDto: IReferralProgramStatusReportRequestDTO = {
            dateRange: DtoUtils.convertToDateRangeDto(filterValues?.dateRange),
            referralProgramCode: filterValues?.referralProgramCode,
            franchiseCodes: filterValues?.franchisesCodes,
        }

        getConversionsByEventReportRequest.runRequest(ReferralProgramReportRequests.getConversionsByEventReport(requestDto))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetConversionsByEventReportRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getConversionsByEventReportRequest, 'Não foi possível obter dados do relatório'))
            return

        props.onLoadReport()
    }

    return (
        <GoalsWrapperSCP>
            <TitleCP underLine={true} textSize={'normal'} marginBottom={30}>Conversões dos Eventos</TitleCP>
            <LoadingCP show={getConversionsByEventReportRequest.isAwaiting}/>
            <FlexCP alignItems={'flex-start'}>
                <>
                    {
                        getConversionsByEventReportRequest.responseData?.list.map((eventPerformance) => (
                            <WidgetsTopItemSCP flex={'1'}>
                                <CardDashboardWidgetCP
                                    widgetResponseDto={eventPerformance}
                                    targetLabel={'Total'}
                                />
                            </WidgetsTopItemSCP>
                        ))
                    }
                </>
            </FlexCP>
        </GoalsWrapperSCP>
    )
}

const WidgetsTopItemSCP = styled.div<{ flex: string }>`
  flex: ${props => props.flex}
`

const GoalsWrapperSCP = styled.div`
  margin-top: 40px;
`
