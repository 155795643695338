import { LayoutSubmenuContentCP } from 'common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import React, { useState } from 'react'
import { HeaderButtonICP } from 'common/components/screen-layout/header/inner/HeaderButtonICP'
import { RoutingHelper } from 'config/RoutingHelper'
import { AdminRoutes } from 'modules/admin/AdminRoutes'
import { TableAccountsCP } from 'modules/admin-space/components/table-accounts/TableAccountsCP'
import { DrawerAccountManagementCP } from 'modules/admin-space/components/drawer-account-management/DrawerAccountManagementCP'
import { AccountResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/AccountResponseDTO'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

/**
 * Tela de listagem de contas.
 */
export function ScreenContentAccountsCP(): JSX.Element {

    const [isAccountDrawerVisible, setIsAccountDrawerVisible] = useState<boolean>(false)
    const [reloadAccounts, setReloadAccounts] = useState<number>(TableUtils.getReloadNumber())
    const [selectedAccount, setSelectedAccount] = useState<AccountResponseDTO>()

    return (
        <>
            <HeaderCP title={'Contas'}>
                <HeaderButtonICP
                    onClick={() => RoutingHelper.openInNewTab(AdminRoutes.CREATE_ACCOUNT)}
                    icon={'plus'}
                    label={'Nova Conta'}
                />
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <TableAccountsCP
                    onSelectAccount={(account) => {
                        setSelectedAccount(account)
                        setIsAccountDrawerVisible(true)
                    }}
                    reloadTable={reloadAccounts}
                />
            </LayoutSubmenuContentCP>

            {
                !!selectedAccount &&
                <DrawerAccountManagementCP
                    visible={isAccountDrawerVisible}
                    onSave={() => {
                        setIsAccountDrawerVisible(false)
                        setReloadAccounts(TableUtils.getReloadNumber())
                    }}
                    onCancel={() => setIsAccountDrawerVisible(false)}
                    account={selectedAccount}
                />
            }
        </>
    )
}
