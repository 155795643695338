import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { FormModel } from 'common/form-state-manager/FormModel'
import { IsOptional } from 'class-validator'

export class GoalFormModel extends FormModel {

    @IsOptional()
    code?: number

    @IsNotEmpty()
    title: string

    @IsNotEmpty()
    rule: string

    @IsNotEmpty()
    prize: string

    @IsNotEmpty()
    quantity: number // Quantas pessoas precisam bater essa meta para que ela seja considerada batida.

    @IsNotEmpty()
    eventCodes: number[]

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
