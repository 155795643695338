import React, { useState } from 'react'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { HeaderButtonICP } from 'common/components/screen-layout/header/inner/HeaderButtonICP'
import { RoutingHelper } from 'config/RoutingHelper'
import { NpsRoutes } from 'modules/survey/routes/NpsRoutes'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { LinkHtmlCP } from 'submodules/nerit-framework-ui/common/components/link-html/LinkHtmlCP'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { FlexCP } from 'common/components/flex/FlexCP'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'
import { ScreenContentNpsHome } from 'modules/survey/screens/screen-nps-home/content/ScreenContentNpsHome'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { WrapperNpsCP } from 'submodules/space4leads-components-ui/survey/components/wrapper-nps/WrapperNpsCP'

/**
 * TELA de listagem de Disparos
 */
export function ScreenNpsHome(): JSX.Element {

    const [nps, setNps] = useState<SurveyResponseDTO>()

    return (
        <AccessControlCP permissions={[PermissionEnum.ADMIN, PermissionEnum.EDITOR]} redirect={true}>
            <WrapperNpsCP onGetNps={setNps}>

                <HeaderCP
                    title={'Meu NPS'}
                >
                    {
                        !!nps &&
                        <LinkHtmlCP
                            href={nps?.mainSurveyLink}
                            type={'ghost'}
                        >
                            Ir para página do NPS
                        </LinkHtmlCP>
                    }

                    <AccessControlCP permissions={[PermissionEnum.ADMIN, PermissionEnum.EDITOR]}>
                        <HeaderButtonICP
                            onClick={() => RoutingHelper.historyPush(NpsRoutes.SETUP)}
                            icon={'setting'}
                            label={'Configurar NPS'}
                        />
                    </AccessControlCP>
                </HeaderCP>

                <ContentCP overflowVertical={true}>
                    {
                        !!nps
                            ?
                            <ScreenContentNpsHome surveyCode={nps.code}/>
                            :
                            <FlexCP justifyContent={'center'} marginTop={50}>
                                <EmptyCP
                                    description={'Você ainda não tem uma pesquisa de NPS. Clique no botão acima para configurar.'}
                                />
                            </FlexCP>
                    }
                </ContentCP>

            </WrapperNpsCP>
        </AccessControlCP>
    )

}
