import React, { useEffect } from 'react'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { FlexCP } from 'common/components/flex/FlexCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { GoalFormModel } from 'modules/referral-program/components/modal-goal/inner/GoalFormModel'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { GoalRequests } from 'modules/referral-program/services/goal/GoalRequests'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { NotificationHelper } from 'common/helpers/NotificationHelper'

interface ICPProps {
    dataSourceItem: GoalFormModel
    itemOrder: number
    onRemove: () => void
    onEdit: () => void
}

/**
 * COMPONENTE da item da tabela de signatarios.
 */
export function DnDGoalConfigItemICP(props: ICPProps): JSX.Element | null {

    const deleteRequest = useRequest<void>('none')
    useEffect(onDeleteRequestChange, [deleteRequest.isAwaiting])

    /**
     * Remover.
     */
    function deleteGoal(): void {

        // Se nao tiver codigo, nao chama api de remocao, apenas retorna para remover na tela
        if (!props.dataSourceItem.code) {
            props.onRemove()
            return
        }

        deleteRequest.runRequest(GoalRequests.delete(props.dataSourceItem.code))
    }

    /**
     *  Retorno da remocao de conta.
     */
    function onDeleteRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteRequest, 'Não foi remover a meta', true))
            return

        NotificationHelper.error('Pronto', 'Meta removida com sucesso')
        props.onRemove()
    }

    return (
        <ListItemICP
            title={
                <FlexCP justifyContent={'space-between'} alignItems={'center'}>
                    <div>{props.dataSourceItem.title}</div>
                    <FlexCP>
                        <ButtonCP
                            size={'small'}
                            onClick={props.onEdit}
                            type={'ghost'}
                            marginRight={5}
                            icon={'edit'}
                        />
                        <ButtonCP
                            size={'small'}
                            onClick={deleteGoal}
                            confirmMsg={'Tem certeza que deseja remover esta meta? Todos os dados serão perdidos!'}
                            icon={'delete'}
                            type={'danger'}
                        />
                    </FlexCP>
                </FlexCP>
            }
        />
    )
}
