import { Alert } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { AlertCPTypeTP } from 'submodules/nerit-framework-ui/common/components/alert/inner/AlertCPTypeTP'

interface SCPProps {
    borderType?: 'rounded' | 'square'
    onClick?: () => void
}

interface IAlertCPProps extends BasicStyleWrapperCPProps, SCPProps {
    message: string | React.ReactNode
    description?: string | JSX.Element
    type: AlertCPTypeTP
    showIcon?: boolean
    show?: boolean
}

/**
 * Exibe mensagem estatica para notificacao de forma destacada.
 */
export function AlertCP(props: IAlertCPProps): JSX.Element {

    if (props.show === false)
        return <></>

    return (
        <BasicStyleWrapperCP margin={props.margin}>
            <WrapperSCP
                onClick={props.onClick}
                borderType={props.borderType}
            >
                <Alert
                    message={props.message}
                    showIcon={props.showIcon}
                    description={props.description}
                    type={props.type}
                />
            </WrapperSCP>
        </BasicStyleWrapperCP>
    )
}

const WrapperSCP = styled.div<SCPProps>`
  cursor: ${props => !!props.onClick ? 'pointer' : undefined};

  .ant-alert {
    border-radius: ${props => props.borderType === 'square' ? '0px' : undefined}; 
  }
`
