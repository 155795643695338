import React from 'react'
import { NpsResponderTypeEnum } from 'submodules/space4leads-sdk/services/surveys/survey-answers/enums/NpsResponderTypeEnum'
import { NpsUtils } from 'submodules/space4leads-components-ui/survey/utils/NpsUtils'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'

interface INpsTotalResponderByTypeCPProps {
    type: NpsResponderTypeEnum
    size?: number
    considerColorOfType?: boolean
}

/**
 * Icone que representa o que uma pessoa respondeu
 */
export function IconNpsResponderCP(props: INpsTotalResponderByTypeCPProps): JSX.Element {

    let icon: string
    switch (props.type) {

        case NpsResponderTypeEnum.DETRACTOR:
            icon = 'frown'
            break

        case NpsResponderTypeEnum.PASSIVE:
            icon = 'meh'
            break

        case NpsResponderTypeEnum.PROMOTER:
            icon = 'smile'
            break

        default:
            return <></>
    }

    return (
        <IconICP
            iconName={icon}
            size={props.size ?? 14}
            color={props.considerColorOfType ? NpsUtils.getColorByReponderType(props.type) : undefined}
        />
    )

}
