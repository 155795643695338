const ThemeAnt = {

    // Propriedades customizadas
    secondaryColor: '#FFA328',
    tertiaryColor: '#ffffff',
    secondMenuBg: '#dae1ef',
    gray: '#c5c5c5',
    darkGray: '#898989',

    fontSizes: {
        extraSmall: '.6rem',
        small: '.7rem',
        normal: '.85rem',
        large: '1rem',
        extraLarge: '1.3rem',
        title: '2.5rem',
    },

    // Propriedades do tema padrao do ant.d
    primaryColor: '#060641',
    infoColor: '#1aa2c4',
    successColor: '#27ae60',
    processingColor: '#1aa2c4',
    errorColor: '#c41a1a',
    highlightColor: '#c41a1a',
    warningColor: '#c4ab1a',
    normalColor: '#d9d9d9',
    white: '#ffffff',
    black: '#010102',

    fontFamily: 'Montserrat, sans-serif',
    borderColorBase: '#010102',
    inputBg: 'transparent',
    inputHoverBorderColor: '#010102',
    btnPrimaryBg: '#6d1c99',
    primary6: '#010102',
    linkColor: '#010102',

    tooltipBg: '#6d1c99',
    menuBg: '#ffffff',
    layoutBodyBackground: '#F1F1F1',
    layoutHeaderBackground: '#ffffff',
    layoutFooterBackground: '@layout-body-background',
    layoutHeaderHeight: '72px',
    layoutSiderBackground: '@layout-header-background',

    secondaryMenuColor: '#dae1ef',
    antPrimaryColor: '#060641',
};

module.exports = { ThemeAnt };
