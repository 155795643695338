import React from 'react'
import styled from 'styled-components'
import { WhatsappIconCP } from 'common/components/icons/WhatsappIconCP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { SystemConfig } from 'config/SystemConfig'
import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'

/**
 * Encapsula chat. Podem ser intercom ou whatsapp
 */
export function ChatBalloonCP(): JSX.Element {

    const loggedUser = AppStateUtils.getLoggedUserData()
    const domainData = AppStateUtils.getDomainData()

    if (SystemConfig.getInstance().environment !== EnvironmentEnum.PROD)
        return <></>

    if (domainData === null || loggedUser === null)
        return <></>

    return (
        <>
            {
                !!AppStateUtils.getCurrentAccount()!.account.disableDate &&
                <WhatsappWrapperSCP>
                    <a
                        href={'https://api.whatsapp.com/send?phone='}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                    >
                        <WhatsappIconCP size={30} color={'#FFF'}/>
                    </a>
                </WhatsappWrapperSCP>
            }
        </>
    )
}

const WhatsappWrapperSCP = styled.div`

    position: fixed;
    bottom: 20px;
    right: 100px;
    z-index: 1;
    width: 60px;
    height: 60px;

    background: #5cb85c;
    
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 70px;

`
