import React from 'react'
import { TitleCP } from 'common/components/title/TitleCP'

interface ICPProps {
    description: string
}

/**
 * Area com as regras do programa de indicacao.
 */
export function ReferralRuleCP(props: ICPProps): JSX.Element {

    return (
        <>
            <TitleCP underLine={true} textSize={'extraLarge'} marginTop={50}>
                Conheça nosso Programa de Indicação
            </TitleCP>

            {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
            <div dangerouslySetInnerHTML={{ __html: props.description }}/>
        </>
    )
}
