import React from 'react'
import { CollapseChannelsCP } from 'modules/survey/components/collapse-channels/CollapseChannelsCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { SurveyChannelsFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyChannelsFormModel'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'

interface ICPProps {
    survey?: SurveyResponseDTO
    channelsFormStateManager: IFormStateManager<SurveyChannelsFormModel>
    onEditing: () => void
}

/**
 * COMPOENENT Passo a passo para configurar a pesquisa NPS.
 */
export function SurveyChannelsStepICP(props: ICPProps): JSX.Element {

    if (!props.survey)
        return <></>

    return (
        <CollapseChannelsCP
            survey={props.survey}
            formStateManager={props.channelsFormStateManager}
            onEditing={props.onEditing}
        />
    )
}
