import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { SURVEY_LADING_PAGE_PARAM_PERSON_TOKEN, SURVEY_LADING_PAGE_PARAM_SCORE, SURVEY_LADING_PAGE_PARAM_TAGS, SurveyLandingPageScreenRoutePropsTP } from 'modules/survey/screens/screen-survey-landing-page/inner/types/SurveyLandingPageScreenRoutePropsTP'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { useLocation } from 'react-router'
import { SurveyCustomerViewCP } from 'submodules/space4leads-components-ui/survey/components/survey-customer-view/SurveyCustomerViewCP'
import { LogoCP } from 'common/components/logo/LogoCP'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { GetLastPersonAnswerRequestDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/request/GetLastPersonAnswerRequestDTO'
import { ScreenSurveyNotFound } from 'modules/survey/screens/screen-survey-not-found/ScreenSurveyNotFound'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { SurveyRoutes } from 'modules/survey/routes/SurveyRoutes'
import { SurveyAnswerResponseDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/response/SurveyAnswerResponseDTO'
import { SurveyAnswersRequests } from 'submodules/space4leads-sdk/services/surveys/survey-answers/SurveyAnswersRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { SurveysRequests } from 'submodules/space4leads-sdk/services/surveys/surveys/SurveysRequests'

/**
 * Landing Page para coletar as respostas de uma pesquisa
 */
export function ScreenSurveyLandingPage(): JSX.Element {

    const routeParams = useParams<SurveyLandingPageScreenRoutePropsTP>()
    const routeLocation = useLocation()

    useEffect(init, [])

    const [isRouteValid, setIsRouteValid] = useState<boolean>()
    const [paramScore, setParamScore] = useState<number>()
    const [surveyToken, setSurveyToken] = useState<string>()
    const [tags, setTags] = useState<string[]>()

    const getSurveyRequest = useRequest<SurveyResponseDTO>()
    useEffect(onGetSurveyRequestChange, [getSurveyRequest.isAwaiting])

    const getSurveyAnswerRequest = useRequest<SurveyAnswerResponseDTO>()
    useEffect(onGetSurveyAnswerRequestChange, [getSurveyAnswerRequest.isAwaiting])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        const query = new URLSearchParams(routeLocation.search)
        setParamScore(Number(query.get(SURVEY_LADING_PAGE_PARAM_SCORE)))

        if (!routeParams.token) {
            setIsRouteValid(false)
            return
        }

        const tags = query.get(SURVEY_LADING_PAGE_PARAM_TAGS) ?? ''
        setTags(tags.split(','))

        setSurveyToken(routeParams.token)
        getSurveyRequest.runRequest(SurveysRequests.findByToken(routeParams.token))
    }

    /**
     * Retorno dos detalhes da pesquisa
     */
    function onGetSurveyRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSurveyRequest, 'Não foi possível encontra a pesquisa informada')) {
            setIsRouteValid(false)
            return
        }

        const query = new URLSearchParams(routeLocation.search)
        const personToken = query.get(SURVEY_LADING_PAGE_PARAM_PERSON_TOKEN)
        if (!personToken) {
            setIsRouteValid(true)
            return
        }

        const dto: GetLastPersonAnswerRequestDTO = {
            personToken,
            surveyToken: surveyToken!
        }
        getSurveyAnswerRequest.runRequest(SurveyAnswersRequests.getLastPersonAnswer(dto))
    }

    /**
     * Retorno dos da resporta, caso o cliente ja tiver respondido
     */
    function onGetSurveyAnswerRequestChange(): void {

        if (getSurveyAnswerRequest.isAwaiting || !getSurveyAnswerRequest.wasTried)
            return

        const isSuccess = (getSurveyAnswerRequest.isSuccess && !!getSurveyAnswerRequest.responseData)
        if (!isSuccess && getSurveyAnswerRequest.responseStatus !== HttpStatusEnum.NOT_FOUND) {
            setIsRouteValid(false)
            return
        }

        setIsRouteValid(true)
    }

    if (isRouteValid === undefined || !getSurveyRequest.responseData || getSurveyAnswerRequest.isAwaiting || !surveyToken)
        return <LoadingOverlayCP show={true}/>

    if (!isRouteValid)
        return <ScreenSurveyNotFound/>

    return (
        <WrapperSCP>
            <LayoutWrapperSCP>
                <LayoutCP
                    header={<LogoCP imageUrl={getSurveyRequest.responseData.logoUrl} marginTop={10} marginBottom={30} align={'center'}/>}
                    content={
                        <SurveyCustomerViewCP
                            selectedScore={paramScore}
                            survey={getSurveyRequest.responseData}
                            surveyToken={surveyToken}
                            surveyAnswer={getSurveyAnswerRequest.responseData}
                            onSaveAnswer={() => RoutingHelper.historyPush(SurveyRoutes.SURVEY_RESULT_LANDING_PAGE.replace(':token', surveyToken))}
                            viewMode={'fullpage'}
                            tags={tags}
                        />
                    }
                />
            </LayoutWrapperSCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #fff;
`

const LayoutWrapperSCP = styled.div`
  margin: 0 auto 0;
  width: 95%;
  max-width: 750px;
  padding: 0 20px;
  height: 100%;
    
  .ant-layout {
    background: none;
  }
`
