import React, { useRef } from 'react'
import styled from 'styled-components'
import { TitleCP } from 'common/components/title/TitleCP'
import { SurveyQuestionBoxCP } from 'submodules/space4leads-components-ui/survey/components/survey-question-box/SurveyQuestionBoxCP'
import { CardCP } from 'common/components/card/CardCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { SurveyQuestionFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyQuestionFormModel'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'
import { SurveyThanksCP } from 'modules/survey/components/survey-thanks/SurveyThanksCP'
import { SurveyThanksFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyThanksFormModel'
import { NpsScreenPreviewViewTP } from 'modules/survey/components/survey-config/inner/NpsScreenPreviewViewTP'
import { SurveyTypeEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/SurveyTypeEnum'
import { SurveyQrCodeLayoutConfigPreviewICP } from 'modules/survey/components/survey-qrcode-layout-config/inner/SurveyQrCodeLayoutConfigPreviewICP'
import { SurveyChannelsFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyChannelsFormModel'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { useReactToPrint } from 'react-to-print'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { PrintAreaCP } from 'submodules/nerit-framework-ui/common/components/print-area-cp/PrintAreaCP'

interface ICPProps {
    survey?: SurveyResponseDTO
    questionConfigFormStateManager: IFormStateManager<SurveyQuestionFormModel>
    thanksConfigFormStateManager: IFormStateManager<SurveyThanksFormModel>
    channelsConfigFormStateManager: IFormStateManager<SurveyChannelsFormModel>
    previewView: NpsScreenPreviewViewTP
    type: SurveyTypeEnum
    reloadChannelInfo: number
}

/**
 * Content da TELA de Criar novo documento para assinatura.
 */
export function SurveyConfigPreviewICP(props: ICPProps): JSX.Element {

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        documentTitle: 's4l-survey',
        content: () => componentRef.current as any,
    })

    return (
        <ContentCP overflowVertical={true}>

            <WrapperSCP>

                <FlexCP justifyContent={'space-between'}>
                    <TitleCP>
                        👇🏻 Pré-visualização
                    </TitleCP>
                    {
                        props.previewView === 'channelView' &&
                        <ButtonCP
                            onClick={handlePrint}
                            icon={'printer'}
                        >
                            Imprimir
                        </ButtonCP>
                    }
                </FlexCP>

                <CardCP>
                    <ConditionalRenderCP shouldRender={props.previewView === 'surveyForm'}>
                        <SurveyQuestionBoxCP
                            type={props.type}
                            disabled={true}
                            questionLabels={{
                                mainQuestionLabel: props.questionConfigFormStateManager.getFieldValue('mainQuestionLabel'),
                                goodAnswerQuestionLabel: props.questionConfigFormStateManager.getFieldValue('goodAnswerQuestionLabel'),
                                badAnswerQuestionLabel: props.questionConfigFormStateManager.getFieldValue('badAnswerQuestionLabel'),
                            }}
                            showScoreAfterAnswer={true}
                        />
                    </ConditionalRenderCP>
                    <ConditionalRenderCP shouldRender={props.previewView === 'thanksForm'}>
                        <SurveyThanksCP
                            thanksMessage={props.thanksConfigFormStateManager.getFieldValue('thanksMessage')}
                            thanksCtaLabel={props.thanksConfigFormStateManager.getFieldValue('thanksCtaLabel')}
                            thanksCtaUrl={props.thanksConfigFormStateManager.getFieldValue('thanksCtaUrl')}
                            disabled={true}
                        />
                    </ConditionalRenderCP>
                    {
                        props.previewView === 'channelView' && !!props.survey &&
                        <PrintAreaCP ref={componentRef}>
                            <SurveyQrCodeLayoutConfigPreviewICP
                                survey={props.survey}
                                formStateManager={props.channelsConfigFormStateManager}
                                reloadChannelInfo={props.reloadChannelInfo}
                            />
                        </PrintAreaCP>
                    }
                </CardCP>

            </WrapperSCP>

        </ContentCP>
    )
}

const WrapperSCP = styled.div`
    padding: 20px;
`
