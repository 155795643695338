import { ScreenSubMenuTP } from 'common/components/screen-layout-submenu/submenu/inner/ScreenSubMenuItemTP'
import { AdminRoutes } from 'modules/admin/AdminRoutes'
import { ScreenContentFranchises } from 'modules/admin/screens/screen-admin/contents/ScreenContentFranchises'
import React from 'react'
import { ScreenContentAccountDetails } from 'modules/admin/screens/screen-admin/contents/ScreenContentAccountDetails'

export const ADMIN_SUBMENU_SYSTEM_KEY = 'system'
export type AdminViewTP = 'systemAccountView' | 'franchisesView'

/**
 * UTILS
 * Reune metodos auxiliares para gestao da tela CONFIGURACOES.
 */
export const AdminScreenUtils = {

    getSubMenuConfigs(): Array<ScreenSubMenuTP<AdminViewTP>> {
        return [
            {
                key: ADMIN_SUBMENU_SYSTEM_KEY,
                title: 'Sistema',
                enabled: true,
                items: [
                    {
                        name: 'Detalhes da Conta',
                        view: 'systemAccountView',
                        route: AdminRoutes.SYSTEM_ACCOUNT,
                        content: <ScreenContentAccountDetails/>,
                        contentTitle: 'Detalhes da sua Conta'
                    },
                    {
                        name: 'Unidades',
                        view: 'franchisesView',
                        route: AdminRoutes.FRANCHISES,
                        content: <ScreenContentFranchises/>
                    },
                ]
            },
        ]
    },

}
