import React, { useState } from 'react'
import { TitleCP } from 'common/components/title/TitleCP'
import { FlexCP } from 'common/components/flex/FlexCP'
import { SwitchCP } from 'common/components/switch/SwitchCP'
import styled from 'styled-components'
import { INotificationByRecipientMessage } from 'modules/referral-program/components/drawer-referral-program-notifications/inner/INotificationByRecipientMessage'

interface ICPProps {
    notificationMessage: INotificationByRecipientMessage
    title: string
    content: JSX.Element
}

/**
 * Titulo do conteudo de cada tipo de disparo.
 */
export function ReferralProgramCollpaseItemContentICP(props: ICPProps): JSX.Element {

    const [showContent, setShowContent] = useState<boolean>(props.notificationMessage.isActive)

    return (
        <WrapperSCP>
            <TitleCP underLine={true} textSize={'normal'}>
                <FlexCP alignItems={'center'}>
                    <SwitchCP
                        isTextInside={false}
                        marginRight={10}
                        isChecked={props.notificationMessage.isActive}
                        onChange={(isChecked) => {
                            props.notificationMessage.isActive = isChecked
                            setShowContent(isChecked)
                        }}
                    />
                    <>{props.title}</>
                </FlexCP>
            </TitleCP>

            {
                showContent &&
                <>{props.content}</>
            }
        </WrapperSCP>
    )

}

const WrapperSCP = styled.div`
    margin-bottom: 30px;
`
