import React, { useEffect, useRef } from 'react'
import ApexCharts from 'apexcharts'
import styled from 'styled-components'
import { CSAT_COLORS } from 'submodules/space4leads-components-ui/survey/components/buttons-survey-score/inner/SurveyButtonScoreColors'

interface IScpProps {
    height?: number
}

interface IChartLineOrBarCPProps extends IScpProps{
    mainValue: string
    data: number[]
}

/**
 * @TODO Fazer o grafico
 */
export function ChartRadialCP(props: IChartLineOrBarCPProps): JSX.Element {

    const chartRef = useRef<any>(null)
    const chartElement = useRef<any>(null)

    useEffect(onMount, [])
    useEffect(updateChartData, [])
    // useEffect(updateChartData, [props.ySeries, props.xAxis])

    function onMount(): void {
        chartElement.current = new ApexCharts(chartRef.current, {
            series: props.data,
            colors: [CSAT_COLORS[4], CSAT_COLORS[2], CSAT_COLORS[0]],
            chart: {
                height: props.height ?? 350,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                        },
                        total: {
                            show: true,
                            label: 'CSAT',
                            formatter: () => {
                                return props.mainValue
                            }
                        }
                    }
                }
            },
            labels: ['Satisfeitos (4~5)', 'Ok (3)', 'Insatisfeitos (1~2)'],
        })
        chartElement.current.render()
    }

    function updateChartData(): void {

        if (!chartElement.current)
        // if (!props.ySeries || !chartElement.current)
            return

        // chartElement.current.updateSeries(props.ySeries)
        // chartElement.current.updateOptions({
        //     xaxis: { categories: props.xAxis }
        // })
    }

    return (
        <ChartWrapperSCP>
            <div ref={chartRef}/>
        </ChartWrapperSCP>
    )
}

const ChartWrapperSCP = styled.div`
    height: 100%;
    width: 100%;
`
