import { RoutingHelper } from 'config/RoutingHelper'
import React from 'react'
import styled from 'styled-components'
import { SystemConfig } from 'config/SystemConfig'

/**
 * COMPONENTE
 * Logo no cabeçalho do menu.
 */
export function MenuLogoCP(): JSX.Element {
    return (
        <MenuLogoSCP href={RoutingHelper.getDefaultRoute()}>
            <img width={'57%'} src={`${process.env.PUBLIC_URL}/icone-sistema.png`} alt={SystemConfig.getInstance().appName}/>
        </MenuLogoSCP>
    )
}

const MenuLogoSCP = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
`
