import React, { useEffect, useState } from 'react'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { TitleCP } from 'common/components/title/TitleCP'
import { DnDReferralProgramGoalConfigCP } from 'modules/referral-program/components/dnd-referral-program-goal-config/DnDReferralProgramGoalConfigCP'
import { ModalGoalCP } from 'modules/referral-program/components/modal-goal/ModalGoalCP'
import { ReferralProgramGoalFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramGoalFormModel'
import { GoalFormModel } from 'modules/referral-program/components/modal-goal/inner/GoalFormModel'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { IGoalResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IGoalResponseDTO'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { FlexCP } from 'common/components/flex/FlexCP'
import { ButtonCP } from 'common/components/button/ButtonCP'

interface ICPProps {
    formStateManager: IFormStateManager<ReferralProgramGoalFormModel>
}

/**
 * COMPOENENT Etapa de configuracoes do agradecimento.
 */
export function ReferralProgramGoalsStepICP(props: ICPProps): JSX.Element {

    const [isGoalModalVisible, setIsGoalModalVisible] = useState<boolean>(false)
    const [selectedGoal, setSelectedGoal] = useState<GoalFormModel>()
    const [selectedGoalIndex, setSelectedGoalIndex] = useState<number>()

    const getGoalRequest = useRequest<IGoalResponseDTO>()
    useEffect(onGetGoalRequestChange, [getGoalRequest.isAwaiting])

    /**
     * Retorno da requisicao para pegar agenda passada como parametro
     */
    function onGetGoalRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getGoalRequest, 'Erro obter dados da meta'))
            return

        const responseData = getGoalRequest.responseData!
        setSelectedGoal(new GoalFormModel({
            code: responseData.code,
            title: responseData.title,
            rule: responseData.rule,
            prize: responseData.prize,
            quantity: responseData.quantity,
            eventCodes: responseData.configuredEvents?.map((evt) => evt.code),
        }))
        setIsGoalModalVisible(true)
    }

    /**
     * Ao fechar o modal de salvar a meta.
     */
    function onSaveGoal(goalFormValues: GoalFormModel): void {

        // Definindo array novo
        const newGoals = [...(props.formStateManager.getFieldValue('goals') ?? [])]

        // Se o index selecionado esta preenchido, significa que esta editando
        if (selectedGoalIndex !== undefined)
            newGoals[selectedGoalIndex] = { ...goalFormValues }
        else
            newGoals.push({ ...goalFormValues })

        props.formStateManager.changeFieldValue('goals', newGoals)
        NotificationHelper.info('Pronto', 'Meta salva com sucesso')

        setIsGoalModalVisible(false)
        setSelectedGoal(undefined)
        setSelectedGoalIndex(undefined)
    }

    /**
     * Ao remover uma meta.
     */
    function onRemoveGoal(index: number): void {

        // Definindo array novo
        const newGoals = [...(props.formStateManager.getFieldValue('goals') ?? [])]
        newGoals.splice(index, 1)
        props.formStateManager.changeFieldValue('goals', newGoals)
    }

    return (
        <>
            <TitleCP underLine={true} textSize={'normal'}>
                Configure as Metas
            </TitleCP>

            <FlexCP justifyContent={'flex-end'} marginBottom={10}>
                <ButtonCP
                    icon={'plus'}
                    onClick={() => {
                        setSelectedGoal(undefined)
                        setSelectedGoalIndex(undefined)
                        setIsGoalModalVisible(true)
                    }}
                >
                    Meta
                </ButtonCP>
            </FlexCP>
            <DnDReferralProgramGoalConfigCP
                goals={props.formStateManager.getFieldValue('goals')}
                onRemoveGoal={onRemoveGoal}
                onChangeOrder={(orderedGoals) => {
                    props.formStateManager.changeFieldValue('goals', orderedGoals)
                }}
                onEditGoal={(goal, index) => {
                    setSelectedGoal(goal)
                    setSelectedGoalIndex(index)
                    setIsGoalModalVisible(true)
                }}
            />

            <ModalGoalCP
                visible={isGoalModalVisible}
                goal={selectedGoal}
                onSave={onSaveGoal}
                onCancel={() => {
                    setIsGoalModalVisible(false)
                    setSelectedGoal(undefined)
                }}
            />
        </>
    )
}
