import { DatePicker, Input } from 'antd'
import dateInputLocalePtBR from 'submodules/nerit-framework-ui/common/components/form-fields/input/inner/DateInputLocalePtBR.json'
import React from 'react'
import styled from 'styled-components'
import { FormItemICP } from '../inner/FormItemICP'
import { IFormItemInputCommonProps } from '../inner/interfaces/IFormItemInputCommonProps'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { LoadingIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/LoadingIconCP'
import { SearchIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/SearchIconCP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'

export type InputTypeTP = 'text' | 'email' | 'number' | 'password' | 'search' | 'date' | 'tel'

export interface IInputCPProps<FModelTP extends FormModel> extends IFormItemInputCommonProps<FModelTP> {
    type?: InputTypeTP
    icon?: JSX.Element
    min?: number
    max?: number
    loading?: boolean
    showTime?: boolean
    autoFocus?: boolean
    placeholder?: string
    prefix?: boolean
    allowClear?: boolean
    disabled?: boolean
    maxlength?: number
}

/**
 * Input generico para formularios.
 * NOTE: Caso este componente seja controlado via 'form state manager' seu valor nao eh determinado diretamente pela prop 'value'.
 */
export function InputCP<FModelTP extends FormModel = any>(props: IInputCPProps<FModelTP>): JSX.Element {

    function renderInput(): JSX.Element {

        let suffixIcon: JSX.Element | undefined

        if (!!props.loading)
            suffixIcon = <LoadingIconCP size={14}/>

        else if (!!props.icon)
            suffixIcon = props.icon

        else if (props.type === 'search')
            suffixIcon = <SearchIconCP size={14}/>

        if (props.type === 'date') {
            return (
                <DatePicker
                    locale={dateInputLocalePtBR}
                    suffixIcon={suffixIcon}
                    placeholder={props.placeholder}
                    showTime={props.showTime}
                    allowClear={props.allowClear}
                    disabled={props.disabled}
                />
            )
        }

        return (
            <Input
                type={props.type ?? 'text'}
                suffix={!props.prefix ? suffixIcon : undefined}
                prefix={props.prefix ? suffixIcon : undefined}
                min={props.min}
                max={props.max}
                disabled={props.disabled}
                autoFocus={props.autoFocus}
                allowClear={props.allowClear}
                placeholder={props.placeholder}
                maxLength={props.maxlength ?? (props.mask === InputMaskTypeEnum.PHONE ? 15 : undefined)}
            />
        )
    }

    return (
        <InputWrapperSCP fontSize={props.fontSize ?? 'normal'}>
            <FormItemICP<FModelTP, IFormItemInputCommonProps<FModelTP>>
                label={props.label}
                onChange={props.onChange}
                onBlur={props.onBlur}
                fieldName={props.fieldName}
                formStateManager={props.formStateManager}
                required={props.required}
                width={props.width}
                onFormSubmit={props.onFormSubmit}
                value={props.value}
                mask={props.mask}
                fontSize={props.fontSize}
                errorMessage={props.errorMessage}
                noValidation={props.noValidation}
                debug={props.debug}
                reloadField={props.reloadField}
                marginTop={props.marginTop}
                marginRight={props.marginRight}
                marginBottom={props.marginBottom}
                marginLeft={props.marginLeft}
                hint={props.hint}
                keepMask={props.keepMask}
            >
                {renderInput()}
            </FormItemICP>
        </InputWrapperSCP>
    )
}

const InputWrapperSCP = styled.div<{ fontSize: FontSizeTP }>`
    width: 100%;
`
