import React, { useState, useEffect } from 'react'
import { Popover } from 'antd'
import { TooltipPlacementTP } from 'common/components/tooltip/TooltipCP'

interface IPopOverCPProps {
    children: JSX.Element
    content?: JSX.Element | JSX.Element[] | false
    title?: string
    visible?: boolean
    trigger?: 'hover' | 'click' | 'focus'
    placement?: TooltipPlacementTP
    onClose?: () => void
    destroyOnHide?: boolean
}

/**
 * COMPONENTE
 * Exibe modal posicionado rente ao conteudo envolto pelo componente.
 *
 * @author renatofs
 * @author hjcostabr
 */
export function PopOverCP(props: IPopOverCPProps): JSX.Element {

    const [isVisible, setIsVisible] = useState<boolean>(false)

    useEffect(onVisiblePropChange, [props.visible])

    function onVisiblePropChange(): void {
        if (props.visible !== undefined)
            setIsVisible(props.visible)
    }

    function onVisibilityStateChange(_visible: boolean): void {
        setIsVisible(_visible)
        if (!_visible && !!props.onClose)
            props.onClose()
    }

    return (
        <Popover
            content={props.content}
            title={props.title}
            visible={isVisible}
            trigger={props.trigger ?? 'contextMenu'}
            placement={props.placement ?? 'top'}
            onVisibleChange={onVisibilityStateChange}
            destroyTooltipOnHide={props.destroyOnHide}
        >
            {props.children}
        </Popover>
    )
}
