import React from 'react'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { StringUtils } from 'common/utils/StringUtils'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { ScreenNpsConfig } from 'modules/survey/screens/screen-nps-config/ScreenNpsConfig'
import { ScreenNpsHome } from 'modules/survey/screens/screen-nps-home/ScreenNpsHome'

NpsRoutes.ROOT = '/nps'

NpsRoutes.SETUP = `${NpsRoutes.ROOT}/setup`

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(NpsRoutes.ROOT),
        path: NpsRoutes.ROOT,
        component: ScreenNpsHome,
        exact: true,
    },
    {
        key: StringUtils.getSlugStyleString(NpsRoutes.SETUP),
        path: NpsRoutes.SETUP,
        component: ScreenNpsConfig,
    },
]

/**
 * Componente que define as rotas para acesso ao modulo de NPS
 */
export function NpsRoutes(): JSX.Element {

    return (
        <AccessControlCP>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}

