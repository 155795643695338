import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired, IsString } from 'submodules/nerit-framework-utils/validators'
import { IsEmail } from 'submodules/nerit-framework-utils/validators/decorators/IsEmail'
import { IsPhoneBR } from 'submodules/nerit-framework-utils/validators/decorators/IsPhoneBR'

export class CreateAccountPersonFormModel extends FormModel {

    @IsRequired()
    @IsString()
    name: string

    @IsRequired()
    @IsEmail()
    email: string

    @IsRequired()
    @IsPhoneBR()
    cellphone: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
