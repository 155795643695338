import { List } from 'antd'
import { ListProps } from 'antd/lib/list'
import styled from 'styled-components'
import React from 'react'

type _ScpPropsTP = {
    paddingRight?: number,
    paddingLeft?: number,
    paddingTop?: number,
    paddingBottom?: number,
}

interface IListCPProps<ListItemTP> extends ListProps<ListItemTP>, _ScpPropsTP {}

/**
 * Componente de Lista
 */
export function ListCP<ListItemTP>(props: IListCPProps<ListItemTP>): JSX.Element {

    return (
        <WrapperSCP
            paddingTop={props.paddingTop}
            paddingRight={props.paddingRight}
            paddingBottom={props.paddingBottom}
            paddingLeft={props.paddingLeft}
        >
            <List<ListItemTP>
                bordered={true}
                {...props}  // eslint-disable-line react/jsx-props-no-spreading
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`

    height: 100%;

    .ant-list-item {
        padding: ${props => `${props.paddingTop!}px ${props.paddingRight!}px ${props.paddingBottom!}px ${props.paddingLeft!}px`} !important;
    }

    .ant-list {

        height: 100%;

        .ant-spin-nested-loading {
            flex: 1;
        }

        .ant-list-pagination {
            padding: 5px 0;
            margin: 16px 0;
            display: flex;
            justify-content: center;
        }
        .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-disabled{
            border-color: ${props => props.theme.normalColor}
        }
        .ant-pagination-next .ant-pagination-item-link,  .ant-pagination-disabled{
            border-color: ${props => props.theme.normalColor}
        }
    }
    .ant-list-bordered {
            border-color: ${props => props.theme.normalColor};
        }
`
