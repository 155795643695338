import React from 'react'
import { PermissionEnum, PermissionLabelEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'

/**
 * Select das permissoes
 */
export function SelectPermissionCP(props: IFormItemCommonProps): JSX.Element {

    return (
        <SelectCP
            options={
                Object.values(PermissionEnum).map((permission) => ({
                    label: PermissionLabelEnum[permission],
                    value: permission
                }))
            }
            label={'Permissão'}
            required={props.required}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            errorMessage={props.errorMessage}
        />
    )
}
