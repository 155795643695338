import React from 'react'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { StringUtils } from 'common/utils/StringUtils'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { ScreenAdmin } from 'modules/admin/screens/screen-admin/ScreenAdmin'

AdminRoutes.ROOT = '/admin'

AdminRoutes.SYSTEM_ACCOUNT = `${AdminRoutes.ROOT}/sistema`
AdminRoutes.FRANCHISES = `${AdminRoutes.ROOT}/unidades`
AdminRoutes.NPS_QRCODE_LAYOUT = `${AdminRoutes.ROOT}/nps-layout-qrcode`

AdminRoutes.CREATE_ACCOUNT = '/create-account'

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(AdminRoutes.ROOT),
        path: AdminRoutes.ROOT,
        component: ScreenAdmin,
    },
]

/**
 * Componente que define as rotas para acesso ao CRM
 */
export function AdminRoutes(): JSX.Element {

    return (
        <AccessControlCP permissions={[PermissionEnum.ADMIN]}>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}
