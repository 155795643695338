import React from 'react'
import { ProjectsRequests } from 'submodules/space4leads-sdk/services/accounts/ProjectsRequests'
import { ProjectResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/ProjectResponseDTO'
import { TagActiveCP } from 'submodules/nerit-framework-ui/common/components/tag/tag-active/TagActiveCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'

interface ICPProps {
    accountCode: number
    wrappedOnCard?: boolean
    reloadTable: number
    onSelectProject: (project: ProjectResponseDTO) => void
    onEditUser: (project: ProjectResponseDTO) => void
}

/**
 * Tabela de projetos.
 */
export function TableProjectsInnerICP(props: ICPProps): JSX.Element {

    return (
        <TableFromApiCP<ProjectResponseDTO>
            appearance={{
                wrappedOnCard: props.wrappedOnCard,
            }}
            shouldLoadData={props.reloadTable}
            apiConfig={{
                requestConfigTP: () => ProjectsRequests.search({ accountCode: props.accountCode })
            }}
            columns={[
                {
                    title: 'Cód.',
                    render: (text, record) => record.code
                },
                {
                    render: (text, record) => <TagActiveCP isActive={record.isActive}/>
                },
                {
                    title: 'Nome do Projeto',
                    render: (text, record) => record.name
                },
                {
                    title: 'Data de Criação',
                    render: (text, record) => DateUtils.formatDate(record.creationDate, DateFormatEnum.BR_WITHOUT_TIME)
                },
                {
                    render: (text, record) => (
                        <FlexCP justifyContent={'flex-end'}>
                            <ButtonCP
                                size={'small'}
                                onClick={() => props.onEditUser(record)}
                                icon={'team'}
                            />
                            <ButtonCP
                                size={'small'}
                                onClick={() => props.onSelectProject(record)}
                                icon={'edit'}
                                marginLeft={5}
                            />
                        </FlexCP>
                    )
                },
            ]}
        />
    )
}
