import React from 'react'
import { RoutingHelper } from 'config/RoutingHelper'
import { StringUtils } from 'common/utils/StringUtils'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { ScreenAdminSpace } from 'modules/admin-space/screens/screen-admin-space/ScreenAdminSpace'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'

AdminSpaceRoutes.ROOT = '/admin-space'

AdminSpaceRoutes.ACCOUNTS = `${AdminSpaceRoutes.ROOT}/accounts`

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(AdminSpaceRoutes.ROOT),
        path: AdminSpaceRoutes.ROOT,
        component: ScreenAdminSpace,
    },
]

/**
 * Componente que define as rotas para acesso ao CRM
 */
export function AdminSpaceRoutes(): JSX.Element {

    if (!PermissionUtils.isSpaceAdmin())
        return <></>

    return (
        <>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </>
    )
}
