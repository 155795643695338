import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'

export class ChangePasswordFormModel extends FormModel {

    @IsNotEmpty()
    currentPassword: string

    @IsNotEmpty()
    newPassword: string

    @IsNotEmpty()
    confirmNewPassword: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
