import { NotificationRecipientEnum } from 'modules/marketing/services/notification/enums/NotificationRecipientEnum'
import { INotificationByRecipient } from 'modules/referral-program/components/drawer-referral-program-notifications/inner/INotificationByRecipient'
import { NotificationTriggerEnum } from 'modules/marketing/services/notification/enums/NotificationTriggerEnum'
import { NotificationChannelEnum } from 'modules/marketing/services/notification/enums/NotificationChannelEnum'
import { INotificationResponseDTO } from 'modules/marketing/services/notification/dtos/responses/INotificationResponseDTO'
import { INotificationBulkSaveItemRequestDTO } from 'modules/marketing/services/notification/dtos/resquests/INotificationBulkSaveItemRequestDTO'
import { NotificationContentVarsTranslateUtils } from 'modules/marketing/utils/NotificationContentVarsTranslateUtils'

/**
 * Util do drawer de notificacoes de programa de indicacao
 */
export const ReferralProgramNotificationsDrawerUtils = {

    /**
     * Converte retorno da API para formato de mapa utilizado na tela.
     */
    fromDtoToMap(notifications: INotificationResponseDTO[]): Map<NotificationRecipientEnum, INotificationByRecipient[]> {

        const msgMapByRecepient: Map<NotificationRecipientEnum, INotificationByRecipient[]> = new Map()
        Object.values(NotificationRecipientEnum).forEach((recipient) => {

            // Adiciona os tipos de destinatario
            msgMapByRecepient.set(recipient, [])

            Object.values(NotificationTriggerEnum).forEach((trigger) => {

                const notificationMsg: INotificationByRecipient = {
                    recipient,
                    trigger,
                    messages: []
                }
                msgMapByRecepient.get(recipient)?.push(notificationMsg)

                Object.values(NotificationChannelEnum).forEach((channel) => {

                    const foundNotification = notifications.find((not) => not.recipient === recipient && not.trigger === trigger && not.channel === channel)
                    notificationMsg.messages?.push({
                        code: foundNotification?.code,
                        isActive: foundNotification?.isActive ?? false,
                        channel,
                        subject: foundNotification?.subject,
                        message: foundNotification?.message ? NotificationContentVarsTranslateUtils.translateVarstoPtBr(foundNotification.message) : undefined,
                    })

                })

            })
        })

        return msgMapByRecepient
    },

    /**
     * Converte mapa utilizado na tela para formato do dto.
     */
    fromMapToDto(notificationsMap: Map<NotificationRecipientEnum, INotificationByRecipient[]>): INotificationBulkSaveItemRequestDTO[] {

        const dtos: INotificationBulkSaveItemRequestDTO[] = []
        notificationsMap.forEach((notificationsByRecipients) => {
            notificationsByRecipients.forEach((notificationsByRecipient) => {
                notificationsByRecipient.messages?.forEach((notificationMsg) => {

                    dtos.push({
                        code: notificationMsg.code,
                        isActive: notificationMsg.isActive,
                        channel: notificationMsg.channel,
                        recipient: notificationsByRecipient.recipient,
                        trigger: notificationsByRecipient.trigger,
                        subject: notificationMsg.subject,
                        message: notificationMsg.message ? NotificationContentVarsTranslateUtils.translateVarstoEnUs(notificationMsg.message) : undefined,
                    })

                })
            })
        })

        return dtos
    },

}
