import React, { useEffect } from 'react'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { SurveysRequests } from 'submodules/space4leads-sdk/services/surveys/surveys/SurveysRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { HttpStatusEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpStatusEnum'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'

interface ICPProps {
    onGetNps: (nps: SurveyResponseDTO) => void
    children: React.ReactNode
}

/**
 */
export function WrapperNpsCP(props: ICPProps): JSX.Element {

    const getNpsRequest = useRequest<SurveyResponseDTO>()
    useEffect(onGetNpsRequestChange, [getNpsRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa dados da tela
     */
    function init(): void {
        getNpsRequest.runRequest(SurveysRequests.getNps())
    }

    /**
     * Retorno da requisicao para pegar campanha de NPS
     */
    function onGetNpsRequestChange(): void {

        if (getNpsRequest.isAwaiting || !getNpsRequest.wasTried)
            return

        const isSuccess = (getNpsRequest.isSuccess && !!getNpsRequest.responseData)
        if (!isSuccess && getNpsRequest.responseStatus !== HttpStatusEnum.NOT_FOUND) {
            NotificationHelper.error('Ops!', 'Erro ao obter nps')
            return
        }

        props.onGetNps(getNpsRequest.responseData!)
    }

    return (
        <>
            <LoadingOverlayCP show={getNpsRequest.isAwaiting}/>

            { props.children }
        </>
    )

}
