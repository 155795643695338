/**
 * TODO: Add descricao
 * TODO: Concluir implementacao
 */
export const PhoneValidationUtils = {

    /**
     * Validador de telefone em padrao brasileiro:
     * Pode validar com mascara mascara obrigatoria, com mascara opcional ou obrigatoriamente sem mascara;
     * Pode validar obrigatoriamente celular, obrigatoriamente tefone fixo ou aceitando qualquer um dos 02;
     */
    validateBR(value: string, isCellphone?: boolean, hasMask?: boolean): boolean {

        const shouldValidateWithMask = (hasMask !== false)
        const shouldValidateWithoutMask = (hasMask !== true)

        // const shouldValidateCellphone = (isCellphone !== false)
        // const shouldValidateRegularPhone = (isCellphone !== true)
        //
        // const regexPhone = /a/
        // const regexCellPhone = /a/
        // const regexMaskedCellPhone = /a/
        // const regexMaskedPhone = /a/

        return (!value ||
            (shouldValidateWithMask && /^\(\d{2}\)(\s*:?)(9\d{4}-\d{4}|[1-6]\d{3}-\d{4})$/.test(value))
            || (shouldValidateWithoutMask && /^\d{2}(9\d{8}|[1-6]\d{7})$/.test(value))
        )
    },
}
// 31993511823
// 31 993511823
// (31) 993511823
// (31) 99351-1823
// 31 99351-1823

// 3134529055
// 31 34529055
// (31) 34529055
// (31) 3452-9055
// 31 3452-9055
