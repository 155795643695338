import React, { useEffect, useState } from 'react'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import styled from 'styled-components'
import { ColorUtils } from 'submodules/nerit-framework-ui/common/utils/ColorUtils'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { TableInnerICP } from 'submodules/nerit-framework-ui/common/components/table/inner/TableInnerICP'
import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TableTopBarICP } from 'submodules/nerit-framework-ui/common/components/table/inner/TableTopBarICP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { TableSortTP } from 'submodules/nerit-framework-ui/common/components/table/types/TableSortTP'
import { OrderingEnum } from 'submodules/nerit-framework-utils/utils/enums/OrderingEnum'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { IApiReturn } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IApiReturn'
import { ISearchOrderingRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchOrderingRequestDTO'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'

export interface TableCPProps<RowTP, FilterDto> {
    columns?: Array<ITableColumn<RowTP>>
    shouldLoadData?: number
    onLoad?: (resultData: RowTP[]) => void
    footer?: (rows: RowTP[]) => JSX.Element
    onLoading?: (isLoading: boolean) => void
    apiConfig: {
        filters?: FilterDto
        requestConfigTP: (filtersDto?: FilterDto) => RequestConfigTP
        hasPagination?: boolean
        pageSize?: number
    }
    appearance?: {
        bordered?: boolean
        showHeader?: boolean
        emptyText?: string
        hideEmptyLogo?: boolean
        wrappedOnCard?: boolean
        showReloadButton?: boolean
        reloadButtonSize?: ButtonSizeTP
        title?: string
        topBarLeftContent?: React.ReactNode
        showTotalOnHeader?: boolean
        recordLabel?: string
    }
    row?: {
        onClick?: (row: RowTP) => void
        onSelect?: {
            rowKey: keyof RowTP
            selectedCodes: number[]
            onSelectRow: (codes: number[]) => void
        }
        setClass?: (record: RowTP) => string
    }
    filter?: {
        loadedListFilterColumnName: string
    }
    expand?: {
        expandedRowRender?: (record: RowTP, index: number, indent: number, expanded: boolean) => React.ReactNode
        onExpand?: (expanded: boolean, record: RowTP) => void
    }
    sorter?: {
        initialSorter?: ISearchOrderingRequestDTO
        onChangeSorter?: (sorter?: ISearchOrderingRequestDTO) => void
    }
}

/**
 * Tabela padrao buscada na api.
 */
export function TableFromApiCP<RowTP, FilterDto = any>(props: TableCPProps<RowTP, FilterDto>): JSX.Element {

    const [requestDto, setRequestDto] = useState<OrUndefinedTP<FilterDto>>()
    const [searchStr, setSearchStr] = useState<string>()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    useEffect(() => props.onLoading?.(isLoading), [isLoading])

    const [total, setTotal] = useState<number>()
    const [list, setList] = useState<RowTP[]>()
    const [isReady, setIsReady] = useState<boolean>(false)

    useEffect(init, [props.shouldLoadData])

    /**
     * Carrega relatorio.
     */
    function init(): void {

        setList(undefined)
        setTotal(undefined)
        if (!props.shouldLoadData)
            return

        const dto: any = { ...props.apiConfig.filters }

        // Se tiver paginacao coloca a paginacao no DTO
        if (props.apiConfig.hasPagination) {
            dto.page = 1
            dto.itemsPerPage = props.apiConfig.pageSize ?? TableUtils.getDefaultPagination().pageSize
        }

        // Se tiver uma ordenacao inicial
        if (!!props.sorter?.initialSorter) {
            dto.orderingCriteria = props.sorter.initialSorter.orderingCriteria
            dto.orderingAscending = props.sorter.initialSorter.orderingAscending
        }

        setRequestDto(dto)

        doRequest(dto)
        setIsReady(true)
    }

    /**
     * Faz o request dessa forma por questao de performance
     */
    function doRequest(dto: any): void {

        setIsLoading(true)
        RequestHelper.runRequest<IApiReturn<ListResponseDTO<RowTP>>>(props.apiConfig.requestConfigTP(dto))
            .then((response) => {

                const result = response.data.data
                setList(result?.list)
                setTotal(result?.total)

                if (!!props.onLoad)
                    props.onLoad(result?.list ?? [])

            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    /**
     * Ao mudar a paginacao
     */
    function onChangePagination(currentPage: number, sorter?: TableSortTP): void {

        const dto: any = requestDto
        if (!dto)
            return NotificationHelper.error('Erro na paginação')

        dto.page = currentPage

        if (!!sorter) {
            dto.orderingCriteria = sorter.column
            dto.orderingAscending = sorter.order === OrderingEnum.ASC
        } else {
            dto.orderingCriteria = props.sorter?.initialSorter?.orderingCriteria
            dto.orderingAscending = props.sorter?.initialSorter?.orderingAscending
        }

        if (!!props.sorter?.onChangeSorter) {
            props.sorter.onChangeSorter({
                orderingAscending: dto.orderingAscending,
                orderingCriteria: dto.orderingCriteria
            })
        }

        doRequest(dto)
    }

    if (!isReady)
        return <></>

    return (
        <TableSCP isClickable={!!props.row?.onClick}>

            <TableTopBarICP
                wrappedOnCard={props.appearance?.wrappedOnCard}
                showReloadButton={props.appearance?.showReloadButton}
                loading={isLoading}
                onClick={init}
                size={props.appearance?.reloadButtonSize}
                leftContent={props.appearance?.topBarLeftContent}
                totalOnHeader={{
                    total: total,
                    show: props.appearance?.showTotalOnHeader ?? false,
                    recordLabel: props.appearance?.recordLabel,
                }}
            />

            {
                !!props.filter?.loadedListFilterColumnName &&
                <SearchWrapperSCP>
                    <InputCP
                        marginRight={props.appearance?.wrappedOnCard ? 10 : undefined}
                        marginLeft={props.appearance?.wrappedOnCard ? 10 : undefined}
                        icon={<IconICP iconName={'search'}/>}
                        placeholder={'Comece a digitar o nome para procurar'}
                        value={searchStr}
                        onChange={setSearchStr}
                    />
                </SearchWrapperSCP>
            }

            {
                props.appearance?.wrappedOnCard
                    ?
                    <CardCP innerSpacing={'none'} bordered={false} overflow={'auto'} title={props.appearance.title}>
                        <TableInnerICP
                            data={StringUtils.isEmpty(searchStr)
                                ? list
                                : list?.filter((record) => StringUtils.removeAccents((record)[props.filter!.loadedListFilterColumnName]).toLowerCase().includes(StringUtils.removeAccents(searchStr!.toLowerCase())))
                            }
                            loading={isLoading}
                            onChangePaginationOrSorter={onChangePagination}
                            totalRecords={total}
                            sorter={props.sorter}
                            pagination={{
                                hasPagination: props.apiConfig.hasPagination ?? false,
                                pageSize: props.apiConfig.pageSize
                            }}
                            footer={props.footer}
                            appearance={props.appearance}
                            row={props.row}
                            columns={props.columns}
                            expand={props.expand}
                        />
                    </CardCP>
                    :
                    <TableInnerICP
                        data={StringUtils.isEmpty(searchStr)
                            ? list
                            : list?.filter((record) => (record)[props.filter!.loadedListFilterColumnName].toLowerCase().includes(searchStr!.toLowerCase()))
                        }
                        loading={isLoading}
                        onChangePaginationOrSorter={onChangePagination}
                        totalRecords={total}
                        sorter={props.sorter}
                        pagination={{
                            hasPagination: props.apiConfig.hasPagination ?? false,
                            pageSize: props.apiConfig.pageSize
                        }}
                        footer={props.footer}
                        appearance={props.appearance}
                        row={props.row}
                        columns={props.columns}
                        expand={props.expand}
                    />
            }
        </TableSCP>
    )
}

const TableSCP = styled.div<{ isClickable: boolean }>`

  width: 100%;

  .row-nerit-error {
    background: ${props => ColorUtils.lightenColor(props.theme.errorColor, 210)};

    :hover {
      background: ${props => ColorUtils.lightenColor(props.theme.errorColor, 200)};;
    }
  }

  .ant-table-small {
    border: none !important;
  }

  .ant-table-body {
    margin: 0 !important;
  }

  .ant-table-wrapper thead {
    tr th {
      font-weight: bold;
      font-size: 0.8rem;
      padding: 12px 8px !important;
    }
  }

  .ant-table-wrapper tbody {
    tr:hover td {
      background: rgba(218, 225, 239, 0.3) !important;
      cursor: ${props => props.isClickable ? 'pointer' : undefined};
    }

    tr td {
      font-size: 11px;
    }
  }

  .ant-table-wrapper .ant-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .n-selected-row {
    background-color: #f1f1f1;
  }
`

const SearchWrapperSCP = styled.div`
  margin: 10px;

  input {
    background: #fff !important;
    border-radius: 3px !important;
  }
`
