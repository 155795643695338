import { RoutingHelper } from 'config/RoutingHelper'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { PrivateAccessVerifierCP } from 'modules/app/components/app/inner/PrivateAccessVerifierCP'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { ScreenLogin } from 'modules/auth/screens/screen-login/ScreenLogin'
import { ScreenRecoverPassword } from 'modules/auth/screens/screen-recover-password/ScreenRecoverPassword'
import { SurveyRoutes } from 'modules/survey/routes/SurveyRoutes'
import { ScreenSurveyLandingPage } from 'modules/survey/screens/screen-survey-landing-page/ScreenSurveyLandingPage'
import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'
import { ScreenLPCustomerReferrals } from 'modules/referral-program/screens/screen-lp-customer-referrals/ScreenLPCustomerReferrals'
import { ScreenLPReferralProgramSubscribe } from 'modules/referral-program/screens/screen-lp-referral-program-subscribe/ScreenLPReferralProgramSubscribe'
import { ScreenSurveyResultLandingPage } from 'modules/survey/screens/screen-survey-result-landing-page/ScreenSurveyResultLandingPage'
import { AdminRoutes } from 'modules/admin/AdminRoutes'
import { ScreenCreateAccount } from 'modules/admin/screens/screen-create-account/ScreenCreateAccount'

PublicRouter.PUBLIC_ROUTES_CONFIG = [
    {
        path: AuthRoutes.USER_LOGIN,
        component: ScreenLogin,
        noSchema: true,
        exact: true,
    },
    {
        path: AuthRoutes.RECOVER_PWD,
        component: ScreenRecoverPassword,
        noSchema: true,
        exact: true,
    },
    {
        path: AdminRoutes.CREATE_ACCOUNT,
        rootPath: AdminRoutes.CREATE_ACCOUNT,
        component: ScreenCreateAccount,
        noSchema: true,
        exact: true,
    },
    {
        path: SurveyRoutes.SURVEY_LANDING_PAGE_WITH_QUERY,
        rootPath: SurveyRoutes.SURVEY_LANDING_PAGE,
        component: ScreenSurveyLandingPage,
        noSchema: true,
        exact: true,
    },
    {
        path: SurveyRoutes.SURVEY_RESULT_LANDING_PAGE,
        component: ScreenSurveyResultLandingPage,
        noSchema: true,
        exact: true,
    },
    {
        path: ReferralProgramRoutes.CUSTOMER_REFERRALS_LP_WITH_QUERY,
        rootPath: ReferralProgramRoutes.CUSTOMER_REFERRALS_LP,
        component: ScreenLPCustomerReferrals,
        noSchema: true,
        exact: true,
    },
    {
        path: ReferralProgramRoutes.REFERRAL_PROGRAM_SUBSCRIBE_LP_WITH_QUERY,
        rootPath: ReferralProgramRoutes.REFERRAL_PROGRAM_SUBSCRIBE_LP,
        component: ScreenLPReferralProgramSubscribe,
        noSchema: true,
        exact: true,
    },
    {
        key: 'verifier',
        path: 'root',
        component: PrivateAccessVerifierCP,
    },

] as RouteConfigTP[]

/**
 * ROTEADOR
 * Define rotas que podem ser acessadas sem realizar login.
 *
 * NOTE: Este eh o UNICO componente da aplicacao que deve utilizar o hook 'useHistory' da biblioteca de roteamento
 */
export function PublicRouter(): JSX.Element {
    return RoutingHelper.renderPublicRoutingSwitch()
}
