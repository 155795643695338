import React from 'react'
import { IconICP } from 'common/components/icons/inner/IconICP'
import { IIconProps } from 'common/components/icons/inner/IIconProps'

type PlusIconTypeTP = 'default' | 'circle' | 'box'

interface IPlusIconCPProps extends IIconProps {
    type?: PlusIconTypeTP
}

/**
 * ICONE: Sinal de 'mais': +
 */
export const PlusIconCP = (props: IPlusIconCPProps): JSX.Element => {

    let iconName: string
    switch (props.type) {
        case 'circle':
            iconName = 'plus-circle'
            break

        case 'box':
            iconName = 'plus-square'
            break

        case 'default':
        default:
            iconName = 'plus'
            break
    }

    return (
        <IconICP
            iconName={iconName}
            size={props.size}
            color={props.color}
            style={props.style}
            theme={props.theme}
            transitionTime={props.transitionTime}
        />
    )
}
