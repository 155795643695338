import { SystemConfig } from 'config/SystemConfig'
import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { IFranchiseSaveRequestDTO } from 'modules/admin/services/franchise/dtos/request/IFranchiseSaveRequestDTO'

/**
 * Requests de relatorios de venda
 */
export class FranchiseRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/franchises`

    static search = (): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}`,
        method: HttpMethodEnum.GET,
    })

    static searchNameAndCode = (): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}/names`,
        method: HttpMethodEnum.GET,
    })

    static findOne = (code: number): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static update = (code: number, dto: IFranchiseSaveRequestDTO): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static create = (dto: IFranchiseSaveRequestDTO): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static delete = (code: number): RequestConfigTP => ({
        url: `${FranchiseRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.DELETE,
    })

}