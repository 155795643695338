import { PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createEncryptor from 'redux-persist-transform-encrypt'
import { Transform } from 'redux-persist/es/types'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { SystemConfig } from 'config/SystemConfig'

/**
 * CONFIG
 * Encapsula configuracoes para persistencia local de valores
 * mantidos no redux com biblioteca 'redux-persist'.
 */
export class ReduxPersistorHelper {

    static readonly ACTION_REHYDRATE = 'persist/REHYDRATE'
    private static readonly _PERSIST_WHITELIST: Array<keyof IReduxState> = ['userData', 'domainData', 'currentProject', 'currentAccount']

    private static readonly _PERSIST_CONFIG_DEFAULT: PersistConfig<IReduxState> = {
        key: 'root',
        storage,
    }

    private constructor() {}

    /** Gera & retorna parametros de configuracao do 'persistor'. */
    static getPersistorConfig(): PersistConfig<IReduxState> {

        const encryptor = createEncryptor({
            secretKey: SystemConfig.getInstance().reduxEncryptKey,
            onError: error => console.error('FALHA - ReduxPersistorHelper.getPersistorConfig.createEncryptor: ', error)
        })

        return {
            ...this._PERSIST_CONFIG_DEFAULT,
            whitelist: this._PERSIST_WHITELIST,
            transforms: [encryptor as Transform<any, any, IReduxState>]
        }
    }
}

