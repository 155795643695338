import { IThemeProject } from 'config/theme/project/IThemeProject'

export const ThemeProjectSpace4Leads: IThemeProject = {

    headerFontColor: '#fff',
    primaryButtonBackgroundColor: '#010102',
    primaryButtonFontColor: '#fff',

    collapsedMenuButtonColor: '#010102',
    serviceWorkerBarColor: '#f3b900',
    serviceWorkerFontColor: '#010102',

    primaryMenuColor: '#010102',
    primaryMenuItemColor: '#f3b900',

    secondaryMenuColor: '#1a1a1a',
    secondaryBorderColor: '#343436',
    secondaryMenuItemColor: '#f3b900',
    secondaryMenuTitleColor: '#f3b900',

    headerBackgroundColor: '#FFF',
    loginCoverColor: '#ffffff',
    titleBarColor: '#4d4d4d',

    checkedBackgroundColor: '#f3b900',
    checkedColor: '#000',

    qrCodeLogoUrl: `${process.env.PUBLIC_URL}/icone-sistema.png`,

    widgetFooterColor: '#f3b900'
}
