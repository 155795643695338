import React from 'react'
import styled from 'styled-components'
import { IGoalResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IGoalResponseDTO'

interface ICPProps {
    goal: IGoalResponseDTO
}

/**
 * Conteudo do item de timeline.
 */
export function TimelineItemCustomerReferralsContentICP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <strong>Regras: </strong>
            <span>{ props.goal.rule }</span>
            <br/>
            <strong>Prêmio: </strong>
            <span>{ props.goal.prize }</span>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  padding: 20px;
    //margin: 20px;
`
