export enum DayPeriodEnum {
    MORNING = 'MORNING',
    AFTERNOON = 'AFTERNOON',
    NIGHT = 'NIGHT',
    ALL = 'ALL',
}

export enum DayPeriodLabelEnum {
    MORNING = 'Manhã',
    AFTERNOON = 'Tarde',
    NIGHT = 'Noite',
    ALL = 'Todos',
}
