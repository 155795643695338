import { LoadingCP } from 'common/components/loading/LoadingCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { RequestHelper } from 'common/request-manager/RequestHelper'
import { PublicRouter } from 'modules/app/routes/PublicRouter'
import { AuthActions } from 'modules/auth/AuthActions'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

/**
 * Configuracoes iniciais da aplicacao.
 */
export function AppInitializerCP(): JSX.Element {

    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [isValidated, setIsValidated] = useState<boolean>(false)

    const routeHistory = useHistory()
    const location = useLocation()

    useEffect(init, [])
    useEffect(() => { validateRoute() }, [location.pathname, isInitialized])

    function init(): void {

        RoutingHelper.init(routeHistory, PublicRouter.PUBLIC_ROUTES_CONFIG)

        // Add tratamento para expiracao de token de login
        RequestHelper.addErrorCustomAction({ action: AuthActions.onUnauthorizedRequestResponse, httpStatus: HttpStatusEnum.UNAUTHORIZED })

        // Add tratamento para schema invalido
        RequestHelper.addErrorCustomAction({
            action: () => RoutingHelper.handleInvalidSchema(),
            httpStatus: HttpStatusEnum.FORBIDDEN
        })

        setIsInitialized(true)
    }

    async function validateRoute(): Promise<void> {
        setIsValidated(true)
    }

    return isValidated ? <PublicRouter/> : <LoadingCP/>
}
