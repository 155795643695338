import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { FormModel } from 'common/form-state-manager/FormModel'
import { IsValidCnpj } from 'common/validation/decorators/IsValidCnpj'
import { IsString } from 'common/validation/decorators/IsString'
import { IsOptional } from 'class-validator'
import { IsEmail } from 'common/validation/decorators/IsEmail'
import { IsPhoneBR } from 'common/validation/decorators/phone/IsPhoneBR'

export class FranchiseFormModel extends FormModel {

    @IsNotEmpty()
    @IsValidCnpj()
    cnpj: string

    @IsOptional()
    @IsString()
    name: string

    @IsOptional()
    @IsEmail()
    email: string

    @IsOptional()
    @IsPhoneBR()
    phone: string

    /* Endereco. */
    @IsOptional()
    @IsString()
    zipCode?: string

    @IsOptional()
    @IsString()
    street?: string

    @IsOptional()
    @IsString()
    number?: string

    @IsOptional()
    @IsString()
    complement?: string

    @IsOptional()
    @IsString()
    neighborhood?: string

    @IsOptional()
    @IsString()
    city?: string

    @IsOptional()
    @IsString()
    state?: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
