import React, { useEffect, useState } from 'react'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { TitleCP } from 'common/components/title/TitleCP'
import { IFranchiseResponseDTO } from 'modules/admin/services/franchise/dtos/response/IFranchiseResponseDTO'
import { SelectOptionTP } from 'common/components/form-fields/select/inner/types/SelectOptionTP'
import { SystemUtils } from 'common/utils/SystemUtils'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { ReferralProgramSubscribeFormModel } from 'modules/referral-program/components/wizard-referral-program-subscribe/models/ReferralProgramSubscribeFormModel'
import { TextAddressCP } from 'modules/app/components/text-address/TextAddressCP'
import * as _ from 'lodash'

interface ICPProps {
    participatingFranchises: IFranchiseResponseDTO[]
    formStateManager: IFormStateManager<ReferralProgramSubscribeFormModel>
}

/**
 * Etapa de insercao de dados.
 */
export function StepFranchiseDataICP(props: ICPProps): JSX.Element {

    const [citiesOptions, setCitiesOptions] = useState<SelectOptionTP[]>([])
    const [franchisesOnCityOptions, setFranchisesOnCityOptions] = useState<SelectOptionTP[]>([])

    useEffect(init, [props.participatingFranchises])

    /**
     * Inicializa.
     */
    function init(): void {

        const citiesOpts: SelectOptionTP[] = []

        props.participatingFranchises.forEach((franchise) => {

            if (!franchise.address)
                return

            // Se ja tiver inserido a cidade, pula
            const foundCity = _.find(citiesOpts, { value: franchise.address.city })
            if (!!foundCity)
                return

            citiesOpts.push({
                value: franchise.address.city,
                label: `${franchise.address.city} / ${franchise.address.state}`,
            })
        })

        // Remove as cidades duplicadas
        setCitiesOptions(Array.from(new Set(citiesOpts).values()))
    }

    /**
     * Ao selecionar uma cidade.
     */
    function onSelectCity(city: string): void {

        const franchisesOnCity = props.participatingFranchises.filter((franchise) => franchise.address?.city === city)

        if (!franchisesOnCity)
            return

        const options = franchisesOnCity.map((franchise) => ({
            value: franchise.code,
            label: franchise.name,
        }))
        setFranchisesOnCityOptions(options)
    }

    return (
        <>
            <SelectCP
                label={'Selecione a Cidade'}
                required={true}
                options={citiesOptions}
                onChange={onSelectCity}
            />

            <SelectCP
                label={'Unidade'}
                required={true}
                options={franchisesOnCityOptions}
                disabled={SystemUtils.isEmpty(franchisesOnCityOptions)}
                onChange={(option) => props.formStateManager.changeFieldValue('franchiseCode', option)}
            />

            <ConditionalRenderCP shouldRender={!!props.formStateManager.getFieldValue('franchiseCode')}>
                <>
                    <TitleCP underLine={true} textSize={'normal'}>
                        Endereço
                    </TitleCP>
                    <TextAddressCP address={props.participatingFranchises.find((franchise) => franchise.code === props.formStateManager.getFieldValue('franchiseCode'))?.address}/>
                </>
            </ConditionalRenderCP>
        </>
    )
}
