import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React from 'react'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'

interface ICPProps {
    message: string
    icon: JSX.Element
    extra?: JSX.Element
    isLoading?: boolean
}

/**
 * Exibe mensagem de 'boas vindas': Para quando NAO ha nenhum formluario a ser exibido na area de conteudo principal da tela de TEMPLATES.
 */
export function WelcomeContentCP(props: ICPProps): JSX.Element {

    return (
        <ContentCP>
            <WrapperSCP>
                {
                    props.isLoading
                        ?
                        <LoadingCP/>
                        :
                        <>
                            <IconSCP>
                                {props.icon}
                            </IconSCP>
                            <TextSCP>
                                {props.message}
                            </TextSCP>
                            <ExtraSCP>
                                {props.extra}
                            </ExtraSCP>
                        </>
                }
            </WrapperSCP>
        </ContentCP>
    )
}

const ExtraSCP = styled.div`
  margin-top: 30px;
`

const TextSCP = styled.div`
  font-size: 1.5rem;
  text-align: center;
`

const WrapperSCP = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
`

const IconSCP = styled.div`
  margin: 0 0 30px 0;
`
