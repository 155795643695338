import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LogoCP } from 'common/components/logo/LogoCP'
import { ProgramSubscribeCP } from 'modules/referral-program/components/program-subscribe/ProgramSubscribeCP'
import { ReferralRuleCP } from 'modules/referral-program/components/referral-rule/ReferralRuleCP'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { LandingPageFooter } from 'modules/app/components/landing-page-footer/LandingPageFooter'
import { ReferralProgramLPWrapperCP } from 'modules/referral-program/components/referral-program-lp-wrapper/ReferralProgramLPWrapperCP'
import { TextCP } from 'common/components/text/TextCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'
import { useLocation } from 'react-router'
import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { FlexCP } from 'common/components/flex/FlexCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { HelpCP } from 'common/components/help/HelpCP'
import { ReferralProgramUtils } from 'modules/referral-program/utils/ReferralProgramUtils'

/**
 * Landing Page para coletar as respostas de uma pesquisa
 */
export function ScreenLPReferralProgramSubscribe(): JSX.Element {

    const routeLocation = useLocation()

    const [loadedReferralProgram, setLoadedReferralProgram] = useState<IReferralProgramResponseDTO>()

    const [referredByToken, setReferredByToken] = useState<string>()
    const [loadedReferredByPerson, setLoadedReferredByPerson] = useState<ICustomerPersonByReferralProgramResponseDTO>()
    const getPersonByTokenRequest = useRequest<ICustomerPersonByReferralProgramResponseDTO>()
    useEffect(onGetPersonByTokenRequestChange, [getPersonByTokenRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        // Token de quem indicou
        const query = new URLSearchParams(routeLocation.search)
        const referredByTokenParam = query.get(ReferralProgramRoutes.REFERRED_BY_TOKEN_PARAM)

        if (!referredByTokenParam)
            return

        setReferredByToken(referredByTokenParam)
        getPersonByTokenRequest.runRequest(PersonRequests.findByToken(referredByTokenParam))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetPersonByTokenRequestChange(): void {

        if (getPersonByTokenRequest.isAwaiting || !getPersonByTokenRequest.wasTried)
            return

        const isSuccess = (getPersonByTokenRequest.isSuccess && !!getPersonByTokenRequest.responseData)
        if (!isSuccess)
            return

        setLoadedReferredByPerson(getPersonByTokenRequest.responseData)
    }

    return (
        <ReferralProgramLPWrapperCP onLoadReferralProgram={setLoadedReferralProgram}>
            <>
                {
                    loadedReferralProgram &&
                    <WrapperSCP>
                        <LayoutWrapperSCP>
                            <LogoCP
                                marginTop={10}
                                marginBottom={30}
                                align={'center'}
                                imageUrl={loadedReferralProgram.logoUrl}
                            />

                            <MainWrapperSCP>
                                <MainSCP>
                                    <ProgramSubscribeCP
                                        referralProgram={loadedReferralProgram}
                                        referredByToken={referredByToken}
                                    />

                                    <FlexCP justifyContent={'center'} marginTop={30}>
                                        <GoToPortalButtonSCP>
                                            <HelpCP text={'Caso já tenha se cadatrado, clique abaixo para acessar a sua área logada'} type={'text'}/>
                                            <FlexCP justifyContent={'center'}>
                                                <ButtonCP
                                                    onClick={() => ReferralProgramUtils.redirectOrHistoryPush(ReferralProgramUtils.getCustomerDashboardUrl(loadedReferralProgram))}
                                                    marginTop={10}
                                                    size={'large'}
                                                    type={'primary'}
                                                >
                                                    Já sou cadastrado
                                                </ButtonCP>
                                            </FlexCP>
                                        </GoToPortalButtonSCP>
                                    </FlexCP>

                                    <ReferralRuleCP description={loadedReferralProgram.description}/>
                                </MainSCP>
                            </MainWrapperSCP>

                            <LandingPageFooter
                                leftContent={
                                    loadedReferredByPerson
                                        ?
                                        <TextCP text={`Indicado por ${loadedReferredByPerson.name}`} color={ThemeAnt.gray} fontStyle={'italic'}/>
                                        :
                                        undefined
                                }
                            />
                        </LayoutWrapperSCP>
                    </WrapperSCP>
                }
            </>
        </ReferralProgramLPWrapperCP>
    )
}

const WrapperSCP = styled.div`
  min-height: 100vh;
  width: 100%;
  background: #fff;
  overflow: hidden;
`

const LayoutWrapperSCP = styled.div`
  margin: 0 auto 0;
  width: 100%;
  max-width: 750px;
  padding: 0 10px;
  height: 100%;

  .ant-layout {
    background: none;
    overflow: auto;
  }
`

const MainWrapperSCP = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const MainSCP = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const GoToPortalButtonSCP = styled.div`
  
  margin-bottom: 30px;
  
  button {
    padding: 30px 60px;
  }
`
