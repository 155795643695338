import React from 'react'
import { WizardReferralProgramSubscribeCP } from 'modules/referral-program/components/wizard-referral-program-subscribe/WizardReferralProgramSubscribeCP'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'
import { TitleReferralProgramSubscribeCP } from 'modules/referral-program/components/title-referral-program-subscribe/TitleReferralProgramSubscribeCP'

interface ICPProps {
    referralProgram: IReferralProgramResponseDTO
    referredByToken?: string
    onSubscribe: (personRegisterd: ICustomerPersonByReferralProgramResponseDTO) => void
}

/**
 * Conteudo do card mostrando informacoes para convidar
 */
export function ProgramSubscribeWizardICP(props: ICPProps): JSX.Element {

    return (
        <>
            <TitleReferralProgramSubscribeCP
                title={props.referralProgram.title}
                subtitle={props.referralProgram.subtitle}
            />

            <WizardReferralProgramSubscribeCP
                onSubscribe={props.onSubscribe}
                referralProgram={props.referralProgram}
                referredByToken={props.referredByToken}
            />
        </>
    )
}
