/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { DragAndDropItemTP } from 'common/components/drag-and-drop-list/inner/types/DragAndDropItemTP'
import { DragAndDropListUtils } from 'common/components/drag-and-drop-list/inner/DragAndDropListUtils'

interface IDragAndDropListCPProps<DnDItemTP>{
    dataSource: DnDItemTP[]
    renderContent: (item: DnDItemTP, index: number) => JSX.Element
    onListOrderChange: (reorderedList: Array<DragAndDropItemTP<DnDItemTP>>) => void
}

/**
 * COMPONENTE genérico de lista com comportamento drag and drop
 */
export function DragAndDropListCP<DnDItemTP>(props: IDragAndDropListCPProps<DnDItemTP>): JSX.Element {

    const [listItems, setListItems] = useState<Array<DragAndDropItemTP<DnDItemTP>>>([])

    useEffect(init, [props.dataSource])

    function init(): void {

        if (!props.dataSource)
            return

        const dndList: Array<DragAndDropItemTP<DnDItemTP>> = props.dataSource.map((item: DnDItemTP, index: number) => ({
            code: 0,
            order: index,
            index,
            item
        }))
        setListItems(dndList)
    }

    function onDragEnd(result: DropResult): void {

        if (!result.destination)
            return

        const items = DragAndDropListUtils.getReorderedList(
            listItems,
            result.source.index,
            result.destination.index
        )

        props.onListOrderChange?.(items)
        setListItems(items)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <DragDropContextSCP>
                <Droppable droppableId={'droppable'}>
                    {(provided: DroppableProvided): JSX.Element => (
                        <ListWrapperSCP>
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {
                                    listItems.map((item, index) => props.renderContent(item.item, index))
                                }
                                {provided.placeholder}
                            </div>
                        </ListWrapperSCP>
                    )}
                </Droppable>
            </DragDropContextSCP>
        </DragDropContext>
    )
}

const DragDropContextSCP = styled.div`
    justify-content: space-between;
    flex-direction: column;
`

const ListWrapperSCP = styled.div`
    background: #f1f1f1;
    padding: 5px;
    width: 100%;
    border: dashed 1px #adadad;
`
