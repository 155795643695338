import { LayoutSubmenuContentCP } from 'common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import React, { useState } from 'react'
import { HeaderButtonICP } from 'common/components/screen-layout/header/inner/HeaderButtonICP'
import { TableFranchisesCP } from 'modules/admin/components/table-franchises/TableFranchisesCP'
import { DrawerFranchiseCP } from 'modules/admin/components/drawer-franchise/DrawerFranchiseCP'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'

/**
 * Tela de listagem de unidades.
 */
export function ScreenContentFranchises(): JSX.Element {

    const [shouldForceUpdateList, setShouldForceUpdateList] = useState<boolean>(true)

    const [isFranchiseDrawerVisible, setIsFranchiseDrawerVisible] = useState<boolean>(false)
    const [selectedFranchiseCode, setSelectedFranchiseCode] = useState<number>()

    return (
        <>
            <HeaderCP title={'Unidades'}>
                <HeaderButtonICP
                    onClick={() => {
                        setSelectedFranchiseCode(undefined)
                        setIsFranchiseDrawerVisible(true)
                    }}
                    icon={'plus'}
                    label={'Nova Unidade'}
                />
            </HeaderCP>

            <LayoutSubmenuContentCP>
                <TableFranchisesCP
                    forceLoadList={shouldForceUpdateList}
                    onListLoaded={() => setShouldForceUpdateList(false)}
                    onEdit={(code) => {
                        setIsFranchiseDrawerVisible(true)
                        setSelectedFranchiseCode(code)
                    }}
                />

                <AlertCP
                    message={'As unidades são exibidas no Programa de Indicação como endereço físico. Isto caso o seu Programa de Indicação esteja configurado para o cliente selecionar uma unidade em que esteja participando.'}
                    type={'info'}
                    margin={{ right: 20, left: 20, bottom: 20 }}
                />
            </LayoutSubmenuContentCP>

            <DrawerFranchiseCP
                visible={isFranchiseDrawerVisible}
                code={selectedFranchiseCode}
                onClose={(dataChanged) => {
                    if (dataChanged)
                        setShouldForceUpdateList(true)
                    setIsFranchiseDrawerVisible(false)
                }}
            />
        </>
    )
}
