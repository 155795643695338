import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SystemConfig } from 'config/SystemConfig'
import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'
import { Space4LeadsSystemApiEnum } from 'submodules/space4leads-sdk/common/Space4LeadsSystemApiEnum'
import { AuthActions } from 'modules/auth/AuthActions'

/**
 * Class especifica do projeto com informacoes necessarios para o projeto.
 */
export class NeritFrameworkProjectConfig {

    static APP_VERSION = '20230324.1'
    static PROJECT_NAME = 'Space4Leads'

    private constructor() {}

    static isDev(): boolean {
        return SystemConfig.getInstance().environment === EnvironmentEnum.DEV
    }

    static isHom(): boolean {
        return SystemConfig.getInstance().environment === EnvironmentEnum.HOM
    }

    static formatApiBaseUrl(config: RequestConfigTP): string {

        let baseurl = config.url
        if (config.baseUrlType === Space4LeadsSystemApiEnum.SPACE4LEADS_API)
            baseurl = `${SystemConfig.getInstance().apiBaseUrl}/${config.url}`

        return baseurl
    }

    static notifyApi(type: 'success' | 'error', title: string, msg: string): void {

        if (type === 'error')
            NotificationHelper.error(title, msg)
        else
            NotificationHelper.success(title, msg)
    }

    static onUnauthorized(): void {
        AuthActions.onUnauthorizedRequestResponse()
    }
}
