import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'

export interface ICheckboxCPProps {
    isChecked?: boolean
    onChange?: (checked: boolean) => void
    children?: JSX.Element | JSX.Element[]
    disabled?: boolean
}

/**
 * Campo checkbox genérico
 */
export function CheckboxCP(props: ICheckboxCPProps): JSX.Element {

    const [isChecked, setIsChecked] = useState<boolean>(false)
    useEffect(init, [props.isChecked])

    /**
     * Inicializa.
     */
    function init(): void {

        if (props.isChecked)
            return setIsChecked(props.isChecked)

    }

    /**
     * Ao alterar o valor do campo.
     */
    function onChange(checked: boolean): void {

        setIsChecked(checked)

        if (props.onChange)
            props.onChange(checked)
    }

    return (
        <Checkbox
            checked={isChecked}
            onChange={(e) => onChange(e.target.checked)}
            disabled={props.disabled}
        >
            {props.children}
        </Checkbox>
    )
}

