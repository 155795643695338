import React, { useEffect, useState } from 'react'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { IAutocompleteCommonProps } from 'common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { INameAndCodeResponseDTO } from 'common/dtos/responses/INameAndCodeResponseDTO'
import { SelectFullOptionTP } from 'common/components/form-fields/select/inner/types/SelectFullOptionTP'
import { AutocompletePickerUtils } from 'common/components/form-fields/autocomplete-picker/inner/AutocompletePickerUtils'
import { PlusIconCP } from 'common/components/icons/PlusIconCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { TransientValueTrimmer } from 'common/transient-value-trimmer/TransientValueTrimmer'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { FormModel } from 'common/form-state-manager/FormModel'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import styled from 'styled-components'

const PAGINATION_ITEMS_DEFAULT = 20
const PAGINATION_PAGE_DEFAULT = 1

const NOT_FOUND_TXT_START_TYPING = 'Comece a digitar'
const NOT_FOUND_TXT_LOADING = 'Carregando...'
const NOT_FOUND_TXT_EMPTY = 'Nenhum item encontrado'

interface IAutocompletePickerCPProps<
    ListItemTP extends INameAndCodeResponseDTO,
    FModelTP extends FormModel = any

> extends IAutocompleteCommonProps<ListItemTP, FModelTP> {
    label: string
    requestConfigGetter: (searchDto: any) => RequestConfigTP
    customFilters?: any
    loading?: boolean
    mustClearSearch?: boolean
    onSearchCleared?: () => void
    onAdding?: (name: string) => void
    showFixedAddButton?: boolean
    onLoadOptions?: (loadedOptionsObjects: any[]) => void
}

/**
 * COMPONENTE
 * Seletor com autocomplete generico.
 * NOTE: Caso este componente seja controlado via 'form state manager' seu valor nao eh determinado diretamente pela prop 'value'.
 *
 * @see FormStateManager
 */
export function AutocompletePickerCP<
    ListItemTP extends INameAndCodeResponseDTO,
    FModelTP extends FormModel = any

>(props: IAutocompletePickerCPProps<ListItemTP, FModelTP>): JSX.Element {

    const [searchString, setSearchString] = useState<string>('')
    const [options, setOptions] = useState<SelectFullOptionTP[]>([])
    const [notFoundTxt, setNotFoundTxt] = useState<string>(NOT_FOUND_TXT_START_TYPING)
    const [selectedOptions, setSelectedOptions] = useState<SelectFullOptionTP[]>([])
    const [onChangeSearchStrTrimmer] = useState<TransientValueTrimmer<string>>(new TransientValueTrimmer(AutocompletePickerUtils.getSearchStrTrimmerConfig(setSearchString)))

    const searchRequest = useRequest<IGenericListResponseDTO<ListItemTP>>()

    useEffect(onSearchTxtChange, [searchString, props.showOptionsOnLoad])
    useEffect(onSearchRequestChange, [searchRequest.isAwaiting])
    useEffect(onInitialOptionsReceived, [props.initialOptions])
    useEffect(onMustClearSearchChange, [props.mustClearSearch])

    function onSearchRequestChange(): void {

        if (!searchRequest.wasTried || searchRequest.isCancelled)
            return

        if (searchRequest.isAwaiting)
            return setNotFoundTxt(NOT_FOUND_TXT_LOADING)

        _setOptions(AutocompletePickerUtils.getSelectOptions(searchRequest.responseData?.list ?? []), true)

        if (props.onLoadOptions)
            props.onLoadOptions(searchRequest.responseData?.list ?? [])

        if (RequestUtils.isRequestError(searchRequest)) {
            RequestUtils.handleError({
                request: searchRequest,
                errorMsg: 'Falha ao buscar lista para seletor via autocomplete',
                failureLogMsg: `FALHA - ${AutocompletePickerCP.name}.${onSearchRequestChange.name}`,
            })
        }
    }

    function onSearchTxtChange(): void {

        if (!searchString && !props.showOptionsOnLoad) {

            _setOptions([], false)
            searchRequest.cancelRequest()

            if (!!props.onSearchCleared)
                props.onSearchCleared()

            return
        }

        const searchDto = {
            itemsPerPage: props.searchItems ?? PAGINATION_ITEMS_DEFAULT,
            page: props.searchPage ?? PAGINATION_PAGE_DEFAULT,
            searchString,
            ...(props.customFilters || {})
        }

        searchRequest.runRequest(props.requestConfigGetter(searchDto))
    }

    function onInitialOptionsReceived(): void {

        const receivedOptions = AutocompletePickerUtils.getSelectOptions(props.initialOptions ?? [])
        if (!receivedOptions.length || searchRequest.isAwaiting)
            return

        // Atualiza opcoes disponiveis
        const nextOptions = AutocompletePickerUtils.getUniqueOptionsList([
            ...options,
            ...receivedOptions
        ])

        _setOptions(nextOptions, false)

        if (!props.isMultiple)
            return

        // Atualiza dados das opcoes ja selecionadas
        const currentValue = getCurrentMultipleValue()

        const nextSelectedOptions = AutocompletePickerUtils.getUniqueOptionsList([
            ...selectedOptions,
            ...receivedOptions.filter(listItem => currentValue.includes(listItem.value as number)),
        ])

        setSelectedOptions(nextSelectedOptions)
    }

    function onMustClearSearchChange(): void {
        if (!!props.mustClearSearch)
            setSearchString('')
    }

    function _setOptions(_options: SelectFullOptionTP[], hasSearchBeenRan: boolean): void {
        if (!_options.length)
            setNotFoundTxt(hasSearchBeenRan ? NOT_FOUND_TXT_EMPTY : NOT_FOUND_TXT_START_TYPING)
        setOptions(_options)
    }

    function getCurrentMultipleValue(): number[] {
        const hasFStateManager = (!!props.formStateManager && !!props.fieldName)
        const currentValue = hasFStateManager ? props.formStateManager?.getFieldValue(props.fieldName!) : props.value
        return currentValue ?? []
    }

    function handleChange(value?: any): void {

        if (!!props.isMultiple && !!props.returnFullOption)
            setSelectedOptions(value)

        if (!!props.onChange)
            props.onChange(value)
    }

    const showAddingBtn = props.showFixedAddButton || (!!props.onAdding && notFoundTxt === NOT_FOUND_TXT_EMPTY && !!searchString && !props.loading)
    return (
        <WrapperSCP>
            <SelectCP<FModelTP>
                options={options}
                selectedOptions={selectedOptions}
                loading={searchRequest.isAwaiting || !!props.loading}
                onSearch={_searchString => onChangeSearchStrTrimmer.onChange(_searchString)}
                onChange={handleChange}
                onFormSubmit={props.onFormSubmit}
                isMultiple={!!props.isMultiple}
                returnFullOption={props.returnFullOption}
                fieldName={props.fieldName}
                formStateManager={props.formStateManager}
                label={props.label}
                required={props.required}
                width={props.width}
                value={props.value}
                fontSize={props.fontSize}
                disabled={props.disabled}
                notFoundContent={notFoundTxt}
            />

            {
                showAddingBtn &&
                <ButtonWrapperSCP show={showAddingBtn}>
                    <ButtonCP
                        onClick={() => props.onAdding!(searchString)}
                        type={'ghost'}
                        borderColor={'transparent'}
                        tooltip={'Adicionar'}
                    >
                        <PlusIconCP type={'circle'} color={ThemeAnt.primaryColor} size={15}/>
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    position: relative;
`

const ButtonWrapperSCP = styled.div<{ show: boolean }>`
    position: absolute;
    right: 13px;
    top: 13px;
    bottom: 5px;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    transition: opacity .5s;
    opacity: ${props => (props.show ? 1 : 0)};
`
