import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import React, { useState } from 'react'
import styled from 'styled-components'
import { LogoCP } from 'common/components/logo/LogoCP'
import { ScreenContentCustomerReferralsLandingPage } from 'modules/referral-program/screens/screen-lp-customer-referrals/inner/content/ScreenContentCustomerReferralsLandingPage'
import { ScreenContentCustomerReferralsLoginLP } from 'modules/referral-program/screens/screen-lp-customer-referrals/inner/content/ScreenContentCustomerReferralsLoginLP'
import { LandingPageFooter } from 'modules/app/components/landing-page-footer/LandingPageFooter'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { ReferralProgramLPWrapperCP } from 'modules/referral-program/components/referral-program-lp-wrapper/ReferralProgramLPWrapperCP'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'

/**
 * Landing Page para coletar as respostas de uma pesquisa
 */
export function ScreenLPCustomerReferrals(): JSX.Element {

    const [loadedReferralProgram, setLoadedReferralProgram] = useState<IReferralProgramResponseDTO>()
    const [loggedCustomer, setLoggedCustomer] = useState<ICustomerPersonByReferralProgramResponseDTO>()

    return (
        <ReferralProgramLPWrapperCP onLoadReferralProgram={setLoadedReferralProgram}>
            <>
                {
                    loadedReferralProgram &&
                    <WrapperSCP>
                        <LayoutWrapperSCP>
                            <LayoutCP
                                header={
                                    <LogoCP
                                        marginTop={10}
                                        marginBottom={30}
                                        align={'center'}
                                        width={!!loggedCustomer ? '150px' : undefined}
                                        imageUrl={loadedReferralProgram.logoUrl}
                                    />
                                }
                                content={
                                    <MainWrapperSCP>
                                        <MainSCP>
                                            {
                                                !!loggedCustomer
                                                    ?
                                                    <ScreenContentCustomerReferralsLandingPage
                                                        referralProgram={loadedReferralProgram}
                                                        loggedCustomer={loggedCustomer}
                                                    />
                                                    :
                                                    <ScreenContentCustomerReferralsLoginLP
                                                        onLoadCustomer={setLoggedCustomer}
                                                        referralProgram={loadedReferralProgram}
                                                    />
                                            }
                                        </MainSCP>

                                        <LandingPageFooter/>
                                    </MainWrapperSCP>
                                }
                            />
                        </LayoutWrapperSCP>
                    </WrapperSCP>
                }
            </>
        </ReferralProgramLPWrapperCP>
    )
}

const WrapperSCP = styled.div`
  min-height: 100vh;
  width: 100%;
  background: #fff;
  overflow: hidden;
`

const LayoutWrapperSCP = styled.div`
  margin: 0 auto 0;
  width: 100%;
  max-width: 750px;
  padding: 0 20px;
  height: 100%;

  .ant-layout {
    background: none;
    overflow: hidden;
  }
`

const MainWrapperSCP = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const MainSCP = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

