import { Select } from 'antd'
import { IFormItemCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { FormModel } from 'common/form-state-manager/FormModel'
import { OrUndefinedTP } from 'common/types/OrUndefinedTP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormItemICP } from 'common/components/form-fields/inner/FormItemICP'
import { InputValueCallbackTP } from 'common/components/form-fields/inner/InputValueCallbackTP'
import { SelectCPUtils } from 'common/components/form-fields/select/inner/SelectCPUtils'
import { SelectOptionTP } from 'common/components/form-fields/select/inner/types/SelectOptionTP'

export interface ISelectCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP> {
    options: SelectOptionTP[]
    selectedOptions?: SelectOptionTP[]
    isMultiple?: boolean
    loading?: boolean
    defaultOpen?: boolean
    onSearch?: InputValueCallbackTP<string>
    onClose?: () => void
    returnFullOption?: boolean
    notFoundContent?: string | JSX.Element
    placeholder?: string | JSX.Element
    maxTagCount?: number
    labelInValue?: boolean
    allowClear?: boolean
    disableSelectAll?: boolean
    disabled?: boolean
}

/**
 * COMPONENTE
 * Input de multipla escolha para formularios.
 *
 * OBSERVACAO: Caso este componente seja controlado via 'form state manager' seu
 * valor nao eh determinado diretamente pela prop 'value'.
 *
 * @TODO 27/10/2020 implementar o funcionamento do  'selecionar todos'
 */
export function SelectCP<FModelTP extends FormModel = any>(props: ISelectCPProps): JSX.Element {

    const [value, setValue] = useState<any>()

    useEffect(() => setValue(props.value), [props.value])

    function handleChange(_valueParam: any): void {

        if (!props.onChange)
            return

        if (_valueParam === undefined)
            return props.onChange(undefined)

        const valueArr = Array.isArray(_valueParam) ? _valueParam : [_valueParam]
        const selectedOptions = !!props.returnFullOption ? valueArr.map(_value => getOptionByValue(_value)) : valueArr
        props.onChange(!!props.isMultiple ? selectedOptions : selectedOptions[0])
    }

    function getOptionByValue(singleValue?: number): OrUndefinedTP<SelectOptionTP> {

        let option = SelectCPUtils.getOptionByValue(props.options, singleValue)

        if (!option && !!props.selectedOptions)
            option = SelectCPUtils.getOptionByValue(props.selectedOptions, singleValue)

        return option
    }
    return (
        <SelectWrapperSCP>
            <FormItemICP<FModelTP, IFormItemCommonProps>
                label={props.label}
                onChange={handleChange}
                fieldName={props.fieldName}
                formStateManager={props.formStateManager}
                required={props.required}
                width={props.width}
                value={value}
                errorMessage={props.errorMessage}
                noValidation={props.noValidation}
                placeholder={props.placeholder}
                onBlur={props.onBlur}
            >
                <Select
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    optionFilterProp={'children'}
                    mode={!!props.isMultiple ? 'multiple' : 'default'}
                    loading={props.loading}
                    allowClear={props.allowClear}
                    labelInValue={props.labelInValue}
                    showSearch={true}
                    defaultOpen={props.defaultOpen}
                    maxTagCount={props.maxTagCount}
                    notFoundContent={props.notFoundContent ?? 'Nenhum item disponível'}
                    onSearch={props.onSearch}
                    onDropdownVisibleChange={(isVisible): void => {
                        if (!isVisible && !!props.onClose)
                            props.onClose()
                    }}
                >
                    {
                        props.options.map((opt: SelectOptionTP) => SelectCPUtils.renderOption(opt))
                    }
                </Select>
            </FormItemICP>
        </SelectWrapperSCP>
    )
}

const SelectWrapperSCP = styled.div`
    width: 100%;
`
