import React, { useEffect, useState } from 'react'
import { ModalCP } from 'common/components/modal/ModalCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { INameAndCodeResponseDTO } from 'common/dtos/responses/INameAndCodeResponseDTO'
import { EventRequests } from 'modules/referral-program/services/event/EventRequests'
import { IEventSaveRequestDTO } from 'modules/referral-program/services/event/dtos/request/IEventSaveRequestDTO'

interface ICPProps {
    visible: boolean
    code?: number
    initialName?: string
    onCancel: () => void
    onSave: (event: INameAndCodeResponseDTO) => void
}

/**
 * COMPONENTE Modal para enviar convite a uma pessoa.
 */
export function ModalEventCP(props: ICPProps): JSX.Element | null {

    const [eventName, setEventName] = useState<string>()

    const saveEventRequest = useRequest<INameAndCodeResponseDTO>()
    useEffect(onSaveEventRequestChange, [saveEventRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa a tela
     */
    function init(): void {

        if (!props.visible)
            return

        setEventName(props.initialName)
    }

    /**
     * Salva evento.
     */
    async function save(): Promise<void> {

        if (!eventName) {
            NotificationHelper.error('Ops!', 'Informe o nome do evento!')
            return
        }

        const dto: IEventSaveRequestDTO = {
            name: eventName
        }

        if (!!props.code)
            saveEventRequest.runRequest(EventRequests.update(props.code, dto))
        else
            saveEventRequest.runRequest(EventRequests.save(dto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveEventRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveEventRequest, 'Ocorreu algun erro ao salvar evento'))
            return

        NotificationHelper.info('Yes', 'Evento salvo com sucesso')
        props.onSave(saveEventRequest.responseData!)
    }

    return (
        <ModalCP
            title={'Evento de Meta'}
            visible={props.visible}
            destroyOnClose={true}
            onOk={save}
            onCancel={props.onCancel}
            loading={saveEventRequest.isAwaiting}
        >

            <InputCP
                label={'Nome do Evento'}
                required={true}
                value={eventName}
                onChange={setEventName}
            />

        </ModalCP>
    )
}
