import React from 'react'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import styled from 'styled-components'
import { TitleCP } from 'common/components/title/TitleCP'
import { CardCP } from 'common/components/card/CardCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { ReferralProgramConfigViewTP } from 'modules/referral-program/components/referral-program-config/ReferralProgramConfigCP'
import { ReferralProgramThanksFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramThanksFormModel'
import { ReferralProgramMainDataFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramMainDataFormModel'
import { TitleReferralProgramSubscribeCP } from 'modules/referral-program/components/title-referral-program-subscribe/TitleReferralProgramSubscribeCP'
import { LogoCP } from 'common/components/logo/LogoCP'
import { StepCustomerDataICP } from 'modules/referral-program/components/wizard-referral-program-subscribe/steps/StepCustomerDataICP'
import { ReferralRuleCP } from 'modules/referral-program/components/referral-rule/ReferralRuleCP'
import { ReferralProgramDescriptionFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramDescriptionFormModel'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'
import { FallbackScreenContentCP } from 'common/components/fallback-screen-content/FallbackScreenContent'
import { SmsPreviewCP } from 'common/components/sms-preview/SmsPreviewCP'

interface ISurveyConfigPreviewICPProps {
    mainDataFormStateManager: IFormStateManager<ReferralProgramMainDataFormModel>
    descriptionFormStateManager: IFormStateManager<ReferralProgramDescriptionFormModel>
    thanksFormStateManager: IFormStateManager<ReferralProgramThanksFormModel>
    previewView: ReferralProgramConfigViewTP
}

/**
 * Content da TELA de Criar novo documento para assinatura.
 */
export function ReferralProgramLPPreviewCP(props: ISurveyConfigPreviewICPProps): JSX.Element {

    return (
        <ContentCP>

            <WrapperSCP>

                <TitleCP>
                    👇🏻 Pré-visualização da Landing Page do Programa de Indicação
                </TitleCP>

                <LogoCP
                    marginTop={30}
                    marginBottom={30}
                    align={'center'}
                    imageUrl={props.mainDataFormStateManager.getFieldValue('logoUrl')}
                    width={'150px'}
                />

                <ConditionalRenderCP shouldRender={props.previewView === 'descriptionForm' || props.previewView === 'mainForm' || props.previewView === 'goalForm'}>
                    <CardCP>
                        <TitleReferralProgramSubscribeCP
                            title={props.mainDataFormStateManager.getFieldValue('title')}
                            subtitle={props.mainDataFormStateManager.getFieldValue('subtitle')}
                        />
                        <StepCustomerDataICP
                            referralProgramTermUrl={props.mainDataFormStateManager.getFieldValue('consentTermUrl')}
                        />
                    </CardCP>
                </ConditionalRenderCP>

                <ConditionalRenderCP shouldRender={props.previewView === 'descriptionForm'}>
                    <RuleWrapperSCP>
                        <ReferralRuleCP description={props.descriptionFormStateManager.getFieldValue('description')}/>
                    </RuleWrapperSCP>
                </ConditionalRenderCP>

                <ConditionalRenderCP shouldRender={props.previewView === 'thanksForm'}>
                    <CardCP>
                        <FallbackScreenContentCP
                            status={'success'}
                            title={'Parabéns!'}
                            message={
                                <MessageSCP>
                                    {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
                                    <div dangerouslySetInnerHTML={{ __html: props.thanksFormStateManager.getFieldValue('thanksMessage') }}/>
                                </MessageSCP>
                            }
                        />
                    </CardCP>

                    <RuleWrapperSCP>
                        <TitleCP underLine={true} marginTop={20} textSize={'normal'}>
                            Mensagem padrão do Whatsapp/SMS
                        </TitleCP>
                        <SmsPreviewCP
                            text={props.thanksFormStateManager.getFieldValue('inviteMessage')}
                        />
                    </RuleWrapperSCP>
                </ConditionalRenderCP>
            </WrapperSCP>
        </ContentCP>
    )
}

const WrapperSCP = styled.div`
  padding: 20px;
`

const RuleWrapperSCP = styled.div`
  padding: 0 30px;
`

const MessageSCP = styled.div`
  margin-top: 20px;
`
