import { Mentions } from 'antd'
import { FormItemICP } from 'common/components/form-fields/inner/FormItemICP'
import { IFormItemInputCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemInputCommonProps'
import React from 'react'
import styled from 'styled-components'
import { MarketingContentVarsConfigTP } from 'modules/marketing/types/MarketingContentVarsConfigTP'

interface IMentionCPProps extends IFormItemInputCommonProps {
    prefix: string
    options: MarketingContentVarsConfigTP[]
    rows?: number
    maxLength?: number
}

/**
 * COMPONENTE Mention do ant, que autocompleta quanto um caracter eh acionado.
 *
 * NOTE: Caso este componente seja controlado via 'form state manager' seu valor NAO eh determinado diretamente pela prop 'value'.
 * @see FormStateManager
 */
export function MentionCP(props: IMentionCPProps): JSX.Element {

    return (
        <MentionSCP>
            <FormItemICP<any, IFormItemInputCommonProps>
                label={props.label}
                onChange={props.onChange}
                fieldName={props.fieldName}
                formStateManager={props.formStateManager}
                required={props.required}
                width={props.width}
                onFormSubmit={props.onFormSubmit}
                value={props.value}
                mask={props.mask}
                fontSize={props.fontSize}
                errorMessage={props.errorMessage}
                noValidation={props.noValidation}
            >
                <Mentions
                    maxLength={props.maxLength}
                    rows={props.rows ? props.rows : 1}
                    prefix={props.prefix}
                >
                    {
                        props.options.map(value => (
                            <Mentions.Option key={value.value} value={value.value}>
                                {value.label}
                            </Mentions.Option>
                        ))
                    }
                </Mentions>
            </FormItemICP>
        </MentionSCP>
    )
}

const MentionSCP = styled.div`

    .ant-mentions {
        border-radius: 0 ;
        border: 1px none;border-bottom-style: solid;
        font-size: ${props => props.theme.fontSizes.normal};

        textarea {
            background: transparent;
        }
    }

`
