import React from 'react'
import { LinkCP } from 'common/components/link/LinkCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import styled from 'styled-components'

interface IAuthFormFooterCPProps {
    link: { text: string, onClick: () => void }
    button: { text: string, onClick: () => void }
    awaiting: boolean
}

/**
 * Rodape para formlarios do modulo de AUTENTICACAO.
 */
export function AuthFormFooterCP(props: IAuthFormFooterCPProps): JSX.Element {

    return (
        <WrapperSCP>
            <LinkContainerSCP>
                <LinkCP text={props.link.text} onClick={props.link.onClick} color={ThemeAnt.secondaryColor}/>
            </LinkContainerSCP>

            <ButtonCP
                size={'large'}
                type={'primary'}
                isSubmit={true}
                loading={props.awaiting}
                onClick={props.button.onClick}
            >
                {props.button.text}
            </ButtonCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
`

const LinkContainerSCP = styled.div`
    width: 100%;
    padding: 10px 0;
`
