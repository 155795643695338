import React, { useEffect } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

interface ICPProps {
    requestConfigTP: () => RequestConfigTP
    onDelete: () => void
}

/**
 * Coluna generica para remover um registro da tabela.
 */
export function ButtonDeleteRecordCP(props: ICPProps): JSX.Element {

    const deleteRequest = useRequest<void>('none')
    useEffect(onDeleteRequestChange, [deleteRequest.isAwaiting])

    /**
     * Remover
     */
    function remove(): void {
        deleteRequest.runRequest(props.requestConfigTP())
    }

    /**
     *  Retorno da remocao.
     */
    function onDeleteRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteRequest, NotificationHelper.DEFAULT_ERROR_DELETE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_DELETE_MESSAGE, true))
            return

        props.onDelete()
    }

    return (
        <ButtonCP
            size={'small'}
            type={'danger'}
            icon={'delete'}
            confirmMsg={NotificationHelper.DEFAULT_CONFIRM_DELETE_MESSAGE}
            onClick={remove}
            loading={deleteRequest.isAwaiting}
        />
    )
}
