import { ConfigProvider as AntDProvider } from 'antd'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { ThemeProvider } from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { AppInitializerCP } from 'modules/app/components/app/inner/AppInitializerCP'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'

/**
 * Componente inicial do sistema.
 */
export function App(): JSX.Element {

    return (
        <ReduxProvider store={ReduxHelper.getInstance().store}>
            <PersistGate loading={<LoadingOverlayCP show={true}/>} persistor={ReduxHelper.getInstance().persistor}>
                <AntDProvider renderEmpty={() => <EmptyCP/>}>
                    <ThemeProvider theme={ThemeAnt as any}>
                        <BrowserRouter>
                            <AppInitializerCP/>
                        </BrowserRouter>
                    </ThemeProvider>
                </AntDProvider>
            </PersistGate>
        </ReduxProvider>
    )
}
