import React from 'react'
import { AccountsRequests } from 'submodules/space4leads-sdk/services/accounts/AccountsRequests'
import { AccountResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/AccountResponseDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { TagActiveCP } from 'submodules/nerit-framework-ui/common/components/tag/tag-active/TagActiveCP'
import { PlanLabelEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'

interface ICPProps {
    reloadTable: number
    onSelectAccount: (account: AccountResponseDTO) => void
}

/**
 * Tela de listagem de contas.
 */
export function TableAccountsCP(props: ICPProps): JSX.Element {

    return (
        <TableFromApiCP<AccountResponseDTO>
            appearance={{
                wrappedOnCard: true,
                showReloadButton: true,
            }}
            shouldLoadData={props.reloadTable}
            apiConfig={{
                requestConfigTP: AccountsRequests.search
            }}
            columns={[
                {
                    title: 'Cód.',
                    render: (text, record) => record.code
                },
                {
                    render: (text, record) => <TagActiveCP isActive={record.isActive}/>
                },
                {
                    title: 'Nome da Conta',
                    render: (text, record) => record.name
                },
                {
                    title: 'Data de Criação',
                    render: (text, record) => DateUtils.formatDate(record.creationDate, DateFormatEnum.BR_WITHOUT_TIME)
                },
                {
                    title: 'Plano',
                    render: (text, record) => PlanLabelEnum[record.plan]
                },
                {
                    render: (text, record) =>
                        <FlexCP justifyContent={'flex-end'}>
                            <ButtonCP
                                size={'small'}
                                icon={'edit'}
                                onClick={() => props.onSelectAccount(record)}
                            />
                        </FlexCP>
                },
            ]}
        />
    )
}
