import { PlanEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'

export type PlanConfigTP = {
    plan: PlanEnum,
    paymentLink?: string
}

export const PlansConfig: PlanConfigTP[] = [
    {
        plan: PlanEnum.BASIC,
        paymentLink: 'https://buy.stripe.com/dR65lt6ao7HlahW000'
    },
    {
        plan: PlanEnum.IDEAL,
        paymentLink: 'https://buy.stripe.com/6oE5ltaqEbXB9dSaEF'
    },
    {
        plan: PlanEnum.PREMIUM,
        paymentLink: 'https://buy.stripe.com/eVa4hp1U85zd1Lq3ce'
    },
]
