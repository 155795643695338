import React from 'react'
import styled from 'styled-components'
import { CustomerLoginFormCP } from 'modules/referral-program/components/customer-login-form/CustomerLoginFormCP'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'
import { ReferralRuleCP } from 'modules/referral-program/components/referral-rule/ReferralRuleCP'
import { CardLoginCP } from 'modules/auth/components/card-login/CardLoginCP'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { HelpCP } from 'common/components/help/HelpCP'

interface IScreenContentCustomerReferralsLoginLPProps {
    onLoadCustomer: (customer: ICustomerPersonByReferralProgramResponseDTO) => void
    referralProgram: IReferralProgramResponseDTO
}

/**
 * Conteudo da Landing Page para logar o usuario
 */
export function ScreenContentCustomerReferralsLoginLP(props: IScreenContentCustomerReferralsLoginLPProps): JSX.Element {

    return (
        <WrapperSCP>
            <MainSCP>

                <CardWrapperFormSCP>
                    <CardLoginCP showCover={false}>
                        <div className='form-login'>
                            Bem-vindo ao seu portal de indicação, informe seus dados para continuar.
                            <WrapperFormSCP>
                                <CustomerLoginFormCP
                                    onLoadCustomer={props.onLoadCustomer}
                                    referralProgram={props.referralProgram}
                                />
                            </WrapperFormSCP>

                            <HelpCP text={'Clique em "Quero me Cadastrar", caso não esteja participando!'} type={'text'}/>
                        </div>
                    </CardLoginCP>
                </CardWrapperFormSCP>

                <ReferralRuleCP description={props.referralProgram.description}/>

            </MainSCP>
        </WrapperSCP>
    )
}

const WrapperFormSCP = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`

const CardWrapperFormSCP = styled.div`
  max-width: 410px;
`

const WrapperSCP = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const MainSCP = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`
