import { IIntegrationKeys } from 'submodules/space4leads-sdk/services/accounts/types/IIntegrationKeys'
import { PlanConfigTP, PlansConfig } from 'submodules/space4leads-sdk/services/accounts/types/PlanConfig'
import { PlanEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'

export const AccountUtils  = {

    /**
     * Obtem as configuracoes DEFAULT de um plano.
     */
    getPlanConfig(plan: PlanEnum): PlanConfigTP {
        return PlansConfig.find((planConfig) => planConfig.plan === plan)!
    },

    /**
     * Obtem a interface com todos os atributos de IntegrationKeys.
     */
    getEmptyIntegrationKeys(): IIntegrationKeys {
        return {
            subscribeWebhook: {
                postUrl: '',
                customArgs: '',
            },
            neritMarketing: {
                slug: '',
                secret: '',
                fromEmail: '',
                fromName: '',
            },
            externalData: {
                totalCustomersInBaseURL: '',
                totalCustomersInBaseHeaders: [
                    {
                        name: '',
                        value: 0,
                    }
                ],
                totalAdhesionAlreadyInBaseURL: '',
                totalAdhesionAlreadyInBaseHeaders: [
                    {
                        name: '',
                        value: 0,
                    }
                ],
            },
            uTalk: {
                token: '',
            }
        }
    }

}
