import React, { useEffect, useState } from 'react'
import { DateRangePickerCP } from 'common/components/date-range-picker/DateRangePickerCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { ReportsCommonFiltersFormModel } from 'modules/reports/components/drawer-referral-program-report-filters/inner/ReportsCommonFiltersFormModel'
import { DrawerFiltersCP } from 'common/components/screen-layout/filters/fast-filters-with-drawer/inner/DrawerFiltersCP'
import { IDateRangeFilter } from 'common/components/date-range-picker/inner/IDateRangeFilter'
import { AutocompleteReferralProgramCP } from 'modules/referral-program/components/autocomplete-referral-program/AutocompleteReferralProgramCP'
import { IReportCommonFiltersConfig } from 'modules/reports/components/drawer-referral-program-report-filters/inner/IReportCommonFiltersConfig'
import { AutocompleteFranchiseCP } from 'modules/admin/components/autocomplete-franchise/AutocompleteFranchiseCP'

interface IReportsCommonFiltersCPProps {
    formStateManager: IFormStateManager<ReportsCommonFiltersFormModel>
    config?: IReportCommonFiltersConfig
    onClearFilters?: () => void
    onFilter?: () => void
    onClose: () => void
    visible: boolean
    loading?: boolean
}

/**
 * Filtros Comuns dos relatorios.
 */
export function DrawerReferralProgramReportFiltersCP(props: IReportsCommonFiltersCPProps): JSX.Element {

    // Mantem as datas em estado aqui local pq senao nao o componente de datas nao gerencia
    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>()

    useEffect(init, [props.formStateManager.getFieldValue('dateRange')])

    /**
     * Inicializa.
     */
    function init(): void {
        setDateFilters(props.formStateManager.getFieldValue('dateRange'))
    }

    /**
     * Ao mudar as datas
     */
    function onChangeDates(dates: IDateRangeFilter): void {
        setDateFilters(dates)
        props.formStateManager.changeFieldValue('dateRange', dates)
    }

    return (
        <DrawerFiltersCP
            visible={props.visible}
            onClose={props.onClose}
            onFilter={props.onFilter}
            onClearFilters={props.onClearFilters}
            isLoading={props.loading}
            showButtons={props.config?.showButtons ?? true}
        >
            <AutocompleteReferralProgramCP
                label={'Programa de indicação'}
                formStateManager={props.formStateManager}
                fieldName={'referralProgramCode'}
                required={true}
                showOptionsOnLoad={true}
                onSelectReferralProgram={(program) => props.formStateManager.changeFieldValue('referralProgram', program)}
                onChange={props.onFilter} // Ao carregar o progama ja carrega o relatorio
            />

            <>
                {
                    props.config?.showFranchises &&
                    <AutocompleteFranchiseCP
                        label={'Selecione uma ou mais unidades'}
                        formStateManager={props.formStateManager}
                        fieldName={'franchisesCodes'}
                        isMultiple={true}
                    />
                }
            </>

            <>
                {
                    props.config?.showDateInterval &&
                    <DateRangePickerCP
                        value={dateFilters}
                        onChange={onChangeDates}
                        fastFilter={'all'}
                        allowClear={true}
                        showBorder={true}
                        marginBottom={20}
                    />
                }
            </>
        </DrawerFiltersCP>
    )
}
