import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ScreenSubmenuCP } from 'common/components/screen-layout-submenu/submenu/ScreenSubmenuCP'
import { ScreenSubMenuItemTP, ScreenSubMenuTP } from 'common/components/screen-layout-submenu/submenu/inner/ScreenSubMenuItemTP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import * as _ from 'lodash'
import { StringUtils } from 'common/utils/StringUtils'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LayoutSubmenuContentCP } from 'common/components/screen-layout-submenu/content/LayoutSubmenuContentCP'
import { ScreenSubmenuMobileICP } from 'common/components/screen-layout-submenu/submenu/inner/ScreenSubmenuMobileICP'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { OrUndefTP } from 'common/types/OrUndefTP'

interface IScreenContentSubmenuCPProps<ViewTP> {
    defaultSubmenu: ViewTP
    menuTitle: string
    submenuConfigs: Array<ScreenSubMenuTP<ViewTP>>
    defaultOpenKeys?: string[]
}

/**
 * Componente LayoutCP para telas que possuem submenu.
 */
export function LayoutSubmenuCP<ViewTP>(props: IScreenContentSubmenuCPProps<ViewTP>): JSX.Element {

    const [isPathResolved, setIsPathResolved] = useState<boolean>(false)
    const [currentMenuOption, setCurrentMenuOption] = useState<ViewTP>(props.defaultSubmenu)

    const screenSize = useScreenSize()
    const routeLocation = useLocation()

    useEffect(onPathChange, [routeLocation.pathname])

    function onPathChange(): void {

        setIsPathResolved(false)

        const pathWithoutDomain = StringUtils.stripInitialChars(routeLocation.pathname, `/${AppStateUtils.getDomainSlug()!}`)
        const viewForRoute = getSubMenuItem(pathWithoutDomain, 'route')?.view

        if (!viewForRoute)
            return onSelectionChange(props.defaultSubmenu)

        setCurrentMenuOption(viewForRoute)
        setIsPathResolved(true)
    }

    function onSelectionChange(view: ViewTP): void {
        RoutingHelper.historyPush(getSubMenuItem(view, 'viewTp')!.route)
    }

    function getSubMenuItem(lookup: string | ViewTP, lookType: 'route' | 'viewTp'): OrUndefTP<ScreenSubMenuItemTP<ViewTP>> {
        for (const sub of props.submenuConfigs) {
            const foundItem = _.find(sub.items, lookType === 'viewTp' ? { view: lookup } : { route: lookup }) as ScreenSubMenuItemTP<ViewTP>
            if (foundItem)
                return foundItem
        }
    }

    if (!isPathResolved)
        return <LoadingOverlayCP show={true}/>

    const _selectedMenuItem = getSubMenuItem(currentMenuOption, 'viewTp')
    if (!_selectedMenuItem)
        return <ContentCP>Conteúdo não encontrado</ContentCP>

    return (
        <LayoutCP
            sider={
                !screenSize.smd &&
                <ScreenSubmenuCP
                    onSelectMenuOption={onSelectionChange}
                    currentMenuOption={currentMenuOption}
                    title={props.menuTitle}
                    submenuConfigs={props.submenuConfigs}
                    defaultOpenKeys={props.defaultOpenKeys}
                />
            }
            content={
                <ContentCP>
                    {
                        _selectedMenuItem.contentTitle
                            ?
                            <>
                                <HeaderCP
                                    title={_selectedMenuItem.contentTitle}
                                    tourId={_selectedMenuItem.tourId}
                                >
                                    {_selectedMenuItem.contentTitleChildren}
                                </HeaderCP>

                                {
                                    screenSize.smd &&
                                    <ScreenSubmenuMobileICP
                                        onSelectMenuOption={onSelectionChange}
                                        currentMenuOption={currentMenuOption}
                                        submenuConfigs={props.submenuConfigs}
                                    />
                                }

                                <LayoutSubmenuContentCP>
                                    {_selectedMenuItem.content}
                                </LayoutSubmenuContentCP>
                            </>
                            :
                            _selectedMenuItem.content
                    }
                </ContentCP>
            }
        />
    )
}
