import React, { useEffect, useState } from 'react'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { MailIconCP } from 'common/components/icons/MailIconCP'
import { AuthFormFooterCP } from 'modules/auth/components/auth-form-footer/AuthFormFooterCP'
import { RecoverPasswordFormModel } from 'modules/auth/components/recover-password-form/inner/RecoverPasswordFormModel'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { UserRequests } from 'submodules/space4leads-sdk/services/people/user/UserRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'

interface IRecoverPasswordFormCPProps {
    goToLogin: () => void
}

/**
 * Formulario de RECUPERAR SENHA.
 */
export function RecoverPasswordFormCP(props: IRecoverPasswordFormCPProps): JSX.Element {

    const [formValidator] = useState<RecoverPasswordFormModel>(new RecoverPasswordFormModel())
    const formStateManager = useFormStateManager(formValidator)

    const recoverPasswordRequest = useRequest<void>('none')
    useEffect(onRecoverPasswordRequestChange, [recoverPasswordRequest.isAwaiting])

    /**
     * Reseta a senha
     */
    async function recoverPassword(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        recoverPasswordRequest.runRequest(UserRequests.resetPassword(formValues.email))
    }

    /**
     * Retorno ao alterar senha.
     */
    function onRecoverPasswordRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(recoverPasswordRequest, 'Erro ao alterar senha!', 'Senha redefinida com sucesso! Verifique seu e-mail e prossiga com o login', true))
            return

        props.goToLogin()
    }

    return (
        <>
            <InputCP
                label={'E-mail'}
                fieldName={'email'}
                formStateManager={formStateManager}
                required={true}
                onFormSubmit={recoverPassword}
                icon={<MailIconCP/>}
            />

            <AuthFormFooterCP
                link={{
                    text: 'Voltar para login',
                    onClick: props.goToLogin,
                }}
                button={{
                    text: 'Recuperar Senha',
                    onClick: recoverPassword,
                }}
                awaiting={recoverPasswordRequest.isAwaiting}
            />
        </>
    )
}
