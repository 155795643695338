import React from 'react'
import { List } from 'antd'
import styled from 'styled-components'

type _ScpPropsTP = {
    paddingRight?: number,
    paddingLeft?: number,
    paddingTop?: number,
    paddingBottom?: number,
}

interface IListItemICPProps extends _ScpPropsTP {
    title?: string | JSX.Element
    content?: JSX.Element | JSX.Element[] | string
    avatar?: JSX.Element
}

/**
 * COMPONENTE Item de uma lista
 */
export function ListItemICP(props: IListItemICPProps): JSX.Element {

    return (
        <List.Item>
            <ListItemContainerSCP>
                <List.Item.Meta
                    title={props.title}
                    description={props.content}
                    avatar={props.avatar}
                />
            </ListItemContainerSCP>
        </List.Item>
    )
}

const ListItemContainerSCP = styled.div<_ScpPropsTP>`
    width: 100%;

    .ant-list-item-meta {
        align-items: center;
    }
`
