import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { SiderCP } from 'common/components/screen-layout/sider/SiderCP'
import styled from 'styled-components'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { ReferralProgramLPPreviewCP } from 'modules/referral-program/components/referral-program-lp-preview/ReferralProgramLPPreviewCP'
import { WizardReferralProgramConfigCP } from 'modules/referral-program/components/wizard-referral-program-config/WizardReferralProgramConfigCP'
import { ReferralProgramMainDataFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramMainDataFormModel'
import { ReferralProgramThanksFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramThanksFormModel'
import { ReferralProgramDescriptionFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramDescriptionFormModel'
import { IReferralProgamSaveRequestDTO } from 'modules/referral-program/services/referral-program/dtos/requests/IReferralProgamSaveRequestDTO'
import { FormStateManagerUtils } from 'common/form-state-manager/FormStateManagerUtils'
import { StringUtils } from 'common/utils/StringUtils'
import { ReferralProgramRequests } from 'modules/referral-program/services/referral-program/ReferralProgramRequests'
import { ReferralProgramGoalFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramGoalFormModel'
import { IReferralProgramGoalSaveRequestDTO } from 'modules/referral-program/services/referral-program/dtos/requests/IReferralProgramGoalSaveRequestDTO'
import { SystemUtils } from 'common/utils/SystemUtils'
import { GoalFormModel } from 'modules/referral-program/components/modal-goal/inner/GoalFormModel'
import { RoutingHelper } from 'config/RoutingHelper'
import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'

export type ReferralProgramConfigViewTP = 'mainForm' | 'descriptionForm' | 'thanksForm' | 'goalForm'

interface ICPProps {
    referralProgram?: IReferralProgramResponseDTO
}

/**
 * TELA do modulo financeiro
 */
export function ReferralProgramConfigCP(props: ICPProps): JSX.Element {

    const [currentPreviewView, setCurrentPreviewView] = useState<ReferralProgramConfigViewTP>('mainForm')

    const [mainDataFormModel, setMainDataFormModel] = useState<ReferralProgramMainDataFormModel>((new ReferralProgramMainDataFormModel()))
    const mainDataFormStateManager = useFormStateManager<ReferralProgramMainDataFormModel>(mainDataFormModel)

    const [descriptionFormModel, setDescriptionDataFormModel] = useState<ReferralProgramDescriptionFormModel>((new ReferralProgramDescriptionFormModel()))
    const descriptionFormStateManager = useFormStateManager<ReferralProgramDescriptionFormModel>(descriptionFormModel)

    const [thanksFormModel, setThanksFormModel] = useState<ReferralProgramThanksFormModel>((new ReferralProgramThanksFormModel()))
    const thanksFormStateManager = useFormStateManager<ReferralProgramThanksFormModel>(thanksFormModel)

    const [goalFormModel, setGoalFormModel] = useState<ReferralProgramGoalFormModel>((new ReferralProgramGoalFormModel()))
    const goalFormStateManager = useFormStateManager<ReferralProgramGoalFormModel>(goalFormModel)

    const saveReferralProgramRequest = useRequest<any>()
    useEffect(onSaveSurveyRequestChange, [saveReferralProgramRequest.isAwaiting])

    useEffect(init, [props.referralProgram])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.referralProgram)
            return

        setMainDataFormModel(new ReferralProgramMainDataFormModel({
            name: props.referralProgram.name,
            title: props.referralProgram.title,
            subtitle: props.referralProgram.subtitle,
            logoUrl: props.referralProgram.logoUrl,
            consentTermUrl: props.referralProgram.consentTermUrl,
            franchiseCodes: props.referralProgram.participatingFranchises.map(franchise => franchise.code)
        }))

        setThanksFormModel(new ReferralProgramThanksFormModel({
            thanksMessage: props.referralProgram.thanksMessage,
            inviteMessage: props.referralProgram.inviteMessage,
        }))

        setDescriptionDataFormModel(new ReferralProgramDescriptionFormModel({
            description: props.referralProgram.description,
        }))

        setGoalFormModel(new ReferralProgramGoalFormModel({
            goals: props.referralProgram.goals?.map((goal) => ({
                code: goal.code,
                title: goal.title,
                rule: goal.rule,
                prize: goal.prize,
                quantity: goal.quantity,
                eventCodes: goal.configuredEvents?.map((event) => event.code)
            }))
        }))
    }

    /**
     * Salva as configuracoes do programa
     */
    function save(): void {

        if (!FormStateManagerUtils.validateRequiredFields(mainDataFormStateManager)
            || !FormStateManagerUtils.validateRequiredFields(descriptionFormStateManager)
            || !FormStateManagerUtils.validateRequiredFields(goalFormStateManager)
            || !FormStateManagerUtils.validateRequiredFields(thanksFormStateManager))
            return

        const mainDataFormValues = mainDataFormStateManager.getFormValues()!
        const descriptionFormValues = descriptionFormStateManager.getFormValues()!
        const thanksFormValues = thanksFormStateManager.getFormValues()!
        const goalsFormValues = goalFormStateManager.getFormValues()!

        if (SystemUtils.isEmpty(goalsFormValues.goals)) {
            NotificationHelper.error('Ops!', 'Cadastre ao menos uma meta')
            return
        }

        const goalsDto: IReferralProgramGoalSaveRequestDTO[] = goalsFormValues.goals.map((goal: GoalFormModel, index: number) => ({
            code: goal.code,
            title: goal.title,
            order: index + 1,
            quantity: goal.quantity,
            rule: goal.rule,
            prize: goal.prize,
            eventConfigCodes: goal.eventCodes,
        }))

        const dto: IReferralProgamSaveRequestDTO = {
            name: mainDataFormValues.name,
            slug: props.referralProgram?.slug ?? StringUtils.getSlugStyleString(mainDataFormValues.name),
            isActive: props.referralProgram?.isActive ?? true,
            title: mainDataFormValues.title,
            subtitle: mainDataFormValues.subtitle,
            logoUrl: mainDataFormValues.logoUrl,
            description: descriptionFormValues.description,
            consentTermUrl: mainDataFormValues.consentTermUrl,
            thanksMessage: thanksFormValues.thanksMessage,
            inviteMessage: thanksFormValues.inviteMessage,
            goals: goalsDto,
            franchiseCodes: mainDataFormValues.franchiseCodes,

        }

        if (!!props.referralProgram)
            saveReferralProgramRequest.runRequest(ReferralProgramRequests.update(props.referralProgram.code, dto))
        else
            saveReferralProgramRequest.runRequest(ReferralProgramRequests.save(dto))

    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveSurveyRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveReferralProgramRequest, 'Ocorreu algun erro ao salvar pesquisa'))
            return

        NotificationHelper.info('Yes', 'Programa de Indicação salvo com sucesso')
        RoutingHelper.historyPush(ReferralProgramRoutes.ROOT)
    }

    return (
        <>
            <LoadingOverlayCP show={saveReferralProgramRequest.isAwaiting}/>
            <LayoutCP
                sider={
                    <SiderCP width={450} scrollable={true}>
                        <SiderWrapperSCP>
                            <WizardReferralProgramConfigCP
                                mainDataFormStateManager={mainDataFormStateManager}
                                descriptionFormStateManager={descriptionFormStateManager}
                                thanksFormStateManager={thanksFormStateManager}
                                goalFormStateManager={goalFormStateManager}
                                onConfirm={save}
                                onChangeStep={setCurrentPreviewView}
                            />
                        </SiderWrapperSCP>
                    </SiderCP>
                }
                content={
                    <ReferralProgramLPPreviewCP
                        mainDataFormStateManager={mainDataFormStateManager}
                        descriptionFormStateManager={descriptionFormStateManager}
                        thanksFormStateManager={thanksFormStateManager}
                        previewView={currentPreviewView}
                    />
                }
            />
        </>
    )
}

const SiderWrapperSCP = styled.div`
    padding: 20px;
`
