import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import React from 'react'
import styled from 'styled-components'
import { DateUtils } from 'common/utils/date/DateUtils'

interface IShotListDateColumnICPProps {
    createDate: string
    isActive: boolean
}

/**
 * Coluna com status e datas do disparo.
 */
export function CsatItemDateColumnICP(props: IShotListDateColumnICPProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <ColumnSCP isSmallDevice={screenSize.smd}>
            <DateIconContainerSCP>
                <StatusSCP>
                    <StatusBallSCP/>
                </StatusSCP>
                {props.isActive ? 'Ativo' : 'Pausado'}
            </DateIconContainerSCP>

            <DatesContainerSCP isSmallDevice={screenSize.smd}>
                <span>Criado em</span>
                <h4>{DateUtils.getFormatted(props.createDate, DateFormatEnum.BR_DAY_MONTH)}</h4>
                <span>{ `às ${DateUtils.getFormatted(props.createDate, DateFormatEnum.TIME_H_M)} de ${DateUtils.getFormatted(props.createDate, DateFormatEnum.YEAR_4)}` }</span>
            </DatesContainerSCP>
        </ColumnSCP>
    )

}

const ColumnSCP = styled.div<{ isSmallDevice: boolean }>`
    width: ${props => (props.isSmallDevice ? '100%' : '120px')};
    border-right: 1px solid #dedede;
    background: #f5f5f5;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const DatesContainerSCP = styled.div<{ isSmallDevice: boolean }>`

  padding: 25px;
  flex-direction: column;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 10px;
  background: #fff;
  width: 100%;
  text-align: center;

  h4 {
    font-size: 1.7rem;
    margin-bottom: 0;
  }

  span {
    font-size: .7rem;
  }

  p {
    margin: 20px 0 10px;
  }
`

const DateIconContainerSCP = styled.div`

    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    align-items: center;
    padding: 20px 0;
    border-top-left-radius: 10px;

    span {
        text-transform: capitalize;
        margin-top: .5rem;
        font-weight: 700;
        font-style: italic;
        font-size: 0.8rem;
    }
`

const StatusSCP = styled.div`

  animation: blink 3s linear infinite;
  margin-top: 10px;
  color: ${props => props.theme.successColor};

  @keyframes blink{
    0%{opacity: .2;}
    20%{opacity: .6;}
    40%{opacity: 1;}
    60%{opacity: 1;}
    80%{opacity: .6;}
    100%{opacity: .2;}
  }
`

const StatusBallSCP = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: ${props => props.theme.successColor};
  margin-bottom: 10px;
`