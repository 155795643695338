import React, { useEffect, useState } from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { AccountResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/AccountResponseDTO'
import { AccountFormModel } from 'modules/admin/components/modal-account/inner/AccountFormModel'
import { EditorJsonCP } from 'submodules/nerit-framework-ui/common/components/editor-json/EditorJsonCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { SelectPlanCP } from 'modules/admin/components/select-plan/SelectPlanCP'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { AccountSaveRequestDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/requests/AccountSaveRequestDTO'
import { AccountsRequests } from 'submodules/space4leads-sdk/services/accounts/AccountsRequests'
import { AccountUtils } from 'submodules/space4leads-sdk/services/accounts/utils/AccountUtils'

interface ICPProps {
    visible: boolean
    onSave: () => void
    onCancel: () => void
    account: AccountResponseDTO
}

/**
 * Modal com dados da conta.
 */
export function ModalAccountCP(props: ICPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<AccountFormModel>((new AccountFormModel()))
    const formStateManager = useFormStateManager<AccountFormModel>(formValidator)

    const saveAccountRequest = useRequest<void>('none')
    useEffect(onSaveAccountRequestChange, [saveAccountRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        setFormValidator(new AccountFormModel())
        if (!props.visible)
            return

        setFormValidator(new AccountFormModel({
            name: props.account.name,
            isActive: props.account.isActive,
            integrationKeys: props.account.integrationKeys ?? AccountUtils.getEmptyIntegrationKeys(),
            plan: props.account.plan,
        }))
    }

    /**
     * Salva.
     */
    async function save(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const dto: AccountSaveRequestDTO = {
            name: formValues.name,
            isActive: formValues.isActive,
            plan: formValues.plan,
            integrationKeys: formValues.integrationKeys,
        }

        saveAccountRequest.runRequest(AccountsRequests.update(props.account.code, dto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveAccountRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveAccountRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            title={'Dados da Conta'}
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={save}
            actionLoading={saveAccountRequest.isAwaiting}
            width={800}
        >
            <RowCP>
                <ColumnCP size={12}>
                    <InputCP
                        formStateManager={formStateManager}
                        fieldName={'name'}
                        label={'Nome do Projeto'}
                    />
                </ColumnCP>
                <ColumnCP size={8}>
                    <SelectPlanCP
                        formStateManager={formStateManager}
                        fieldName={'plan'}
                    />
                </ColumnCP>
                <ColumnCP size={4}>
                    <SwitchCP
                        formStateManager={formStateManager}
                        fieldName={'isActive'}
                        label={'Ativo?'}
                        marginTop={10}
                    />
                </ColumnCP>
            </RowCP>

            <TitleCP textSize={'normal'} marginTop={30} underLine={true}>
                Configuração de Integrações
            </TitleCP>

            {
                !!formStateManager.getFieldValue('integrationKeys') &&
                <EditorJsonCP
                    value={formStateManager.getFieldValue('integrationKeys')}
                    onChange={(val) => formStateManager.changeFieldValue('integrationKeys', val)}
                />
            }

            {
                props.account.plan !== formStateManager.getFieldValue('plan') &&
                <AlertCP
                    message={'CUIDADO! Você está alterando o plano do usuário. Lembre-se de conferir se o mesmo pagou pela alteração. Tenha certeza da sua ação'}
                    type={'warning'}
                    margin={{ top: 20 }}
                />
            }
            {
                props.account.isActive !== formStateManager.getFieldValue('isActive') &&
                <AlertCP
                    message={'CUIDADO! Você está alterando o status da conta, tem certeza disso?'}
                    type={'warning'}
                    margin={{ top: 20 }}
                />
            }
        </ModalCP>
    )
}
