import { DatePicker } from 'antd'
import { RangePickerValue } from 'antd/lib/date-picker/interface'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ConditionalRenderCP } from 'submodules/nerit-framework-ui/common/components/conditional-render/ConditionalRenderCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { FastDateRangeFilterTP, FastFilterICP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/FastFilterICP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FastFilterMonthICP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/fast-filter-month/FastFilterMonthICP'

interface IScpProps {
    showBorder?: boolean
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

interface ICPProps extends IScpProps {
    onChange?: (dates: IDateRangeFilter, advancedFilterType?: FastDateRangeFilterTP) => void
    value?: IDateRangeFilter
    fastFilter?: FastDateRangeFilterTP
    allowClear?: boolean
    disabledDate?: ((current: moment.Moment | undefined) => boolean) | undefined
    label?: string
    fieldName?: any //keyof FModelTP
    formStateManager?: IFormStateManager<any> // FModelTP
    mode?: 'time' | 'date' | 'month' | 'year' | 'decade'
}

/**
 * Date picker customizado para range de datas (data inicial + data final).
 */
export const DateRangePickerCP = (props: ICPProps): JSX.Element => {

    const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false)
    const [dateRangePicker, setDateRangePicker] = useState<RangePickerValue>()

    useEffect(() => formatRangePicker(props.value), [props.value])
    useEffect(() => formatRangePicker(props.formStateManager?.getFieldValue(props.fieldName)), [props.formStateManager?.getFieldValue(props.fieldName)])

    /**
     * Metodo necessario para abstrair o tipo RangeDatePicker, tudo que chega eh convertido para RangeDatePicker
     */
    function formatRangePicker(dates?: IDateRangeFilter): void {

        if (!dates)
            return

        setDateRangePicker(([
            dates.beginDate ? moment(dates.beginDate) : undefined,
            dates.endDate ? moment(dates.endDate) : undefined
        ]) as any)
    }

    /**
     * Ao selecionar uma data no datePicker.
     */
    function onDateRangeSelection(dates: RangePickerValue): void {

        const dateFilter: IDateRangeFilter = {
            beginDate: !!dates[0] ? dates[0].toDate() : undefined,
            endDate: !!dates[1] ? dates[1].toDate() : undefined
        }

        setDateRangePicker(dates)
        setIsPickerOpen(false)

        if (!!props.onChange)
            props.onChange(dateFilter, undefined)

        if (!!props.formStateManager && !!props.fieldName)
            props.formStateManager.changeFieldValue(props.fieldName, dateFilter)
    }

    return (
        <WrapperSCP
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 0}
            marginLeft={props.marginLeft ?? 0}
        >
            <ConditionalRenderCP shouldRender={!!props.label}>
                <TextCP
                    text={props.label}
                    marginBottom={5}
                />
            </ConditionalRenderCP>

            <DatePickerContainerSCP showBorder={props.showBorder}>
                <DateRangePickerSCP
                    onOpenChange={(status: boolean) => setIsPickerOpen(status)}
                    value={dateRangePicker}
                    onChange={onDateRangeSelection}
                    open={isPickerOpen}
                    format={props.mode === 'month' ? DateFormatEnum.BR_MONTH_YEAR : DateFormatEnum.BR_WITHOUT_TIME}
                    placeholder={['Data inicial', 'Data final']}
                    allowClear={props.allowClear}
                    disabledDate={props.disabledDate as any}
                    mode={props.mode === 'month' ? ['month', 'month'] : ['date', 'date']}
                    renderExtraFooter={
                        props.mode === 'month'
                            ?
                            () => <FastFilterMonthICP onFilterSelection={(selectedDateRange) => onDateRangeSelection([moment(selectedDateRange.beginDate), moment(selectedDateRange.endDate)])}/>
                            :
                            !props.fastFilter
                                ? undefined
                                : () => (
                                    <FastFilterICP
                                        onFilterSelection={(selectedDateRange) => onDateRangeSelection([moment(selectedDateRange.beginDate), moment(selectedDateRange.endDate)])}
                                        currentFilter={props.fastFilter}
                                    />
                                )
                    }
                />
            </DatePickerContainerSCP>
        </WrapperSCP>
    )

}

const WrapperSCP = styled.div<IScpProps>`
  margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
`

const DatePickerContainerSCP = styled.div<{ showBorder?: boolean }>`
  height: ${props => props.showBorder ? undefined : '37px'};
  padding-bottom: 2px;
  border-bottom: ${props => props.showBorder ? 'solid 1px #BBBBBB' : ''};
`

const DateRangePickerSCP = styled(DatePicker.RangePicker)`
  &.ant-calendar-picker {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border: none;
    box-shadow: none;

    .ant-calendar-picker-icon {
      width: 28px;
      height: 28px;
      margin-top: -9px;
      left: 0;

      &:after {
        font-size: 20px;
        color: ${props => props.theme.primaryColor};
      }
    }

    .ant-calendar-picker-input {
      border: none;
      padding-left: 35px;
      color: ${props => props.theme.black};

      .ant-calendar-range-picker-separator {
        margin: 5px;
      }

      &:focus {
        box-shadow: none;
      }

      input::placeholder {
        color: #adadad;
      }
    }

    input {
      border: none;
      width: 40%;
      font-size: .8rem;
      color: ${props => props.theme.black};
    }
  }

  .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
    border: none;
    box-shadow: none;
  }
`
