import * as React from 'react'
import styled from 'styled-components'

interface IScpProps {
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

interface IFlexOneICPProps extends IScpProps {
    children: React.ReactNode
}

/**
 * COMPONENTE para flex 1 de um display flex
 */
export function FlexOneICP(props: IFlexOneICPProps): JSX.Element {
    return (
        <FilterWrapperSCP
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 0}
            marginLeft={props.marginLeft ?? 0}
        >
            {props.children}
        </FilterWrapperSCP>
    )
}

const FilterWrapperSCP = styled.div<IScpProps>`
    flex: 1;
    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
`
