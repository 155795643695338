import { SurveyTypeEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/SurveyTypeEnum'
import { AppStateUtils } from 'common/utils/AppStateUtils'

/**
 * Utiliario geral de survey.
 */
export const SurveyUtils = {

    getDefaultMainQuestionLabel(type: SurveyTypeEnum): string {
        if (type === SurveyTypeEnum.NPS)
            return `De 1 a 10 quanto você recomenda o ${AppStateUtils.getCurrentProject()!.name} para um amigo?`
        return 'O quão satisfeito você ficou com o atendimento recebido pela nossa equipe?'
    },

    getDefaultGoodQuestionLabel(type: SurveyTypeEnum): string {
        if (type === SurveyTypeEnum.NPS)
            return 'Ficamos felizes em saber que está satifeito(a), tem algo que podemos melhorar?'
        return 'Ficamos felizes em saber que está satifeito(a), tem algo que podemos melhorar?'
    },

    getDefaultBadQuestionLabel(type: SurveyTypeEnum): string {
        if (type === SurveyTypeEnum.NPS)
            return 'O que podemos fazer para que sua experiência seja melhor?'
        return 'O que podemos fazer para que sua experiência seja melhor?'
    },
}
