import React, { useEffect, useState } from 'react'
import { TextAreaCP } from 'common/components/form-fields/text-area/TextAreaCP'
import { TitleCP } from 'common/components/title/TitleCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { SurveyQuestionFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyQuestionFormModel'
import { SurveyUtils } from 'modules/survey/utils/SurveyUtils'
import { SurveyTypeEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/SurveyTypeEnum'

interface ISurveyQuestionsStepICPProps {
    formStateManager: IFormStateManager<SurveyQuestionFormModel>
    type: SurveyTypeEnum
}

/**
 * COMPOENENT Passo a passo para configurar a pesquisa NPS.
 */
export function SurveyQuestionsStepICP(props: ISurveyQuestionsStepICPProps): JSX.Element {

    const [mainQuestionLabel, setMainQuestionLabel] = useState<string>()
    const [goodAnswerQuestionLabel, setGoodAnswerQuestionLabel] = useState<string>()
    const [badAnswerQuestionLabel, setBadAnswerQuestionLabel] = useState<string>()

    useEffect(init, [props.formStateManager])

    /**
     * Inicializa.
     */
    function init(): void {
        setMainQuestionLabel(props.formStateManager.getFieldValue('mainQuestionLabel') ?? SurveyUtils.getDefaultMainQuestionLabel(props.type))
        setGoodAnswerQuestionLabel(props.formStateManager.getFieldValue('goodAnswerQuestionLabel') ?? SurveyUtils.getDefaultGoodQuestionLabel(props.type))
        setBadAnswerQuestionLabel(props.formStateManager.getFieldValue('badAnswerQuestionLabel') ?? SurveyUtils.getDefaultBadQuestionLabel(props.type))
    }

    return (
        <>
            <TextAreaCP
                label={'Pergunta principal'}
                required={true}
                value={mainQuestionLabel}
                onChange={(val) => {
                    props.formStateManager.changeFieldValue('mainQuestionLabel', val)
                    setMainQuestionLabel(val)
                }}
            />
            <TitleCP textSize={'normal'} underLine={true} marginTop={40} marginBottom={30}>
                Texto das observações
            </TitleCP>
            <TextAreaCP
                label={'Texto de observação para os Satisfeitos'}
                required={true}
                value={goodAnswerQuestionLabel}
                onChange={(val) => {
                    props.formStateManager.changeFieldValue('goodAnswerQuestionLabel', val)
                    setGoodAnswerQuestionLabel(val)
                }}
                hint={{
                    text: `Caso a nota dada seja entre ${props.type === SurveyTypeEnum.NPS ? '9 e 10' : '4 e 5'} defina qual a pergunta para que a pessoa dê detalhes da nota.`,
                    type: 'tooltip'
                }}
            />
            <TextAreaCP
                label={'Texto de observação para os Insatisfeitos'}
                required={true}
                value={badAnswerQuestionLabel}
                onChange={(val) => {
                    props.formStateManager.changeFieldValue('badAnswerQuestionLabel', val)
                    setBadAnswerQuestionLabel(val)
                }}
                hint={{
                    text: `Caso a nota dada seja inferior a ${props.type === SurveyTypeEnum.NPS ? '9' : '4'} defina qual a pergunta para que a pessoa dê detalhes da nota.`,
                    type: 'tooltip'
                }}
            />
        </>
    )
}
