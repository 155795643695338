import React from 'react'
import { TagCP } from 'common/components/tag/TagCP'

interface ITagCPProps {
    texts: string[]
}

/**
 * Passando mais de uma tag.
 */
export function TagsCP(props: ITagCPProps): JSX.Element {

    return (
        <>
            {
                props.texts.map((text, index) => (
                    <TagCP
                        key={`key-tag-${index}`}
                        content={text}
                    />
                ))
            }
        </>
    )
}
