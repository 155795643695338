import { MarketingContentVarsConfigTP } from 'modules/marketing/types/MarketingContentVarsConfigTP'
import { NotificationMessageSubstitutionsEnum } from 'modules/marketing/services/notification/enums/NotificationMessageSubstitutionsEnum'

/**
 * De-Para das variaveis para notificacoes
 */
export const NOTIFICATION_CONTENT_VARS: MarketingContentVarsConfigTP<NotificationMessageSubstitutionsEnum>[] = [
    {
        label: 'Nome do Indicado',
        value: '#nome_indicado##',
        linkedEnum: NotificationMessageSubstitutionsEnum.INDICATED_NAME,
    },
    {
        label: 'Nome de quem está indicando',
        value: '#nome_quem_indicando##',
        linkedEnum: NotificationMessageSubstitutionsEnum.WHO_INDICATES_NAME,
    },
    {
        label: 'Nome da Meta',
        value: '#nome_meta##',
        linkedEnum: NotificationMessageSubstitutionsEnum.GOAL_NAME,
    },
    {
        label: 'Prêmio da Meta',
        value: '#premio_meta##',
        linkedEnum: NotificationMessageSubstitutionsEnum.GOAL_PRIZE,
    },
]
