import React, { useEffect, useState } from 'react'
import { NpsScorePerPeriodListItemResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/NpsScorePerPeriodListItemResponseDTO'
import { SurveysRequests } from 'submodules/space4leads-sdk/services/surveys/surveys/SurveysRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SurveyScorePerPeriodRequestDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/request/SurveyScorePerPeriodRequestDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { ChartLineOrBarCP } from 'submodules/nerit-framework-ui/common/components/chart/ChartLineOrBarCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'

interface ICPProps {
    reload: number
    tags?: string[]
    dateRange?: DateRangeSearchRequestDTO
}

/**
 * Grafico de nota de NPS ao longo do tempo
 */
export function WidgetNpsPerPeriod(props: ICPProps): JSX.Element {

    const [npsScores, setNpsScores] = useState<NpsScorePerPeriodListItemResponseDTO[]>()

    const getNpsScoresRequest = useRequest<ListResponseDTO<NpsScorePerPeriodListItemResponseDTO>>()
    useEffect(onGetNpsScoresRequestChange, [getNpsScoresRequest.isAwaiting])

    useEffect(init, [props.reload])

    /**
     * Inicializa dados para tela.
     */
    function init(): void {

        const filters: SurveyScorePerPeriodRequestDTO = {
            tags: props.tags,
            dateRange: props.dateRange,
        }
        getNpsScoresRequest.runRequest(SurveysRequests.getNpsPerPeriod(filters))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetNpsScoresRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getNpsScoresRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setNpsScores(getNpsScoresRequest.responseData!.list)
    }

    return (
        <CardCP>
            <ChartLineOrBarCP
                xAxis={npsScores?.map((score) => DateUtils.getFormatted(score.date, DateFormatEnum.BR_WITHOUT_TIME))}
                ySeries={[
                    {
                        name: 'NPS',
                        data: npsScores?.map((score) => score.score) ?? [],
                        color: '#E8344D',
                    }
                ]}
                type={'line'}
                height={'300px'}
            />
        </CardCP>
    )

}
