/**
 * UTILS
 * Encapsula definicao de metodos genericos uteis para manipulacao de strings.
 */
export const StringUtils = {

    /** Altera & retorna 01 string na forma 'camel-case'. */
    toCamelCase(str: string): string {

        if (!str.match(/-/))
            return str

        return str
            .split('-')
            .reduce(
                (camelCaseStr: string, strToken: string) => {
                    if (!!camelCaseStr)
                        strToken = (strToken.charAt(0).toUpperCase() + strToken.slice(1))
                    return camelCaseStr + strToken
                },
                ''
            )
    },

    /** Transforma & retorna string da forma 'camelCase' na forma 'KebabCase'. */
    camelCaseToKebabCase(str: string): string {
        return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2')
            .replace(/\s/g, '-')
            .toLowerCase()
    },

    /** Obtem o primeiro nome de um nome completo. */
    getFirstName(fullName: string): string {
        if (!fullName || fullName === '')
            return ''

        return fullName.split(' ')[0]
    },

    /** Obtem o nome e sobrenome de um nome completo. */
    getFirstAndSecondName(fullName: string): string {

        if (!fullName || fullName === '')
            return ''

        const nameSplitted = fullName.split(' ')

        if (nameSplitted.length === 1)
            return nameSplitted[0]

        return `${nameSplitted[0]} ${nameSplitted[1]}`
    },

    /**
     * Obtem a primeira e ultima iniciais de uma string
     */
    getFirstAndLastInitials(str: string): string {

        if (!str)
            return ''

        const initials = str.match(/\b\w/g) ?? []
        return ((initials.shift() ?? '') + (initials.pop() ?? '')).toUpperCase()
    },

    /** Obtem as iniciais de uma string. */
    getInitials(str: string): string {

        if (!str)
            return ''

        return str.split(' ')
            .map((n) => n[0]).join(' ')
            .toUpperCase()
    },

    /** Retorna string sem nenhum acento. */
    removeAccents(string: string): string {

        const replacingCharGroups = {
            'áàâã': 'a',
            'éèê': 'e',
            'íìî': 'i',
            'óòôõ': 'o',
            'úùû': 'u',
            'ç': 'c'
        }

        for (const isUpperCase of [false, true]) {
            for (const charGroup in replacingCharGroups) {
                const charGroupForRegex = isUpperCase ? charGroup.toLocaleUpperCase() : charGroup
                const regex = new RegExp(`[${charGroupForRegex}]`, 'g')
                string = string.replace(regex, replacingCharGroups[charGroup])
            }
        }

        return string
    },

    /** Retorna string sem nenhum caracter especial. */
    removeSpecialCharacters(str: string): string {
        return str.replace(/[^\w\s-_]/gi, '')
    },

    /** Retorna sem acento, caracteres especiais, letras maiusculas ou espacos. */
    getSlugStyleString(string?: string, replaceSpaceWith = '-', maxLength?: number): string {

        if (!string)
            return ''

        if (!string)
            return ''

        string = StringUtils.removeAccents(string.toLowerCase())
        string = StringUtils.removeSpecialCharacters(string)

        replaceSpaceWith = replaceSpaceWith ? replaceSpaceWith : '-'
        string = string.replace(/[\s]/g, replaceSpaceWith)

        if (!!maxLength) {
            const regex = new RegExp(`^(.{${maxLength}})(.*)`, 'g')
            string = string.replace(regex, '$1')
        }

        return string
    },

    /** Separar string em 2, pegando a primeira ocorrencia do separador. */
    splitOnce(str: string, separator: string): [string, string] {
        const index = str.indexOf(separator)
        return [str.slice(0, index), str.slice(index + 1)]
    },

    /**
     * Retorna 01 string retirando um determinado conjunto especifico de caracteres SE eles estiverem
     * no COMECO da string.
     */
    stripInitialChars(string: string, chars: string): string {
        if (string === chars)
            return ''
        const regex = new RegExp(`^(${chars})(.+)`)
        return string.replace(regex, '$2')
    },

    /**
     * Retorna 01 string retirando um determinado conjunto especifico de caracteres SE eles estiverem
     * no FIM da string.
     */
    stripEndingChars(string: string, chars: string): string {
        if (string === chars)
            return ''
        const regex = new RegExp(`(.+)(${chars})$`)
        return string.replace(regex, '$1')
    },

    /**
     * Retorna 01 string retirando um determinado conjunto especifico de caracteres SE eles estiverem
     * no COMECO ou FIM da string.
     */
    stripBorderChars(string: string, chars: string): string {
        return StringUtils.stripEndingChars(StringUtils.stripInitialChars(string, chars), chars)
    },

    /**
     * Retorna 01 string retirando um determinado conjunto especifico de caracteres SE eles aparecerem
     * duplicados no inicio da string.
     */
    stripRepeatedBegin(string: string, chars: string): string {
        return string.replace(new RegExp(`^(${chars})(${chars}.*)`, 'g'), '$2')
    },

    replaceAll(string: string, from: string, to: string): string {
        const regex = new RegExp(StringUtils.escapeRegExp(from), 'g')
        return string.replace(regex, to)
    },

    escapeRegExp(string: string): string {
        return string.replace(/[$()*+.?[\\\]^{|}\-]/g, '\\$&') // $& means the whole matched string
    },
}
