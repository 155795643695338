import { SelectCurrentProjectCP } from 'modules/auth/components/select-current-project/SelectCurrentProjectCP'
import { MenuItemCP } from 'common/components/menu/menu-item/MenuItemCP'
import { MenuLogoCP } from 'common/components/menu/menu-logo/MenuLogoCP'
import { MenuCP } from 'common/components/menu/menu/MenuCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { StringUtils } from 'common/utils/StringUtils'
import styled from 'styled-components'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { SubMenuCP } from 'common/components/menu/sub-menu/SubMenuCP'
import { AuthActions } from 'modules/auth/AuthActions'
import { MainMenuItemTP } from 'modules/user/components/user-main-menu/inner/MainMenuItemTP'
import { ProfileMenuItemICP } from 'modules/user/components/user-main-menu/inner/ProfileMenuItemICP'
import { UserMenuUtils } from 'modules/user/components/user-main-menu/inner/UserMenuUtils'
import { PersonRoutes } from 'modules/person/routes/PersonRoutes'

interface ICPProps {
    onChangeCurrentFranchise: () => void
}

/**
 * Menu lateral do sistema.
 */
export function UserMainMenuCP(props: ICPProps): JSX.Element | null {

    const routeLocation = useLocation()

    /**
     * Renderiza os itens do menu.
     */
    function renderMenuItems(options: MainMenuItemTP[]): JSX.Element[] {
        return options
            .filter(option => option.enabled === undefined || option.enabled)
            .map(option => {

                if (!option.menuItemsChildren) {
                    return (
                        <MenuItemCP
                            key={`menu-opts-route${StringUtils.getSlugStyleString(option.title)}`}
                            tooltipTitle={option.renderType === 'text' ? undefined : option.title}
                            isActive={!!routeLocation.pathname.match(option.route)}
                            onClick={(): void => RoutingHelper.historyPush(option.route)}
                            marginVertical={option.renderType === 'text' ? 0 : 15}
                            align={option.renderType === 'text' ? 'left' : 'center'}
                        >
                            { option.renderType === 'text' ? option.title : option.iconComponent}
                        </MenuItemCP>
                    )
                }

                return (
                    <SubMenuCP
                        key={`menu-opts-route${StringUtils.getSlugStyleString(option.title)}`}
                        title={option.iconComponent}
                        onTitleClick={(): void => RoutingHelper.historyPush(option.route)}
                        hideArrow={true}
                        padding={'0 20px'}
                    >
                        { renderMenuItems(option.menuItemsChildren) }
                    </SubMenuCP>
                )
            })
    }

    return (
        <MenuContainerSCP>
            <MenuCP selectable={false} inlineCollapsed={false} width={67} mode={'vertical'}>
                <MenuLogoCP/>
                <SelectCurrentProjectCP
                    onChangeCurrentFranchise={props.onChangeCurrentFranchise}
                />
                { renderMenuItems(UserMenuUtils.getMenuOptionsTop()) }
            </MenuCP>

            <MenuCP selectable={false} inlineCollapsed={false} width={67}>

                { renderMenuItems(UserMenuUtils.getMenuOptionsBottom()) }

                <ProfileMenuItemICP
                    optionTitle={AppStateUtils.getLoggedUserData()?.user.name ?? 'Usuário'}
                    profileOptions={[
                        {
                            text: 'Editar Perfil',
                            action: (): void => RoutingHelper.historyPush(PersonRoutes.USER_PROFILE)
                        },
                        {
                            text: 'Sair',
                            action: (): void => {
                                AuthActions.logout()
                            }
                        }
                    ]}
                />
            </MenuCP>
        </MenuContainerSCP>
    )
}

const MenuContainerSCP = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  height: 100%;
  background: #060641;

  li.ant-menu-item {
    padding: 0px !important;
  }

  svg {
    font-size: 20px;
  }

  .ant-menu-item .anticon {
    margin-right: 0px !important;
  }
`
