import React from 'react'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import styled from 'styled-components'
import { LogoCP } from 'submodules/nerit-framework-ui/common/components/logo/LogoCP'

interface IContentAuthCPProps {
    children: JSX.Element | JSX.Element[]
    maxWidthContainer?: number
    logoUrl: string
    logoWidth: number
}

/**
 * Conteudo de um layout com imagem na lateral.
 */
export function ContentLayoutImageSiderICP(props: IContentAuthCPProps): JSX.Element | null {

    return (
        <ContentCP>
            <ContentWrapperSCP>
                <FormContainerSCP
                    maxWidth={props.maxWidthContainer}
                >
                    <LogoSCP>
                        <LogoCP
                            imgUrl={props.logoUrl}
                            width={props.logoWidth}
                            marginBottom={20}
                        />
                    </LogoSCP>

                    {props.children}

                </FormContainerSCP>
            </ContentWrapperSCP>
        </ContentCP>
    )
}

const ContentWrapperSCP = styled.div`
    display: flex;
    justify-content: center;
    overflow: hidden auto;
    height: 100%;
    align-items: center;
    padding: 20px;

    .ant-layout {
	    background: #fff;
	}
`

const LogoSCP = styled.div`
    text-align: center;
`

const FormContainerSCP = styled.div<{ maxWidth?: number }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: ${props => props.maxWidth ?? 500}px;
`
