import React, { useEffect, useState } from 'react'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { ModalCP } from 'common/components/modal/ModalCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ColumnCP } from 'common/components/grid/ColumnCP'
import { RowCP } from 'common/components/grid/RowCP'
import { IUserChangePasswordRequestDTO } from 'modules/user/services/dtos/request/IUserChangePasswordRequestDTO'
import md5 from 'md5'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { ChangePasswordFormModel } from 'modules/user/components/modal-change-password/inner/ChangePasswordFormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { UserRequests } from 'submodules/space4leads-sdk/services/people/user/UserRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'

interface IChangePasswordModalCPProps {
    visible: boolean
    onClose: () => void
}

/**
 * Modal de alteracao de senha.
 */
export function ModalChangePasswordCP(props: IChangePasswordModalCPProps): JSX.Element {

    const [formValidator] = useState<ChangePasswordFormModel>(new ChangePasswordFormModel())
    const formStateManager = useFormStateManager(formValidator)

    const changePasswordRequest = useRequest<void>('none')
    useEffect(onChangePasswordRequestChange, [changePasswordRequest.isAwaiting])

    /**
     * Faz requisicao na API para alteracao de senha.
     */
    async function changePassword(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!

        if (formValues.newPassword !== formValues.confirmNewPassword)
            return NotificationHelper.error('Ops!', 'A nova senha e sua confirmação não estão iguais')

        const dto: IUserChangePasswordRequestDTO = {
            newPassword: md5(formValues.newPassword),
            oldPassword: md5(formValues.currentPassword)
        }

        changePasswordRequest.runRequest(UserRequests.changePassword(AppStateUtils.getLoggedUserData()!.user.code, dto))
    }

    /**
     * Faz as validações de request, manipula o novo tokem que a API retorna após a troca de senha e reseta o modal
     */
    function onChangePasswordRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(changePasswordRequest, 'Não foi possível alterar a sua senha'))
            return

        formStateManager.reset()

        NotificationHelper.success('Sucesso', 'Senha alterada com sucesso!')
        props.onClose()
    }

    return (
        <ModalCP
            title={'Alterar Senha'}
            visible={props.visible}
            onOk={changePassword}
            onCancel={props.onClose}
            loading={changePasswordRequest.isAwaiting}
        >
            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={24}>
                    <InputCP
                        fieldName={'currentPassword'}
                        formStateManager={formStateManager}
                        required={true}
                        type={'password'}
                        label={'Senha Atual '}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={24}>
                    <InputCP
                        fieldName={'newPassword'}
                        formStateManager={formStateManager}
                        required={true}
                        type={'password'}
                        label={'Nova senha '}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={24}>
                    <InputCP
                        fieldName={'confirmNewPassword'}
                        formStateManager={formStateManager}
                        required={true}
                        type={'password'}
                        label={'Confirmar senha '}
                    />
                </ColumnCP>
            </RowCP>
        </ModalCP>
    )
}

