import React, { useEffect } from 'react'
import md5 from 'md5'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { CreateAccountPersonFormModel } from 'modules/admin/components/wizard-create-account/inner/CreateAccountPersonFormModel'
import { CreateAccountAccessFormModel } from 'modules/admin/components/wizard-create-account/inner/CreateAccountAccessFormModel'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { WizardCP } from 'submodules/nerit-framework-ui/common/components/wizard/WizardCP'
import { CreateAccountPersonContentStepICP } from 'modules/admin/components/wizard-create-account/steps/CreateAccountPersonContentStepICP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { CreateAccountAccessContentStepICP } from 'modules/admin/components/wizard-create-account/steps/CreateAccountAccessContentStepICP'
import { AccountCreateRequestDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/requests/AccountCreateRequestDTO'
import { AccountsRequests } from 'submodules/space4leads-sdk/services/accounts/AccountsRequests'

interface ITrialRequestWizardCPProps {
    personFormStateManager: IFormStateManager<CreateAccountPersonFormModel>
    accessFormStateManager: IFormStateManager<CreateAccountAccessFormModel>
    onCreateAccount: () => void
}

/**
 * Wizard de criacao de conta
 */
export function WizardCreateAccountCP(props: ITrialRequestWizardCPProps): JSX.Element {

    const createAccountRequest = useRequest<void>('none')
    useEffect(onCreateAccountRequestChange, [createAccountRequest.isAwaiting])

    /**
     * Salva politico no NP
     */
    async function createAccount(): Promise<void> {

        // Cada step validou seu FORM, entao nao precisa validar novamente aqui
        if(!await FormStateManagerUtils.validateRequiredFields(props.personFormStateManager) || !await FormStateManagerUtils.validateRequiredFields(props.accessFormStateManager))
            return

        const personFormValues = props.personFormStateManager.getFormValues()!
        const accessFormValues = props.accessFormStateManager.getFormValues()!

        if (accessFormValues.password !== accessFormValues.confirmPassword) {
            NotificationHelper.error('Ops!', 'A senha e a confirmação não estão iguais')
            return
        }

        // Faz pedido de trial
        const dto: AccountCreateRequestDTO = {
            account: {
                name: accessFormValues.accountName,
                isActive: true,
                plan: accessFormValues.plan,
            },
            personCustomerPassword: md5(accessFormValues.password),
            personCustomer: {
                name: personFormValues.name,
                email: personFormValues.email,
                phone: personFormValues.cellphone
            }
        }

        createAccountRequest.runRequest(AccountsRequests.create(dto))
    }

    /**
     * Retorno da api que salva candidato
     */
    function onCreateAccountRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(createAccountRequest, 'Ocorreu algun erro ao criar seu ambiente', undefined, true))
            return

        props.onCreateAccount()
    }

    return (
        <WizardCP
            loading={createAccountRequest.isAwaiting}
            action={createAccount}
            steps={[
                {
                    key: 'person-data',
                    title: 'Dados do Responsável',
                    content: <CreateAccountPersonContentStepICP formStateManager={props.personFormStateManager}/>,
                    nextAction: async () => await FormStateManagerUtils.validateRequiredFields(props.personFormStateManager)
                },
                {
                    key: 'access-data',
                    title: 'Configurações do Sistema',
                    content: (
                        <CreateAccountAccessContentStepICP
                            formStateManager={props.accessFormStateManager}
                            email={props.personFormStateManager.getFormValues()?.email}
                        />
                    ),
                },
            ]}
        />
    )
}
