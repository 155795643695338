export enum NotificationChannelEnum {
    WHATSAPP = 'WHATSAPP',
    SMS = 'SMS',
    EMAIL = 'EMAIL',
}

export enum NotificationChannelLabelEnum {
    WHATSAPP = 'Whatsapp',
    SMS = 'SMS',
    EMAIL = 'E-mail',
}