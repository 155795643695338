import React from 'react'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'

interface IDrawerMoreFiltersCPProps {
    children: JSX.Element | JSX.Element[]
    visible: boolean
    onClearFilters?: () => void
    onFilter?: () => void
    onClose: () => void
    isLoading?: boolean
    showButtons: boolean
}

/**
 * Drawer com os filtros avancados.
 */
export function DrawerFiltersCP(props: IDrawerMoreFiltersCPProps): JSX.Element {

    return (
        <DrawerCP
            title={'Filtros avançados'}
            visible={props.visible}
            footerSpaced={true}
            onClose={props.onClose}
            placement={'left'}
            width={341}
            showMask={false}
            maskClosable={false}
            footer={
                <ConditionalRenderCP shouldRender={props.showButtons}>
                    <>
                        {
                            !!props.onClearFilters &&
                            <ButtonCP
                                type={'ghost'}
                                onClick={props.onClearFilters}
                                loading={props.isLoading}
                            >
                                Limpar
                            </ButtonCP>
                        }
                        {
                            !!props.onFilter &&
                            <ButtonCP
                                type={'primary'}
                                onClick={props.onFilter}
                                loading={props.isLoading}
                            >
                                Filtar
                            </ButtonCP>
                        }
                    </>
                </ConditionalRenderCP>
            }
        >

            { props.children }

        </DrawerCP>
    )
}
