import { NOTIFICATION_CONTENT_VARS } from 'modules/marketing/types/NotificationContentVarsConfig'
import { StringUtils } from 'common/utils/StringUtils'

/**
 * UTILS para fazer o DE-PARA das variaveis para alguma linguagem.
 */
export const NotificationContentVarsTranslateUtils = {

    /**
     * Obtem a string do texto com variaveis em ingles (padrao do projeto) e retorna com as variaveis em protugues.
     */
    translateVarstoPtBr(orginalMsg: string): string {

        let translated: string = orginalMsg
        NOTIFICATION_CONTENT_VARS.forEach((contentVar) => {
            translated = StringUtils.replaceAll(translated, contentVar.linkedEnum!, `#${contentVar.value}`)
        })

        return translated
    },

    /**
     * Obtem a string do texto com variaveis em portugues e retorna com as variaveis em ingles (padrao do projeto).
     */
    translateVarstoEnUs(orginalMsg: string): string {

        let translated: string = orginalMsg
        NOTIFICATION_CONTENT_VARS.forEach((contentVar) => {
            translated = StringUtils.replaceAll(translated, `#${contentVar.value}`, contentVar.linkedEnum!.toUpperCase())
        })

        return translated
    },

}
