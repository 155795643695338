import { Modal } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React from 'react'
import styled from 'styled-components'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { ConditionalRenderCP } from 'submodules/nerit-framework-ui/common/components/conditional-render/ConditionalRenderCP'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'

type _AntPropsTP = {
    visible?: boolean,
    title?: string | JSX.Element,
    onOk?: () => void,
    onCancel?: () => void,
    okText?: string,
    cancelText?: string,
    width?: string | number,
    zIndex?: number,
    okButtonProps?: ButtonProps,
    destroyOnClose?: boolean,
    closable?: boolean,
}

type _CustomPropsTP = {
    onClose?: (okClicked: boolean) => void,
    disableOutsideClickClosing?: boolean,
    loading?: boolean,
    actionLoading?: boolean,
    singleButton?: boolean,
    noFooter?: boolean,
    isOkButtonDisabled?: boolean,
    top?: number,
    height?: number,
    mask?: boolean,
    footer?: JSX.Element,
}

export interface BasicModalCPProps {
    onCancel: () => void
    onSave: () => void
    visible: boolean
}

interface IModalCPProps extends React.PropsWithChildren<_AntPropsTP & _CustomPropsTP> {}

/**
 * Modal
 */
export function ModalCP(props: IModalCPProps): JSX.Element {

    const screenSize = useScreenSize()

    function onClose(okClicked: boolean): void {

        if (!!props.onClose)
            props.onClose(okClicked)

        if (okClicked && !!props.onOk)
            props.onOk()

        if (!okClicked && !!props.onCancel)
            props.onCancel()
    }

    function renderCustomFooter(): JSX.Element | undefined {

        if (!!props.noFooter) {
            return (
                <FlexCP justifyContent={'center'} alignItems={'center'}>
                    <TextCP text={NeritFrameworkProjectConfig.PROJECT_NAME}/>
                </FlexCP>
            )
        }

        if (!!props.singleButton) {
            return (
                <SingleButtonFooterWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={() => onClose(true)}
                        loading={props.loading}
                        disabled={props.isOkButtonDisabled}
                    >
                        {props.okText ?? 'Salvar'}
                    </ButtonCP>
                </SingleButtonFooterWrapperSCP>
            )
        }

        if (!!props.footer)
            return props.footer

        return undefined
    }

    if (!props.visible)
        return <></>

    return (
        <Modal
            title={props.title}
            visible={props.visible}
            onOk={() => onClose(true)}
            onCancel={() => onClose(false)}
            okText={props.okText ?? 'Salvar'}
            cancelText={props.cancelText ?? 'Cancelar'}
            width={props.width ?? 530}
            zIndex={props.zIndex}
            maskClosable={props.disableOutsideClickClosing !== true}
            mask={props.mask ?? true}
            destroyOnClose={props.destroyOnClose ?? true}
            okButtonProps={props.okButtonProps}
            confirmLoading={props.actionLoading}
            footer={renderCustomFooter()}
            closable={props.closable !== false}
            style={{
                top: screenSize.smd ? 10 : props.top,
            }}
            bodyStyle={{
                height: props.height,
                overflow: !!props.height ? 'hidden' : undefined,
                paddingBottom: !!props.height ? 10 : undefined,
            }}
        >
            <LoadingCP show={props.loading ?? false}/>
            <ConditionalRenderCP shouldRender={!props.loading}>
                <>{ props.children! }</>
            </ConditionalRenderCP>
        </Modal>
    )
}

const SingleButtonFooterWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
`
