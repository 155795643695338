import { FlexCP } from 'common/components/flex/FlexCP'
import { ButtonWhatsAppInviteFriendsCP } from 'modules/referral-program/components/button-whatsapp-invite-friends/ButtonWhatsAppInviteFriendsCP'
import React from 'react'
import { ColumnProps } from 'antd/es/table'
import { ICustomerReferralsResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/ICustomerReferralsResponseDTO'
import { IconGoalStatusCP } from 'modules/referral-program/components/icon-goal-status/IconGoalStatusCP'
import { SystemUtils } from 'common/utils/SystemUtils'
import { TextCP } from 'common/components/text/TextCP'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'

/**
 * Utilitarios gerais do componente de tabela
 */
export const TableCustomerReferralsUtils = {

    getTableColumns(
        referralProgram?: IReferralProgramResponseDTO,
        personToken?: string
    ): Array<ColumnProps<ICustomerReferralsResponseDTO>> {
        return [
            {
                render: (text, dto) => <IconGoalStatusCP wasAchived={SystemUtils.isEmpty(dto.eventsAwaiting)}/>
            },
            {
                title: 'Nome',
                render: (text, dto) => dto.person.name
            },
            {
                title: 'Ações necessárias',
                render: (text, dto) => (
                    <>
                        {
                            SystemUtils.isEmpty(dto.eventsAwaiting)
                                ?
                                <TextCP text={'Meta cumprida'}/>
                                :
                                <TextCP text={dto.eventsAwaiting.map((evt) => evt.name).join(', ')}/>
                        }
                    </>
                )
            },
            {
                render: (text, dto) => (
                    <>
                        {
                            !SystemUtils.isEmpty(dto.eventsAwaiting) && !!referralProgram &&
                            <FlexCP justifyContent={'flex-end'}>
                                <ButtonWhatsAppInviteFriendsCP
                                    size={'small'}
                                    buttonText={'Reforçar Indicação'}
                                    referralProgram={referralProgram}
                                    token={personToken}
                                    phone={dto.person.phone}
                                />
                            </FlexCP>
                        }
                    </>
                )
            },
        ]
    },

}
