import * as _ from 'lodash'
import { OrFunctionTP } from 'common/types/OrFunctionTP'
import { OrNullishTP } from 'common/types/OrNullishTP'
import { OrUndefTP } from 'common/types/OrUndefTP'

/**
 * UTILS
 * Encapsula logica auxiliar para uso geral.
 */
export const SystemUtils = {

    async sleep(time: number): Promise<void> {
        await new Promise(resolve => setTimeout(() => resolve(), time))
    },

    /**
     * Retorna 01 determinado valor se 01 determinada condicao for verdadeira. Caso contrario retorna
     * 01 valor padrao (que pode ser indefinido).
     */
    nvl<ValueTP = any>(condition: boolean, value: OrNullishTP<OrFunctionTP<ValueTP>>, defaultValue?: ValueTP): OrUndefTP<ValueTP> {
        let valueToReturn: any
        if (condition)
            valueToReturn = (typeof value === 'function') ? (value as Function)() : value
        return valueToReturn ?? defaultValue
    },

    isEmpty(value: unknown): boolean {
        return ((typeof value !== 'object' && !value)
            || (typeof value === 'object' && _.isEmpty(value))
        )
    },
}
