import React, { useEffect, useState } from 'react'
import { TableCP, TablePaginationTP } from 'common/components/table/TableCP'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { TableCustomerReferralsUtils } from 'modules/referral-program/components/table-customer-referrals/inner/TableCustomerReferralsUtils'
import { ICustomerReferralsResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/ICustomerReferralsResponseDTO'
import { TitleAndTextCP } from 'common/components/title-and-text/TitleAndTextCP'
import { ReferralProgramRequests } from 'modules/referral-program/services/referral-program/ReferralProgramRequests'
import { IReferralsSearchRequestDTO } from 'modules/referral-program/services/referral-program/dtos/requests/IReferralsSearchRequestDTO'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { TableUtils } from 'common/components/table/inner/TableUtils'

interface ICPProps {
    personCode?: number
    personToken?: string
    referralProgram?: IReferralProgramResponseDTO
    showInviteButton: boolean
    onLoadReport?: () => void
    shouldLoadReport?: boolean
}

/**
 * Tabela com as indicacoes de um cliente.
 */
export function TableCustomerReferralsCP(props: ICPProps): JSX.Element {

    const [pagination, setPagination] = useState<TablePaginationTP>(TableUtils.getDefaultPagination())
    const [customerReferrals, setCustomerReferrals] = useState<ICustomerReferralsResponseDTO[]>([])

    const getCustomerReferralsRequest = useRequest<IGenericListResponseDTO<ICustomerReferralsResponseDTO>>()
    useEffect(onGetCustomerReferralsRequestChange, [getCustomerReferralsRequest.isAwaiting])

    useEffect(() => init(true), [pagination.current])
    useEffect(init, [props.shouldLoadReport])

    /**
     * Inicializa.
     */
    function init(force?: boolean): void {

        if (force === undefined && props.shouldLoadReport === false)
            return

        if (!props.referralProgram)
            return

        const searchDto: IReferralsSearchRequestDTO = {
            whoIndicatesCode: props.personCode,
            itemsPerPage: pagination.pageSize,
            page: pagination.current
        }

        getCustomerReferralsRequest.runRequest(ReferralProgramRequests.getReferrals(props.referralProgram.code, searchDto))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetCustomerReferralsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getCustomerReferralsRequest, 'Não foi possível obter os metas deste usuário'))
            return

        setCustomerReferrals(getCustomerReferralsRequest.responseData!.list)
        setPagination({ ...pagination, total: getCustomerReferralsRequest.responseData!.total })

        props.onLoadReport?.()
    }

    return (
        <TableCP
            loading={getCustomerReferralsRequest.isAwaiting}
            columns={TableCustomerReferralsUtils.getTableColumns(
                props.showInviteButton ? props.referralProgram : undefined,
                props.personToken
            )}
            data={customerReferrals}
            expandedRowRender={(referral: ICustomerReferralsResponseDTO) => (
                <>
                    <TitleAndTextCP title={'Feito:'} text={referral.eventsDone.map((evt) => evt.name).join(', ')}/>
                    <TitleAndTextCP title={'Pendente:'} text={referral.eventsAwaiting.map((evt) => evt.name).join(', ')}/>
                </>
            )}
            pagination={pagination}
            onPaginationChange={(page) => setPagination({ ...pagination, current: page.current! })}
        />
    )
}
