import React, { useEffect, useState } from 'react'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { IFranchiseResponseDTO } from 'modules/admin/services/franchise/dtos/response/IFranchiseResponseDTO'
import { FranchiseFormModel } from 'modules/admin/components/drawer-franchise/inner/FranchiseFormModel'
import { FranchiseRequests } from 'modules/admin/services/franchise/FranchiseRequests'
import { FranchiseFormUtils } from 'modules/admin/components/drawer-franchise/inner/FranchiseFormUtils'
import { FormStateManagerUtils } from 'common/form-state-manager/FormStateManagerUtils'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { RowCP } from 'common/components/grid/RowCP'
import { ColumnCP } from 'common/components/grid/ColumnCP'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { TitleCP } from 'common/components/title/TitleCP'
import { AddressPanelCP } from 'modules/person/components/address-panel/AddressPanelCP'

interface IModalTreatmentGroupCPProps {
    visible: boolean
    onClose: (dataChanged?: boolean) => void
    code?: number
}

/**
 * Tela de listagem de tratamentos.
 */
export function DrawerFranchiseCP(props: IModalTreatmentGroupCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [formValidator, setFormValidator] = useState<FranchiseFormModel>((new FranchiseFormModel()))
    const formStateManager = useFormStateManager<FranchiseFormModel>(formValidator)

    const getFranchiseRequest = useRequest<IFranchiseResponseDTO>()
    useEffect(onGetFranchiseRequestChange, [getFranchiseRequest.isAwaiting])

    const saveFranchiseRequest = useRequest<IFranchiseResponseDTO>()
    useEffect(onSaveFranchiseRequestChange, [saveFranchiseRequest.isAwaiting])

    const deleteFranchiseRequest = useRequest<void>('none')
    useEffect(onDeleteFranchiseRequestChange, [deleteFranchiseRequest.isAwaiting])

    /**
     * Inicializa a tela dados para tela.
     */
    function init(): void {

        // Sempre reseta variaveis locais para nao pegar de forms antigos
        formStateManager.reset(new FranchiseFormModel({}))

        if (!props.visible)
            return

        if (props.code)
            return getFranchiseRequest.runRequest(FranchiseRequests.findOne(props.code))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetFranchiseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getFranchiseRequest, 'Erro obter unidade'))
            return

        const result = getFranchiseRequest.responseData!
        setFormValidator(new FranchiseFormModel(FranchiseFormUtils.getFormData(result)))
    }

    /**
     * Salva.
     */
    async function saveFranchise(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const dto = FranchiseFormUtils.getDto(formStateManager.getFormValues()!)

        if (!!props.code)
            saveFranchiseRequest.runRequest(FranchiseRequests.update(props.code, dto))
        else
            saveFranchiseRequest.runRequest(FranchiseRequests.create(dto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveFranchiseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveFranchiseRequest, 'Ocorreu algun erro ao salvar unidade'))
            return

        NotificationHelper.info('Yes', 'Unidade salva com sucesso')
        formStateManager.reset(undefined)
        props.onClose(true)
    }

    /**
     * Remover unidade.
     */
    function deleteFranchise(): void {

        if (!props.code)
            return

        deleteFranchiseRequest.runRequest(FranchiseRequests.delete(props.code))
    }

    /**
     *  Retorno da remocao.
     */
    function onDeleteFranchiseRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteFranchiseRequest, 'Não foi remover unidade', true))
            return

        NotificationHelper.info('Pronto', 'Unidade removida com sucesso')
        formStateManager.reset(undefined)
        props.onClose(true)
    }

    return (
        <DrawerCP
            title={'Unidade'}
            visible={props.visible}
            onClose={props.onClose}
            loading={getFranchiseRequest.isAwaiting}
            footerSpaced={!!props.code}
            footer={
                <>
                    <ConditionalRenderCP shouldRender={!!props.code}>
                        <ButtonCP
                            type={'danger'}
                            loading={deleteFranchiseRequest.isAwaiting}
                            confirmMsg={'Você tem certeza que deseja remover esta unidade?'}
                            onClick={deleteFranchise}
                        >
                            Remover
                        </ButtonCP>
                    </ConditionalRenderCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={saveFranchise}
                        loading={saveFranchiseRequest.isAwaiting}
                    >
                        Salvar
                    </ButtonCP>
                </>
            }
        >

            <RowCP>
                <ColumnCP size={24}>
                    <InputCP
                        fieldName={'name'}
                        formStateManager={formStateManager}
                        label={'Nome'}
                        required={true}
                        fontSize={'large'}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP>
                <ColumnCP size={14}>
                    <InputCP
                        fieldName={'cnpj'}
                        formStateManager={formStateManager}
                        mask={InputMaskTypeEnum.CNPJ}
                        label={'CNPJ'}
                        required={true}
                    />
                </ColumnCP>
                <ColumnCP size={10}>
                    <InputCP
                        fieldName={'phone'}
                        formStateManager={formStateManager}
                        label={'Telefone'}
                        mask={InputMaskTypeEnum.PHONE}
                        required={true}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP>
                <ColumnCP size={24}>
                    <InputCP
                        fieldName={'email'}
                        formStateManager={formStateManager}
                        label={'E-mail'}
                        required={true}
                    />
                </ColumnCP>
            </RowCP>

            <TitleCP underLine={true} textSize={'normal'} marginTop={10}>
                Endereço
            </TitleCP>
            <AddressPanelCP formStateManager={formStateManager}/>

        </DrawerCP>
    )
}
