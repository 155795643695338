import React, { useEffect, useState } from 'react'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { GoalFormModel } from 'modules/referral-program/components/modal-goal/inner/GoalFormModel'
import { ModalCP } from 'common/components/modal/ModalCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ColumnCP } from 'common/components/grid/ColumnCP'
import { RowCP } from 'common/components/grid/RowCP'
import { TextAreaCP } from 'common/components/form-fields/text-area/TextAreaCP'
import { AutocompleteEventCP } from 'modules/referral-program/components/autocomplete-event/AutocompleteEventCP'
import { FormStateManagerUtils } from 'common/form-state-manager/FormStateManagerUtils'

interface ICPProps {
    visible: boolean
    goal?: GoalFormModel
    onCancel: () => void
    onSave: (goalFormValues: GoalFormModel) => void
}

/**
 * COMPONENTE Modal para enviar convite a uma pessoa.
 */
export function ModalGoalCP(props: ICPProps): JSX.Element | null {

    const [formValidator, setFormValidator] = useState<GoalFormModel>((new GoalFormModel()))
    const formStateManager = useFormStateManager<GoalFormModel>(formValidator)

    useEffect(init, [props.visible])

    /**
     * Inicializa a tela
     */
    function init(): void {

        setFormValidator(new GoalFormModel({
            eventCodes: []
        }))

        if (!props.visible)
            return

        const goalFormModel = props.goal ?? new GoalFormModel()
        if (!goalFormModel.eventCodes)
            goalFormModel.eventCodes = []

        setFormValidator(new GoalFormModel(props.goal))
    }

    /**
     * Salva meta.
     */
    async function saveGoal(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        props.onSave(formStateManager.getFormValues()!)
    }

    return (
        <ModalCP
            title={'Meta'}
            visible={props.visible}
            destroyOnClose={true}
            onOk={saveGoal}
            onCancel={props.onCancel}
        >

            <RowCP>
                <ColumnCP size={20}>
                    <InputCP
                        fontSize={'large'}
                        label={'Nome da Meta'}
                        formStateManager={formStateManager}
                        fieldName={'title'}
                    />
                </ColumnCP>
                <ColumnCP size={4}>
                    <InputCP
                        label={'Qtde.'}
                        formStateManager={formStateManager}
                        required={true}
                        fieldName={'quantity'}
                        type={'number'}
                        hint={{ type: 'tooltip', text: 'Quantas pessoas precisam bater essa meta para que ela seja considerada batida' }}
                    />
                </ColumnCP>
            </RowCP>

            <AutocompleteEventCP
                label={'Escolhas os eventos para bater a meta'}
                required={true}
                formStateManager={formStateManager}
                fieldName={'eventCodes'}
            />

            <TextAreaCP
                label={'Descrição da regra para bater essa meta'}
                required={true}
                formStateManager={formStateManager}
                fieldName={'rule'}
            />

            <TextAreaCP
                label={'Descrição do prêmio ao bater essa meta'}
                required={true}
                formStateManager={formStateManager}
                fieldName={'prize'}
            />

        </ModalCP>
    )
}
