import { Form } from 'antd'
import * as _ from 'lodash'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import moment from 'moment'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { IFormItemInputCommonProps } from './interfaces/IFormItemInputCommonProps'
import { OrArrayTP } from 'submodules/nerit-framework-utils/utils/types/OrArrayTP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { ConditionalRenderCP } from 'submodules/nerit-framework-ui/common/components/conditional-render/ConditionalRenderCP'
import { HelpIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/HelpIconCP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'

type _SimpleSingleValueTP = number | string
type _ValueTP = OrArrayTP<_SimpleSingleValueTP> | moment.Moment

const CLASS_RISE_LABEL = 'rise-label'
const CLASS_ERROR = 'has-error'
const CLASS_ANIMATE = 'animate-label'

/**
 * OBS: Neste caso, excepcionalmente, eh necessario definir as props como 'type' ao invez de
 * 'interface' para possbilitar a tipagem correta.
 */
type _FormItemICPPropsTP<FModelTP extends FormModel, PropsTP extends IFormItemCommonProps<FModelTP>> = PropsTP & {
    children: JSX.Element,
    hideBorder?: boolean
}

/**
 * COMPONENTE
 * Wrapper generico a ser utilizado por inputs de formulario.
 *
 * NOTE: Caso este componente seja controlado via 'form state manager' seu valor nao eh determinado diretamente pela prop 'value'.
 * @see FormStateManager
 */
export function FormItemICP<
    FModelTP extends FormModel,
    PropsTP extends IFormItemCommonProps<FModelTP>
>(props: _FormItemICPPropsTP<FModelTP, PropsTP>): JSX.Element | null {

    const validationsCount = props.formStateManager?.validationsCount ?? 0

    const [value, setValue] = useState<_ValueTP>(props.value)
    const [mustRiseLabel, setMustRiseLabel] = useState<boolean>(false)
    const [validationErrMsg, setValidationErrMsg] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [hasFocus, setHasFocus] = useState<boolean>(false)

    useEffect(setValueByProps, [props.value])
    useEffect(parseValidation, [validationsCount])
    useEffect(onFormStateManagerChange, [props.formStateManager, props.reloadField])
    useEffect(handleErrMsgUpdate, [props.errorMessage, validationErrMsg])
    useEffect(updateLabelRisingState, [value, hasFocus])

    function updateLabelRisingState(): void {
        setMustRiseLabel(
            hasFocus
            || (typeof value !== 'object' && !!value)
            || (typeof value === 'object' && !_.isEmpty(value))
        )
    }

    function setValueByProps(): void {
        if (!!props.value)
            setValue(props.value)
    }

    function parseValidation(): void {

        if (!!props.noValidation)
            return

        if (!hasStateManager() || props.formStateManager!.isValid)
            return setValidationErrMsg(undefined)

        const fieldErrors = props.formStateManager!.getFieldError(props.fieldName as keyof FModelTP)
        const constraints = _.get(fieldErrors, 'constraints')

        if (!!constraints) {
            const errMessages: any = Object.values(constraints) || []
            if (!!errMessages.length)
                return setValidationErrMsg(errMessages[0])
        }

        setValidationErrMsg(undefined)
    }

    function onFormStateManagerChange(): void {

        if (!hasStateManager())
            return

        const stateValue = props.formStateManager!.getFieldValue(props.fieldName!)
        const isValidValue = (['number', 'string'].includes(typeof stateValue) || stateValue instanceof Array || stateValue instanceof Date)

        if (isValidValue)
            handleChange((stateValue instanceof Date) ? moment(stateValue) : stateValue)
    }

    function hasStateManager(): boolean {
        return (!!props.fieldName && !!props.formStateManager)
    }

    function hasMask(): boolean {
        return !!(props as IFormItemInputCommonProps).mask
    }

    function handleChange(eventOrValue: SyntheticEvent | string): void {

        // Analisa valor interno
        const receivedValue = ((eventOrValue as SyntheticEvent)?.target as any)?.value ?? eventOrValue
        const mustApplyMask = (hasMask() && !!receivedValue && !Array.isArray(receivedValue) && !(receivedValue instanceof Date))

        const exhibitionValue = mustApplyMask ? MaskUtils.applyMask(receivedValue, ((props as IFormItemInputCommonProps).mask! as any)) : receivedValue
        if (exhibitionValue === value)
            return

        setValue(exhibitionValue)

        const formItemProps = props as IFormItemInputCommonProps
        // Expele valor de saida
        const shouldClearMask = mustApplyMask && typeof receivedValue === 'string' && !formItemProps.keepMask && formItemProps.mask !== InputMaskTypeEnum.MONEY
        const outputValue = shouldClearMask ? receivedValue.replace(/\D/g, '') : receivedValue

        if (!!props.onChange)
            props.onChange(outputValue)

        if (hasStateManager())
            props.formStateManager!.changeFieldValue(props.fieldName!, outputValue)

    }

    function handleErrMsgUpdate(): void {
        if (!props.noValidation)
            setErrorMessage(props.errorMessage ?? validationErrMsg)
    }

    function handleBlur(eventOrValue: SyntheticEvent | string): void {
        setHasFocus(false)

        if (hasStateManager() && !props.noValidation)
            props.formStateManager!.validate()

        if (!!props.onBlur) {
            const newValue = _.get(eventOrValue, 'target.value', eventOrValue)
            props.onBlur(newValue)
        }
    }

    function handleFocus(): void {
        setHasFocus(true)
        if (hasStateManager())
            props.formStateManager!.setFieldDirty(props.fieldName!)
    }

    function getCssClasses(): string {

        let cssClasses = CLASS_ANIMATE

        if (mustRiseLabel)
            cssClasses += ` ${CLASS_RISE_LABEL}`

        if (!!errorMessage)
            cssClasses += ` ${CLASS_ERROR}`

        return cssClasses
    }

    function handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter' && !!props.onFormSubmit)
            props.onFormSubmit()
    }

    function getValueForChildren(): _ValueTP {
        const mustApplyMask = (hasMask() && !!value && !(value instanceof Array))
        return mustApplyMask
            ? MaskUtils.applyMask(value as _SimpleSingleValueTP, ((props as IFormItemInputCommonProps).mask! as any))
            : value
    }

    return (
        <WrapperSCP
            fontSize={props.fontSize ?? 'normal'}
            marginTop={props.marginTop ?? !!props.label ? 15 : 5}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 5}
            marginLeft={props.marginLeft ?? 0}
            hideBorder={props.hideBorder}
        >
            <Form.Item
                label={props.label}
                colon={false}
                className={getCssClasses()}
                required={props.required}
                style={{ width: props.width }}
            >
                {
                    React.cloneElement(
                        props.children,
                        {
                            onFocus: handleFocus,
                            onBlur: handleBlur,
                            onChange: handleChange,
                            onKeyPress: handleKeyPress,
                            value: getValueForChildren(),
                        }
                    )
                }

                <ConditionalRenderCP shouldRender={!!errorMessage}>
                    <div className={'ant-form-explain'}>
                        { errorMessage }
                    </div>
                </ConditionalRenderCP>

                {
                    !!props.hint &&
                    <div className={'ant-form-explain'}>
                        {
                            props.hint.type === 'tooltip'
                                ?
                                <FlexCP alignItems={'center'} justifyContent={'flex-end'}>
                                    <TooltipCP showSpan={true} text={props.hint.text}>
                                        <FlexCP alignItems={'center'}>
                                            <>Entenda</>
                                            <HelpIconCP size={11}/>
                                        </FlexCP>
                                    </TooltipCP>
                                </FlexCP>
                                :
                                props.hint.text
                        }
                    </div>
                }
            </Form.Item>
        </WrapperSCP>
    )
}

type ScpPropsTP = {
    fontSize: FontSizeTP,
    marginRight?: number,
    marginLeft?: number,
    marginTop?: number,
    marginBottom?: number,
}

const FormElementsCSS = css<ScpPropsTP>`
  border-radius: 0;
  border: 0 none;
  background-color: transparent !important;
  cursor: text;
  border-bottom: 1px solid #bbb;
  font-size: ${props => props.theme.fontSizes[props.fontSize]};
`

const WrapperSCP = styled.div<ScpPropsTP & {hideBorder?: boolean}>`

  /*ESTILOS GERAIS*/

  width: 100%;

  .ant-col:focus {
    outline: 0
  }


  .ant-row,
  .${CLASS_ANIMATE},
  .${CLASS_ERROR} {

    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
    line-height: 0;

    &:focus,
    .ant-form-item-control:focus,
    .ant-form-item-children:focus {
      outline: 0
    }

    .ant-form-item-label {

      margin-bottom: 5px;
      line-height: 35px;

      label {

        color: #adadad;

        &.ant-form-item-required {
          &:before {
            content: '';
          }

          &:after {
            display: inline-block;
            margin-right: 4px;
            font-size: 10px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
          }
        }
      }
    }

    .ant-form-explain {
      transition: opacity .3s;
      font-size: 12px;
      font-style: italic;
      margin: 2px 0;

      i {
        margin-left: 5px;
      }
      
      a {
        text-decoration: underline;
      }
    }
  }

  .${CLASS_ANIMATE} {

    position: relative;

    .ant-col {
      position: initial;
    }

    input {
      outline: 0;
      background-color: transparent;
    }

    label {
      position: absolute;
      transition: transform 0.5s ease, color 1s ease, font-size 1s ease;
    }

    &.${CLASS_RISE_LABEL} {
      label {
        transform: translate(2px, -15px) scale(1);
        color: #000;
      }

      input {
        border-bottom-color: #000;  
      }
      
      .ant-input-suffix {
        color: ${props => props.theme.darkGray};
      }
    }

    &:not(.${CLASS_RISE_LABEL}) {
      label {
        font-size: ${props => props.theme.fontSizes[props.fontSize || 'small']};
        transform: translate(0, 5px) scale(1);

      }
    }
  }

  .${CLASS_ERROR} {
    .ant-input-affix-wrapper {
      .ant-input:focus {
        box-shadow: none;
      }
    }

    &:not(.${CLASS_RISE_LABEL}) {
      .ant-select-arrow {
        color: transparent;
      }
    }
  }

  /*SELECT*/

  .ant-select {

    width: 100%;

    .ant-select-selection {

      ${props => props.hideBorder ? undefined : FormElementsCSS}
      .ant-select-enable {
        height: 0;
      }

      &.ant-select-selection--multiple {
        padding-bottom: 0px !important;

        .ant-select-selection__rendered {
          margin: 0;
        }
      }

      .ant-select-selection__rendered:focus,
      &:focus,
      &:active {
        box-shadow: none;
        outline: 0;
      }

      .ant-select-selection__rendered {

        ul {

          &:focus {
            outline: 0;
          }

          li:not(.ant-select-search) {
            height: 20px;
            margin: 2px;
            padding: 0 18px 0 6px;
            font-size: 12px;
            display: flex;
            line-height: 18px;
          }
        }
      }
    }
  }

  /*INPUT*/

  .ant-input {
    ${props => props.hideBorder ? undefined : FormElementsCSS}
  }

  .has-error {
    background-color: transparent !important;
  }

  .ant-input:focus,
  .has-error .ant-input:focus {
    box-shadow: none;
    border-color: transparent;
    border-bottom: 1px solid #000;
  }
`
