import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import Tooltip from 'antd/lib/tooltip'
import { FormModel } from 'common/form-state-manager/FormModel'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import React from 'react'
import styled from 'styled-components'
import { RadioOptionTP } from 'common/components/form-fields/radio-group/inner/RadioOptionTP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'

type _AntPropsTP = {
    disabled?: boolean,
}

type _ScpPropsTP = {
    buttonWidth?: number,
    buttonHeight?: number,
    secondary?: boolean,
    paddingTop?: number,
    fontSize?: FontSizeTP,
}

type _CustomPropsTP<OptionTP, FModelTP extends FormModel> = {
    selected?: OptionTP,
    onChange?: (value: OptionTP) => void,
    fieldName?: keyof FModelTP,
    formStateManager?: IFormStateManager<FModelTP>,
    options: Array<RadioOptionTP<OptionTP>>,
}

interface IRadioGroupCPProps<OptionTP = string, FModelTP extends FormModel = any>
    extends _AntPropsTP, _ScpPropsTP, _CustomPropsTP<OptionTP, FModelTP> { }

/**
 * COMPONENTE
 * Grupo de radio-butons (combo de selecao unica).
 */
export function RadioGroupCP<OptionTP = string, FModelTP extends FormModel = any>(props: IRadioGroupCPProps<OptionTP>): JSX.Element | null {

    const hasStateManager = (!!props.formStateManager && !!props.formStateManager)

    function handleChange(event: RadioChangeEvent): void {

        const nextValue = event.target.value

        if (!!props.onChange)
            props.onChange(nextValue)

        else if (hasStateManager)
            props.formStateManager!.changeFieldValue(props.fieldName!, nextValue)
    }

    if (!props.onChange && !hasStateManager) {
        console.error('FALHA - RadioGroupCPOLD: ', 'Parametrizacao invalida!')
        return null
    }

    return (
        <RadioGroupWrapperSCP
            buttonWidth={props.buttonWidth}
            buttonHeight={props.buttonHeight}
            secondary={props.secondary}
            paddingTop={props.paddingTop}
            fontSize={props.fontSize}
        >
            <Radio.Group
                value={props.selected}
                disabled={props.disabled}
                onChange={handleChange}
            >
                {
                    props.options.map((opt, index) => (
                        <Tooltip
                            title={opt.tooltip}
                            key={`opt-radio-group-${((opt.value ?? '') as string || index).toString()}`}
                        >
                            <Radio.Button value={opt.value} disabled={opt.disabled}>
                                {opt.content}
                            </Radio.Button>
                        </Tooltip>
                    ))
                }
            </Radio.Group>
        </RadioGroupWrapperSCP>
    )
}

const RadioGroupWrapperSCP = styled.div<_ScpPropsTP>`

    .ant-radio-group {
        display: flex;
        padding-top: ${props => props.paddingTop ?? '7'}px;
    }

    .ant-radio-button-wrapper {
        
        display: flex;
        width: ${props => (!!props.buttonWidth ? `${props.buttonWidth}px` : 'unset')};
        height: ${props => (!!props.buttonHeight ? `${props.buttonHeight}px` : 'unset')};
        align-items: center;
        justify-content: center;
        color: #03F6D1;
        border-color: #adadad;
        font-size: ${props => ThemeAnt.fontSizes[props.fontSize ?? 'normal']};

        &:not(.ant-radio-button-wrapper-checked) {
            background: #fff;
            color: ${props => props.theme.darkGray};
            &:hover {
                background: ${props => props.theme.normalColor};
                color: ${props => props.theme.darkerGray};;
                border-color:${props => props.theme.darkerGray};
            }
        }

        &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background: ${props => (!!props.secondary ? props.theme.secondaryColor : props.theme.primaryColor)};
            color: #ffffff;
            border-color: transparent;
            box-shadow: none;
        }
    }
`
