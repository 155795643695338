import React, { useState } from 'react'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { IReferralProgramSubscribeItemRequestDTO } from 'modules/person/services/dtos/request/IReferralProgramSubscribeItemRequestDTO'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { ModalCP } from 'common/components/modal/ModalCP'

interface ICPProps {
    visible: boolean
    onAdd: (friend: IReferralProgramSubscribeItemRequestDTO) => void
    onClose: () => void
}

/**
 * Etapa de indicador os amigos.
 */
export function ModalReferFriendCP(props: ICPProps): JSX.Element {

    const [friendName, setFriendName] = useState<string>()
    const [friendPhone, setFriendPhone] = useState<string>()

    /**
     * Ao salvar novo amigo.
     */
    function onAddFriend(): void {

        if (!friendName || !friendPhone) {
            NotificationHelper.error('Ops!', 'Informe o nome e telefone do amigo(a)')
            return
        }

        props.onAdd({
            name: friendName,
            phone: friendPhone
        })
    }

    return (
        <ModalCP
            visible={props.visible}
            top={5}
            onOk={onAddFriend}
            onCancel={props.onClose}
            width={250}
            destroyOnClose={true}
            title={'Indicação'}
        >
            <InputCP
                label={'Nome'}
                required={true}
                value={friendName}
                onChange={setFriendName}
            />

            <InputCP
                label={'Celular'}
                required={true}
                mask={InputMaskTypeEnum.PHONE}
                value={friendPhone}
                onChange={setFriendPhone}
            />
        </ModalCP>
    )
}
