import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { FormModel } from 'common/form-state-manager/FormModel'
import { IsPhoneBR } from 'common/validation/decorators/phone/IsPhoneBR'
import { IsEmail } from 'common/validation/decorators/IsEmail'

export class CustomerLoginFormModel extends FormModel {

    @IsNotEmpty()
    @IsEmail()
    email: string

    @IsNotEmpty()
    @IsPhoneBR()
    phone: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
