import React from 'react'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { StringUtils } from 'common/utils/StringUtils'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { ReportsScreen } from 'modules/reports/screens/reports/ReportsScreen'

ReportsRoutes.ROOT = '/reports'

ReportsRoutes.REFERRAL_PROGRAM_REPORTS_ROOT = `${ReportsRoutes.ROOT}/programs`
ReportsRoutes.REFERRAL_PROGRAM_LEADS = `${ReportsRoutes.REFERRAL_PROGRAM_REPORTS_ROOT}/leads`
ReportsRoutes.REFERRAL_PROGRAM_ANALYSIS = `${ReportsRoutes.REFERRAL_PROGRAM_REPORTS_ROOT}/analysis`

ReportsRoutes.MULTI_PROJECT_ROOT = `${ReportsRoutes.ROOT}/multi-projects`
ReportsRoutes.MULTI_PROJECT_NPS = `${ReportsRoutes.MULTI_PROJECT_ROOT}/nps`

ReportsRoutes.SURVEYS_REPORT_ROOT = `${ReportsRoutes.ROOT}/surveys`
ReportsRoutes.NPS_ANSWERS_REPORT = `${ReportsRoutes.SURVEYS_REPORT_ROOT}/nps-answers`
ReportsRoutes.CSAT_ANSWERS_REPORT = `${ReportsRoutes.SURVEYS_REPORT_ROOT}/csat-answers`

ReportsRoutes.SURVEY_CODE_PARAM = 'surveyCode'

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(ReportsRoutes.ROOT),
        path: ReportsRoutes.ROOT,
        component: ReportsScreen,
    },
]

/**
 * Componente que define as rotas para acesso ao modulo de RELATORIOS
 */
export function ReportsRoutes(): JSX.Element {

    return (
        <AccessControlCP>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}

