import { TextCP } from 'common/components/text/TextCP'
import React from 'react'
import styled from 'styled-components'
import { FlexCP } from 'common/components/flex/FlexCP'
import { HelpCP } from 'common/components/help/HelpCP'
import { LoadingCP } from 'common/components/loading/LoadingCP'

interface IScpProps {
    marginTop?: number
    marginRight?: number
    marginBottom?: number
    marginLeft?: number
    maxWidth?: number
    flex?: string
}

interface ICPProps extends IScpProps {
    title?: string
    children?: JSX.Element | JSX.Element[]
    className?: string
    footer?: JSX.Element | string
    help?: string
    loading?: boolean
}

export function CardDashboardWidgetWrapperCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP
            className={props.className}
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 10}
            marginBottom={props.marginBottom ?? 0}
            marginLeft={props.marginLeft ?? 10}
            maxWidth={props.maxWidth}
            flex={props.flex}
        >

            <FlexCP alignItems={'center'} marginBottom={20} justifyContent={!!props.help ? 'space-between' : undefined}>
                <TextCP text={props.title}/>
                {
                    props.help &&
                    <HelpCP
                        showOnlyIcon={true}
                        marginTop={0}
                        marginBottom={0}
                        text={props.help}
                        type={'tooltip'}
                    />
                }
            </FlexCP>

            {
                props.loading
                    ? <LoadingCP show={true}/>
                    : props.children
            }

            {
                !!props.footer &&
                <FooterSCP>
                    { props.footer }
                </FooterSCP>
            }

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<IScpProps>`
  background: #fff;
  border-radius: 5px;
  margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
  padding: 10px;
  max-width: ${props => props.maxWidth}px;
  flex: ${props => props.flex};
`

const FooterSCP = styled.div`
  background: #F7F7F7;
  margin: 20px -10px -10px -10px;
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`
