import React, { useEffect, useState } from 'react'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { CardsProjectsNpsCP } from 'submodules/space4leads-components-ui/report/components/cards-projetcs-nps/CardsProjectsNpsCP'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'

/**
 * Comparativo NPS entre projetos
 */
export function ScreenContentMultiProjectsNpsCP(): JSX.Element {

    const [reload, setReload] = useState<number>(1)

    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>()
    useEffect(() => setReload(TableUtils.getReloadNumber()), [dateFilters])

    return (
        <ContentCP overflowVertical={true}>

            <FlexCP justifyContent={'center'}>
                <CardCP innerSpacing={'small'}>
                    <DateRangePickerCP
                        value={dateFilters}
                        onChange={setDateFilters}
                        fastFilter={'all'}
                        allowClear={false}
                    />
                </CardCP>
            </FlexCP>

            <CardsProjectsNpsCP
                accountCode={AppStateUtils.getCurrentAccount()!.account.code}
                reload={reload}
                dateRange={dateFilters}
            />
        </ContentCP>
    )
}
