import React from 'react'
import { Menu, Tooltip } from 'antd'
import styled from 'styled-components'
import { ObjectUtils } from 'common/utils/ObjectUtils'

type _AlignItemTP = 'right' | 'left' | 'center'

type _AntPropsTP = {
    onClick: () => void,
    title?: string,
}

type _ScpPropsTP = {
    marginVertical?: number,
}

type _CustomPropsTP = {
    isActive?: boolean,
    tooltipTitle?: string,
    dataTour?: string,
    align?: _AlignItemTP,
}

const CLASS_ACTIVE_ANT = 'ant-menu-item-selected'

interface IMenuItemICPProps extends React.PropsWithChildren<_AntPropsTP & _ScpPropsTP & _CustomPropsTP> { }

/**
 * COMPONENTE: Item de 01 menu.
 * OBSERVACAO: Este componente so funciona perfeitamente se for FILHO DIRETO
 * de 01 componente de 'menu' ou 'submenu'
 *
 * @see MenuCP
 * @see SubMenuCP
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function MenuItemCP(props: IMenuItemICPProps): JSX.Element {

    function getAntProps(): {} {

        type _TypesToRemoveTP = _ScpPropsTP & _CustomPropsTP

        const cpProps: Array<keyof _TypesToRemoveTP> = [
            'isActive',
            'tooltipTitle',
            'align',
            'marginVertical',
        ]

        return ObjectUtils.getObjectsWithoutSomeProps<_TypesToRemoveTP>(props, cpProps)
    }

    function getClassName(): string {
        const isSelectable = (props as any).parentMenu?.props?.selectable ?? true
        return (!!props.isActive && isSelectable) ? CLASS_ACTIVE_ANT : ''
    }

    const justifyContent = (props.align === 'left')
        ? 'flex-start'
        : (props.align === 'right')
            ? 'flex-end' :
            (props.align === 'center' ? 'center' : 'space-between')

    return (
        <Tooltip placement={'right'} title={props.tooltipTitle}>
            <MenuItemWrapperSCP
                marginVertical={props.marginVertical}
                justifyContent={justifyContent}
                data-tour={props.dataTour}
            >
                <Menu.Item
                    className={getClassName()}
                    onClick={props.onClick}
                    {...getAntProps() /* eslint-disable-line react/jsx-props-no-spreading */}
                >
                    {props.children}
                </Menu.Item>

                {
                    !!props.isActive &&
                    <ActiveItemBarSCP/>
                }
            </MenuItemWrapperSCP>
        </Tooltip>
    )
}

const MenuItemWrapperSCP = styled.div<_ScpPropsTP & { justifyContent: string }>`

  position: relative;
  margin: ${props => (!!props.marginVertical ? `${Number(props.marginVertical).toString()}px 0` : 'unset')};

  .ant-menu-item {

    display: flex;
    justify-content: ${props => props.justifyContent};
    align-items: center;

    svg {
      margin: 0;
      color: ${props => props.theme.secondaryColor}
    }

    .ant-menu-item::after {
      border: none !important;
    }
  }
`

const ActiveItemBarSCP = styled.div`
  background-color: ${props => props.theme.secondaryColor};
  width: 3px;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
`
