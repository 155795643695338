import React from 'react'
import { RecoverPasswordFormCP } from 'modules/auth/components/recover-password-form/RecoverPasswordFormCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { CardLoginOLD } from 'modules/auth/components/card-login/CardLoginOLD'
import bgImg from 'modules/auth/components/auth-screen-cover/inner/auth-cover2.png'
import { LogoCP } from 'common/components/logo/LogoCP'
import styled from 'styled-components'

/**
 * TELA de Recuperar senha.
 */
export function ScreenRecoverPassword(): JSX.Element {

    return (
        <CenteredCardSCP>
            <CardLoginOLD>
                <div className='img-login' style={{ backgroundImage: `url(${bgImg})` }}/>

                <div className='form-login'>
                    <LogoCP align={'center'} marginTop={10}/>
                    <RecoverPasswordFormCP goToLogin={() => RoutingHelper.historyPush(AuthRoutes.USER_LOGIN)}/>
                </div>
            </CardLoginOLD>
        </CenteredCardSCP>
    )
}

const CenteredCardSCP = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
