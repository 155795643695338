import React from 'react'
import { TooltipCP, TooltipPlacementTP } from 'common/components/tooltip/TooltipCP'
import { PopConfirmCP } from 'common/components/pop-confirm/PopConfirmCP'

interface IButtonContentICPProps {
    children: JSX.Element
    onClick?: () => void
    tooltip?: string
    tooltipPlacement?: TooltipPlacementTP
    confirmMsg?: string
}

/**
 * COMPONENTE
 * Monta conteudo interno de 01 botao generico.
 *
 * TODO: Fazer tooltip funcionar junto com 'pop confirm'
 * @see ButtonCP
 */
export function ButtonContentICP(props: IButtonContentICPProps): JSX.Element {

    let buttonContentCp = props.children

    if (!!props.confirmMsg) {   // Inclui popup de confirmacao
        buttonContentCp = (
            <PopConfirmCP title={props.confirmMsg} onConfirm={props.onClick}>
                {buttonContentCp}
            </PopConfirmCP>
        )
    }

    if (!!props.tooltip) {  // Inclui tooltip
        buttonContentCp = (
            <TooltipCP
                text={props.tooltip}
                placement={props.tooltipPlacement}
            >
                {buttonContentCp}
            </TooltipCP>
        )
    }

    return buttonContentCp
}
