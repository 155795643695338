import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsEmail } from 'common/validation/decorators/IsEmail'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'

export class RecoverPasswordFormModel extends FormModel {

    @IsEmail()
    @IsNotEmpty()
    email: string

    constructor(initialValues?: Partial<RecoverPasswordFormModel>) {
        super()
        Object.assign(this, initialValues)
    }
}
