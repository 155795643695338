import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { TableSurveyAnswersCP } from 'modules/survey/components/table-survey-answers/TableSurveyAnswersCP'
import { SelectCsatsCP } from 'modules/survey/components/select-csats/SelectCsatsCP'
import { WelcomeContentCP } from 'submodules/nerit-framework-ui/common/components/welcome-content/WelcomeContentCP'
import { faCommentMedical } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'common/components/icons/FontAwsomeIconCP'
import { useLocation } from 'react-router'
import { ReportsRoutes } from 'modules/reports/routes/ReportsRoutes'

/**
 * Relatorio de respostas de CSAT
 */
export function ScreenContentCsatAnswersReportCP(): JSX.Element {

    const routeLocation = useLocation()

    const [selectedCsatCode, setSelectedCsatCode] = useState<number>()
    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        const query = new URLSearchParams(routeLocation.search)
        const surveyCodeParam = query.get(ReportsRoutes.SURVEY_CODE_PARAM)
        if (!!surveyCodeParam)
            setSelectedCsatCode(+surveyCodeParam)

    }

    return (
        <>
            <HeaderCP title={'Respostas do CSAT'}>
                <WrapperSCP>
                    <SelectCsatsCP
                        value={selectedCsatCode}
                        label={'Selecione uma pesquisa'}
                        onChange={setSelectedCsatCode}
                        width={400}
                    />
                </WrapperSCP>
            </HeaderCP>

            {
                !selectedCsatCode &&
                <WelcomeContentCP
                    message={'Selecione uma pesquisa para ver as respostas'}
                    icon={<FontAwsomeIconCP type={faCommentMedical} size={'4x'}/>}
                />
            }

            <ContentCP overflow={'auto'}>
                <ContentSCP>
                    {
                        !!selectedCsatCode &&
                        <TableSurveyAnswersCP
                            surveyCode={selectedCsatCode}
                        />
                    }
                </ContentSCP>
            </ContentCP>
        </>
    )
}

const WrapperSCP = styled.div`
  .wrapper-input {
    margin-bottom: 0px !important;
  }
`

const ContentSCP = styled.div`
  margin-bottom: 80px;
`
