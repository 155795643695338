import React, { useEffect, useState } from 'react'
import { TitleAndTextCP } from 'common/components/title-and-text/TitleAndTextCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { CardDashboardWidgetCP } from 'modules/dashboard/components/card-dashboard-widget/CardDashboardWidgetCP'
import { TitleCP } from 'common/components/title/TitleCP'
import { CardDashboardWidgetWrapperCP } from 'modules/dashboard/components/card-dashboard-widget-wrapper/CardDashboardWidgetWrapperCP'
import styled from 'styled-components'
import { FlexCP } from 'common/components/flex/FlexCP'
import { ChartPieCP } from 'common/components/chart/ChartPieCP'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { IAdhesionResponseDTO } from 'modules/reports/services/referral-program-reports/dtos/response/IAdhesionResponseDTO'
import { IReferralProgramStatusReportRequestDTO } from 'modules/reports/services/referral-program-reports/dtos/requests/IReferralProgramStatusReportRequestDTO'
import { ReferralProgramReportRequests } from 'modules/reports/services/referral-program-reports/ReferralProgramReportRequests'
import { ReportsCommonFiltersFormModel } from 'modules/reports/components/drawer-referral-program-report-filters/inner/ReportsCommonFiltersFormModel'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { DtoUtils } from 'common/dtos/DtoUtils'

interface ICPProps {
    filters: IFormStateManager<ReportsCommonFiltersFormModel>
    onLoadReport: () => void
    shouldLoadReport: boolean
}

/**
 * Analise do programa de indicacao
 */
export function WidgetsRowAdhesionCP(props: ICPProps): JSX.Element {

    const [subscribeReportData, setSubscribeReportData] = useState<Array<{ label: string, value: string }>>()
    const [engagementReportData, setEngagementReportData] = useState<Array<{ label: string, value: string }>>()

    const [adhesionReporData, setAdhesionReporData] = useState<IAdhesionResponseDTO>()
    const getAdhesionReportRequest = useRequest<IAdhesionResponseDTO>()
    useEffect(onGetAdhesionReportRequestChange, [getAdhesionReportRequest.isAwaiting])

    useEffect(init, [props.shouldLoadReport])

    /**
     * Requisicao API.
     */
    function init(): void {

        if (!props.shouldLoadReport)
            return

        const filterValues = props.filters.getFormValues()
        const requestDto: IReferralProgramStatusReportRequestDTO = {
            dateRange: DtoUtils.convertToDateRangeDto(filterValues?.dateRange),
            referralProgramCode: filterValues?.referralProgramCode,
            franchiseCodes: filterValues?.franchisesCodes,
        }

        getAdhesionReportRequest.runRequest(ReferralProgramReportRequests.getAdheredReport(requestDto))
    }

    /**
     * Retorno da requisicao.
     */
    function onGetAdhesionReportRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getAdhesionReportRequest, 'Não foi possível obter dados do relatório'))
            return

        const responseDto = getAdhesionReportRequest.responseData!
        setAdhesionReporData(responseDto)

        setSubscribeReportData([
            { label: 'Total novos cadastros no programa', value: MaskUtils.applyNumberMask(responseDto.totalRegistered, 0) },
            { label: 'Total de cadastros por indicacao', value: MaskUtils.applyNumberMask(responseDto.totalRegisteredByReferral, 0) },
            { label: 'Total cadastros diretos', value: MaskUtils.applyNumberMask(responseDto.totalRegisteredOnTheirOwn, 0) },
            { label: 'Total novas adesões', value: MaskUtils.applyNumberMask(responseDto.totalAdhered, 0) },
            { label: 'Total Clientes na base', value: MaskUtils.applyNumberMask(responseDto.totalCustomersInBase, 0) },
        ])

        setEngagementReportData([
            { label: 'Total GERAL indicações no programa', value: MaskUtils.applyNumberMask(responseDto.totalReferrals, 0) },
            { label: 'Média de indicações', value: MaskUtils.applyNumberMask(responseDto.averageReferralsByPerson ?? 0, 2) },
        ])

        props.onLoadReport()
    }

    return (
        <>
            <TitleCP underLine={true} textSize={'normal'}>Adesão ao Programa</TitleCP>
            <WidgetsTopSCP>
                <WidgetsTopItemSCP flex={'auto'}>
                    <CardDashboardWidgetWrapperCP title={'Dados da Base'} loading={getAdhesionReportRequest.isAwaiting}>
                        { subscribeReportData?.map(a => <TitleAndTextCP noBold={true} title={a.label} text={a.value} justifyContent={'space-between'} marginTop={27}/>) }
                    </CardDashboardWidgetWrapperCP>

                    <CardDashboardWidgetWrapperCP title={'Engajamento'} marginTop={20} loading={getAdhesionReportRequest.isAwaiting}>
                        { engagementReportData?.map(a => <TitleAndTextCP title={a.label} text={a.value} justifyContent={'space-between'} marginTop={27}/>) }
                    </CardDashboardWidgetWrapperCP>
                </WidgetsTopItemSCP>

                <WidgetsTopItemSCP flex={'auto'}>
                    <CardDashboardWidgetCP
                        title={'Taxa de Adesão'}
                        loading={getAdhesionReportRequest.isAwaiting}
                        widgetResponseDto={
                            !!adhesionReporData
                                ? { value: adhesionReporData.totalAdhered, target: adhesionReporData.totalCustomersInBase }
                                : undefined
                        }
                        targetLabel={'Total'}
                    />
                    <CardDashboardWidgetWrapperCP title={'Dos que aderiram, qtos estavam na base ou não'} marginTop={20} loading={getAdhesionReportRequest.isAwaiting}>
                        <FlexCP justifyContent={'center'}>
                            {
                                !!adhesionReporData &&
                                    <ChartPieCP
                                        series={[
                                            {
                                                id: 'base',
                                                label: 'Da base',
                                                value: adhesionReporData.totalAdhesionAlreadyInBase,
                                            },
                                            {
                                                id: 'nobase',
                                                label: 'Novos',
                                                value: adhesionReporData.totalAdhesionNotInBase,
                                            }
                                        ]}
                                        height={200}
                                        width={'300px'}
                                    />
                            }
                        </FlexCP>
                    </CardDashboardWidgetWrapperCP>
                </WidgetsTopItemSCP>
            </WidgetsTopSCP>
        </>
    )
}

const WidgetsTopSCP = styled.div`
  display: flex;
  align-items: flex-start;
`

const WidgetsTopItemSCP = styled.div<{ flex: string }>`
  flex: ${props => props.flex}
`
