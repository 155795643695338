import React from 'react'
import { IAutocompleteCommonProps } from 'common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { AutocompletePickerCP } from 'common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { FormModel } from 'common/form-state-manager/FormModel'
import { INameAndCodeResponseDTO } from 'common/dtos/responses/INameAndCodeResponseDTO'
import { FranchiseRequests } from 'modules/admin/services/franchise/FranchiseRequests'

interface ICPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
}

/**
 * COMPONENTE
 * Seletor de unidades..
 */
export function AutocompleteFranchiseCP<FModelTP extends FormModel = any>(props: ICPProps<FModelTP>): JSX.Element {

    return (
        <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
            requestConfigGetter={FranchiseRequests.searchNameAndCode}
            showOptionsOnLoad={true}
            // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
            {...props/* eslint-disable-line react/jsx-props-no-spreading */}
        />
    )
}
