import { Cascader } from 'antd'
import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { CascaderValueTP } from 'common/components/cascader/inner/CascaderValueTP'
import { CascaderOptionTP } from 'common/components/cascader/inner/CascaderOptionTP'

interface ICascaderCPProps {
    options: CascaderOptionTP[]
    onChange: (value: CascaderValueTP, selectedOptions?: CascaderOptionTP[]) => void
    value?: CascaderValueTP
    disabled?: boolean
    onPopupVisibleChange?: (isVisible: boolean) => void
    placeholder?: string
    selectionDisplay?: JSX.Element | string
    allowClear?: boolean
    size?: 'default' | 'large' | 'small'
    loadData?: (selectedOptions?: CascaderOptionTP[]) => void
    changeOnSelect?: boolean
}

/**
 * COMPONENTE
 * Cascader: Componente de selecao de valores aninhados (wrapper do componente cascader da biblioteca ant design).
 */
export function CascaderCP(props: ICascaderCPProps): JSX.Element {

    const [selectionValue, setSelectionValue] = useState<CascaderValueTP>([])

    useEffect(() => setSelectionValue(props.value ?? []), [props.value])

    function onChange(value: string[], selectedOptions?: CascaderOptionTP[]): void {
        setSelectionValue(value as CascaderValueTP)
        props.onChange(value as CascaderValueTP, selectedOptions)
    }

    return (
        <WrapperSCP>
            <Cascader
                options={props.options}
                placeholder={props.placeholder ?? 'Selecionar'}
                value={selectionValue}
                size={props.size ?? 'default'}
                onChange={onChange}
                allowClear={props.allowClear ?? false}
                disabled={props.disabled ?? false}
                onPopupVisibleChange={props.onPopupVisibleChange}
                loadData={props.loadData}
                changeOnSelect={props.changeOnSelect}
            >
                {
                    !!props.selectionDisplay &&
                    <a href={'#'}>{props.selectionDisplay}</a>
                }
            </Cascader>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

    .ant-cascader-picker {
        border-bottom: 2px solid black;
        width: 100%;
        padding-bottom: 3px;
        border-radius: 0;
    }

    input {
        border: 0 none;
    }

    a {
        display: flex;
    }
`
