import { FormModel } from 'common/form-state-manager/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsOptional } from 'class-validator'

export class ProjectFormModel extends FormModel {

    @IsRequired()
    name: string

    @IsRequired()
    isActive: boolean

    @IsOptional()
    logoUrl: boolean

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
