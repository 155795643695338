import React from 'react'
import styled from 'styled-components'
import { TitleCP } from 'common/components/title/TitleCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { QrCodeCP } from 'submodules/nerit-framework-ui/common/components/qr-code/QrCodeCP'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { LogoCP } from 'submodules/nerit-framework-ui/common/components/logo/LogoCP'
import { FontAwsomeIconCP } from 'common/components/icons/FontAwsomeIconCP'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { SurveyChannelsFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyChannelsFormModel'
import { ChannelConfigTP } from 'submodules/space4leads-sdk/services/surveys/surveys/types/ChannelConfigTP'
import { ChannelEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/ChannelEnum'

interface ICPProps {
    formStateManager: IFormStateManager<SurveyChannelsFormModel>
    survey: SurveyResponseDTO
    reloadChannelInfo: number
}

/**
 * Content da TELA de Criar novo documento para assinatura.
 */
export function SurveyQrCodeLayoutConfigPreviewICP(props: ICPProps): JSX.Element {

    /**
     * Busca o valor de um canal.
     */
    function getFieldValue(field: string): string {
        const channels: ChannelConfigTP[] = props.formStateManager.getFieldValue('channels')
        const foundChannel = channels.find((channel) => channel.channel === ChannelEnum.QRCODE)

        return foundChannel?.config?.layoutConfig?.[field] ?? ''
    }

    return (
        <WrapperSCP>
            {
                props.reloadChannelInfo &&
                <>
                    <LogoCP
                        imgUrl={props.survey.logoUrl ?? `${process.env.PUBLIC_URL}/logo-sistema.png`}
                        width={100}
                    />

                    <TitleCP underLine={true}>
                        <h1>
                            {getFieldValue('title')}
                        </h1>
                    </TitleCP>

                    <h2>
                        {getFieldValue('subtitle')}
                    </h2>

                    <QrCodeCP
                        margin={{ top: 30, bottom: 50 }}
                        url={props.survey.mainSurveyLink}
                        size={250}
                    />

                    <FooterSCP>
                        <FontAwsomeIconCP type={faMobileAlt} size={'2x'}/>
                        {getFieldValue('footer')}
                    </FooterSCP>

                    <PoweredBySCP>
                        Powered by Space4Leads
                    </PoweredBySCP>
                </>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  margin: 20px;
  border-radius: 5px;
  text-align: center;
  
  h1 {
    font-size: 50px;
    margin-bottom: 5px;
    margin-top: 30px;
  }
`

const FooterSCP = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  svg {
    margin-right: 10px;
  }
`

const PoweredBySCP = styled.div`
  color: ${ThemeFrameworkCommon.grayColor};
  margin-top: 30px;
`
