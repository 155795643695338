import React, { useState } from 'react'
import { FlexCP } from 'common/components/flex/FlexCP'
import { TimelineCustomerReferralsCP } from 'modules/referral-program/components/timeline-customer-referrals/TimelineCustomerReferralsCP'
import { TableCustomerReferralsCP } from 'modules/referral-program/components/table-customer-referrals/TableCustomerReferralsCP'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { RadioGroupCustomerReferralsViewCP } from 'modules/referral-program/components/radio-group-customer-referrals-view/RadioGroupCustomerReferralsViewCP'
import { StringUtils } from 'common/utils/StringUtils'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'
import styled from 'styled-components'
import { CardCP } from 'common/components/card/CardCP'
import { ModalReferMultipleFriendsCP } from 'modules/referral-program/components/modal-refer-multiple-friends/ModalReferMultipleFriendsCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { ButtonWhatsAppInviteFriendsCP } from 'modules/referral-program/components/button-whatsapp-invite-friends/ButtonWhatsAppInviteFriendsCP'

type ViewTP = 'referralsView' | 'timelineView'

interface ICPProps {
    referralProgram: IReferralProgramResponseDTO
    loggedCustomer: ICustomerPersonByReferralProgramResponseDTO
}

/**
 * Conteudo da Landing Page para captar respostas
 */
export function ScreenContentCustomerReferralsLandingPage(props: ICPProps): JSX.Element {

    const [view, setView] = useState<ViewTP>('timelineView')
    const [isInviteModalVisible, setIsInviteModalVisible] = useState<boolean>(false)

    return (
        <>
            <FlexCP justifyContent={'center'}>
                <>{`Seja bem-vindo(a) ${StringUtils.getFirstName(props.loggedCustomer.name)}, confira suas recompensas e indique mais amigos!`}</>
            </FlexCP>

            <ButtonsSCP>
                <ButtonsRightSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={() => setIsInviteModalVisible(true)}
                        marginRight={5}
                        icon={'usergroup-add'}
                    >
                        Indicar Amigos
                    </ButtonCP>

                    <ButtonWhatsAppInviteFriendsCP
                        buttonText={'Convidar pelo Whatsapp'}
                        referralProgram={props.referralProgram}
                        token={props.loggedCustomer.token}
                    />
                </ButtonsRightSCP>

                <RadioGroupCustomerReferralsViewCP
                    selectedView={view}
                    onChangeView={setView}
                />
            </ButtonsSCP>

            {
                view === 'timelineView'
                    ?
                    <TimelineCustomerReferralsCP
                        referralProgramCode={props.referralProgram.code}
                        personCode={props.loggedCustomer.code}
                    />
                    :
                    <CardCP innerSpacing={'none'} overflow={'auto'}>
                        <TableCustomerReferralsCP
                            personCode={props.loggedCustomer.code}
                            personToken={props.loggedCustomer.token}
                            referralProgram={props.referralProgram}
                            showInviteButton={true}
                            shouldLoadReport={true}
                        />
                    </CardCP>
            }

            <ModalReferMultipleFriendsCP
                visible={isInviteModalVisible}
                customer={props.loggedCustomer}
                onClose={() => setIsInviteModalVisible(false)}
                referralProgramSlug={props.referralProgram.slug}
            />
        </>
    )
}

const ButtonsSCP = styled.div`
  display: flex;
  margin: 50px 0 30px 0;
  align-items: center;

  @media (min-width: 600px) {
    justify-content: space-between;
  }

  @media (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
    
    .ant-radio-group {
      margin-top: 30px;
    }
  }
`
const ButtonsRightSCP = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 600px) {
    justify-content: space-between;
  }

  @media (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
    
    button {
      margin-bottom: 20px;
    }
  }
`
