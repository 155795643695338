import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { PermissionEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'
import { LogicOperationTP } from 'common/types/LogicOperationTP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { UserRoleEnum } from 'submodules/space4leads-sdk/services/people/person/enums/UserRoleEnum'
import { PlanEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'

export type FranchiseOrFranchisorTP = 'franchise' | 'franchisor' | 'all'

/**
 * UTILITARIOS: Permissoes de acesso a recursos do sistema.
 */
export class PermissionUtils {

    /** Exibe notificacao padrao tentativa de acesso a rota nao atorizada. */
    static showDefaultDeniedAccessMsg(): void {
        NotificationHelper.warning('Ops!', 'Você não tem permissão para acessar esse recurso.')
    }

    static isSpaceAdmin(): boolean {
        return AppStateUtils.getLoggedUserData()!.user.roles?.includes(UserRoleEnum.ADMIN_SPACE) ?? false
    }

    /**
     * Determina se usuario informado tem acesso a 01 recurso de acordo com a combinacao de exigencias de habilitacao de modulo & permissoes de acesso.
     */
    static hasAccess(permissions?: PermissionEnum[], plan?: PlanEnum): boolean {

        const isPermissionsOk = (!permissions || PermissionUtils.arePermissionsGranted(permissions))

        const currentAccountPlan = AppStateUtils.getCurrentAccount()!.account.plan
        const isPlanOk = !plan || plan === currentAccountPlan

        return isPermissionsOk && isPlanOk
    }

    /**
     * Determina se usuario informado possui permissoes informadas (logica 'and').
     */
    private static arePermissionsGranted(permissions: PermissionEnum[], logic: LogicOperationTP = 'or'): boolean {

        const userPermissions = AppStateUtils.getCurrentProject()?.permissions ?? []
        if (!userPermissions.length)
            return false

        for (const requiredPermission of permissions) {

            const hasPermission = userPermissions.includes(requiredPermission)

            if (hasPermission && logic === 'or')
                return true

            if (!hasPermission && logic === 'and')
                return false
        }

        return (logic === 'and')
    }
}
