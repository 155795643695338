import React from 'react'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { TextCP } from 'common/components/text/TextCP'
import { DescriptionCP } from 'common/components/description/DescriptionCP'
import { DescriptionItemCP } from 'common/components/description/description-item/DescriptionItemCP'
import styled from 'styled-components'
import { NOTIFICATION_CONTENT_VARS } from 'modules/marketing/types/NotificationContentVarsConfig'
import { StringUtils } from 'common/utils/StringUtils'

interface IDrawerSaleContractVarsCPProps {
    onClose: () => void
    visible: boolean
}

/**
 * Drawer para listagem de variaveis que podem ser inseridas nas notificacoes.
 */
export function DrawerReferralProgramNotificationVarsCP(props: IDrawerSaleContractVarsCPProps): JSX.Element {

    return (
        <DrawerCP
            title={'Variáveis Disponíveis'}
            visible={props.visible}
            onClose={props.onClose}
            width={550}
        >
            <DescriptionWrapperSCP>
                <DescriptionCP>
                    {
                        NOTIFICATION_CONTENT_VARS.map((varContent) => (
                            <DescriptionItemCP key={StringUtils.getSlugStyleString(varContent.label)} label={varContent.label}>
                                <TextCP copyable={true} text={varContent.value} onCopy={props.onClose}/>
                            </DescriptionItemCP>
                        ))
                    }
                </DescriptionCP>
            </DescriptionWrapperSCP>
        </DrawerCP>
    )
}

const DescriptionWrapperSCP = styled.div`

  .ant-descriptions-title {
    margin: 20px 0 0 0;
  }

  .ant-descriptions-row > td {
    padding-bottom: 0 !important;
  }

`
