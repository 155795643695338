import React, { useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'

const API_KEY = 'hxo1epw94lb8mahhakymzvmp382up2y6ha0a4sfx6g14bz0o'

interface ITinyMceCPProps {
    initialEditorContent?: string
    editorContent?: string
    onEditorContentChange: (e: any) => void
    onSetup?: (editor: any) => void
}

/**
 * Editor TinyMCE.
 */
export function TinyMceCP(props: ITinyMceCPProps): JSX.Element {

    const [isLoading, setIsLoading] = useState<boolean>(true)

    return (
        <>
            <LoadingOverlayCP show={isLoading}/>
            <Editor
                apiKey={API_KEY}
                onInit={() => setIsLoading(false)}
                initialValue={props.initialEditorContent}
                value={props.editorContent}
                init={{
                    height: 500,
                    menubar: false,
                    branding: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | addLogo | showVars',
                    setup: props.onSetup
                }}
                onEditorChange={props.onEditorContentChange}
            />
        </>
    )
}
