import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { IGenericSearchStringRequestDTO } from 'common/dtos/requests/IGenericSearchStringRequestDTO'
import { IEventSaveRequestDTO } from 'modules/referral-program/services/event/dtos/request/IEventSaveRequestDTO'

/**
 * Requests de Eventos.
 */
export class EventRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/events`

    static findByCode = (code: number): RequestConfigTP => ({
        url: `${EventRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static search = (dto: IGenericSearchStringRequestDTO): RequestConfigTP => ({
        url: `${EventRequests._MODULE_BASE}/`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static save = (dto: IEventSaveRequestDTO): RequestConfigTP => ({
        url: `${EventRequests._MODULE_BASE}/`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static update = (code: number, dto: IEventSaveRequestDTO): RequestConfigTP => ({
        url: `${EventRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

}
