import * as React from 'react'
import chromeIcon from './chrome_icon.png'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import styled from 'styled-components'

/**
 * Mensagem com a Notificacao.
 */
export function BrowserWarningContentICP(): JSX.Element {

    return (
        <ContentSCP>
            <img src={chromeIcon} width={30}/>
            <a href={'https://www.google.com.br/chrome/browser/desktop/'} target={'_blank'} rel={'noopener noreferrer'}>
                O {NeritFrameworkProjectConfig.PROJECT_NAME} é otimizado para o Google Chrome. Clique aqui para baixar.
            </a>
        </ContentSCP>
    )
}

const ContentSCP = styled.div`

    a {
        margin-left: 10px;
    }

    img {
        height: 24px;
        width: 24px;
    }
`
