import { Button } from 'antd'
import { ButtonContentICP } from 'common/components/button/inner/ButtonContentICP'
import { ButtonTypeTP } from 'common/components/button/inner/ButtonTypeTP'
import { TooltipPlacementTP } from 'common/components/tooltip/TooltipCP'
import { ColorUtils } from 'common/utils/ColorUtils'
import styled from 'styled-components'
import React from 'react'
import { ButtonSizeTP } from 'common/components/button/inner/ButtonSizeTP'

type WrapperScpPropsTP = {
    marginRight?: number,
    marginLeft?: number,
    marginBottom?: number,
    marginTop?: number,
    color?: string,
    horizontalPadding?: number,
    bgColor?: string,
    borderColor?: string,
}

type CustomPropsTP = {

    onClick?: () => void,
    type?: ButtonTypeTP,
    shape?: 'circle' | 'circle-outline' | 'round',
    size?: ButtonSizeTP,
    ghost?: boolean,

    tooltip?: string,
    tooltipPlacement?: TooltipPlacementTP,
    confirmMsg?: string,

    loading?: boolean,
    disabled?: boolean,
    isSubmit?: boolean,

    icon?: string,
    style?: React.CSSProperties,
}

const CLASS_HORIZONTAL_PADDING = 'button_horizontal_padding'

interface IButtonCPProps extends React.PropsWithChildren<CustomPropsTP & WrapperScpPropsTP> {}

/**
 * COMPONENTE
 * Botao generico customizavel.
 */
export function ButtonCP(props: IButtonCPProps): JSX.Element {

    return (
        <WrapperSCP
            marginLeft={props.marginLeft}
            marginRight={props.marginRight}
            marginBottom={props.marginBottom}
            marginTop={props.marginTop}
            color={props.color}
            bgColor={props.bgColor}
            borderColor={props.borderColor}
            horizontalPadding={props.horizontalPadding}
            className={!!props.horizontalPadding ? CLASS_HORIZONTAL_PADDING : ''}
        >
            <ButtonContentICP
                onClick={props.onClick}
                tooltip={props.tooltip}
                tooltipPlacement={props.tooltipPlacement}
                confirmMsg={props.disabled ? undefined : props.confirmMsg}
            >
                <Button
                    htmlType={!!props.isSubmit ? 'submit' : 'button'}
                    onClick={!props.confirmMsg ? props.onClick : undefined}
                    disabled={props.disabled}
                    type={props.type ?? 'default'}
                    size={props.size}
                    ghost={props.ghost ?? false}
                    shape={props.shape}
                    icon={props.icon}
                    loading={props.loading}
                    style={props.style}
                >
                    {props.children}
                </Button>
            </ButtonContentICP>
        </WrapperSCP>
    )

}

const WrapperSCP = styled.div<WrapperScpPropsTP>`

  margin-left: ${props => props.marginLeft ?? 0}px;
  margin-right: ${props => props.marginRight ?? 0}px;
  margin-bottom: ${props => props.marginBottom ?? 0}px;
  margin-top: ${props => props.marginTop ?? 0}px;
  color: ${props => props.color ?? 'inherit'};

  &.${CLASS_HORIZONTAL_PADDING} {
    .ant-btn {
      padding: 0 ${props => props.horizontalPadding}px;
    }
  }

  .ant-btn {

    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${props => props.bgColor ?? props.theme.white};

    border-color: ${props => props.borderColor ?? 'transparent'};
    border-width: ${props => (!!props.borderColor ? '1px' : 0)};
    border-style: ${props => (!!props.borderColor ? 'solid' : 'none')};

    &.ant-btn-primary {

      background-color: ${props => props.theme.primaryColor};
      transition: background-color 0.20s ease-in;

      :disabled {
        background: ${props => props.theme.gray};
        color: ${props => props.color ?? props.theme.white};
        border: none;
      }

      &:hover:not([disabled]) {
        background: ${props => ColorUtils.lightenColor(props.theme.primaryColor, 65)};
      }
    }

    &.ant-btn-icon-only {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.ant-btn-ghost {
      background-color: ${props => props.bgColor ?? 'transparent'};
      border: 1px solid ${props => props.borderColor ?? props.theme.primaryColor};
      color: ${props => props.color ?? 'inherit'};
    }

    &.ant-btn-default {
      background-color: ${props => props.theme.secondaryColor};
      transition: background-color 0.20s ease-in;
      color: #FFF;
    }

    &.ant-btn-link {
      background-color: ${props => props.bgColor ?? 'transparent'};
      color: ${props => props.color ?? 'inherit'};
      height: unset;
      width: unset;
    }

    &.ant-btn-loading {

      display: flex;
      align-items: center;

      .anticon-loading {
        color: ${props => props.color ?? 'inherit'};
        margin-right: 7px;
      }
    }

    &.ant-btn-danger {
      background-color: ${props => props.bgColor ?? props.theme.errorColor};
    }

    &.ant-btn-info {
      background-color: ${props => props.bgColor ?? props.theme.infoColor};
    }

    &.ant-btn-success {
      background-color: ${props => props.bgColor ?? props.theme.successColor};
    }

    .anticon {
      display: flex;
      align-items: center;
    }

    .ant-typography {
      margin-left: 4px;
    }
  }
`
