import * as _ from 'lodash'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { RoutingHelper } from 'config/RoutingHelper'
import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { UserDataTP } from 'common/redux/types/UserDataTP'
import { RequestHelper as RequestHelperOLD } from 'common/request-manager/RequestHelper'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { AuthUtils } from 'common/utils/AuthUtils'
import { SystemUtils } from 'common/utils/SystemUtils'
import { ISchemaResponseDTO } from 'modules/app/services/dtos/response/ISchemaResponseDTO'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { AuthUserAccountResponseDTO } from 'submodules/space4leads-sdk/services/auth/dtos/responses/inner/AuthUserAccountResponseDTO'
import { AuthUserProjectsResponseDTO } from 'submodules/space4leads-sdk/services/auth/dtos/responses/inner/AuthUserProjectsResponseDTO'
import { AuthUserResponseDTO } from 'submodules/space4leads-sdk/services/auth/dtos/responses/inner/AuthUserResponseDTO'

/**
 * ACTIONS
 * Encapsula definicao de acoes envolvendo estado global (redux) relativas ao modulo de autenticacao.
 */
export class AuthActions {

    static readonly TYPE_UPDATE_DOMAIN = 'update_domain'
    static readonly TYPE_UPDATE_USER = 'update_user'
    static readonly TYPE_UPDATE_CURRENT_ACCOUNT = 'update_current_account'
    static readonly TYPE_UPDATE_CURRENT_PROJECT = 'update_current_project'

    private static _handlingUnauthorizedError = false

    private constructor() { }

    /** Encapsula procedimento completo de 'logar' 01 usuario do sistama. */
    static login(user: AuthUserResponseDTO, token: string): void {

        // Se nao tiver accounts vinculadas nao loga
        if (SystemUtils.isEmpty(user?.accounts)) {
            NotificationHelper.error('Ops!', 'Você não tem acesso a nenhuma conta')
            return AuthActions.logout()
        }

        // Define usuario logado
        const userPayload: UserDataTP = { token, user }

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_USER,
            payload: userPayload,
        })

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_CURRENT_PROJECT,
            payload: user.accounts[0].projects[0],
        })

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_CURRENT_ACCOUNT,
            payload: user.accounts[0],
        })

        this.refreshLoginData(token)
    }

    /** Atualiza dados do cliente (schema). */
    static setDomain(schema: ISchemaResponseDTO, mktToken?: string): void {

        RequestHelperOLD.addDefaultHeader('schema', schema.slug)
        RequestHelper.addDefaultHeader('schema', schema.slug)

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_DOMAIN,
            payload: { schema, mktToken }
        })

    }

    /**
     * Define no REDUX o projeto e conta selecionadas como corrente.
     */
    static setCurrentProject(project: AuthUserProjectsResponseDTO, account: AuthUserAccountResponseDTO): void {

        RequestHelperOLD.addDefaultHeader('on-behalf-of', project.code.toString())
        RequestHelper.addDefaultHeader('on-behalf-of', project.code.toString())

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_CURRENT_PROJECT,
            payload: project
        })

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_CURRENT_ACCOUNT,
            payload: account
        })
    }

    /** Encapsula procedimento completo de 'deslogar'. */
    static logout(): void {

        RoutingHelper.setDefaultRoute('')

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_USER,
            payload: null,
        })

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_CURRENT_PROJECT,
            payload: null,
        })

        this.refreshLoginData(null)
        RoutingHelper.historyReplace(AuthRoutes.USER_LOGIN)
    }

    /** Action para tratamento erro 401 (nao autorizado) em requisicoes. */
    static onUnauthorizedRequestResponse(): void {

        if (AuthActions._handlingUnauthorizedError)
            return

        const currentState: IReduxState = ReduxHelper.getInstance().store.getState()

        if (!_.isEmpty(currentState.userData)) {
            AuthActions._handlingUnauthorizedError = true
            const durationSeconds = 6
            NotificationHelper.info('Sessão Expirada!', 'Sua sessão expirou. Faça login novamente para prosseguir', durationSeconds)
            setTimeout(() => AuthActions._handlingUnauthorizedError = false, (durationSeconds - 1) * 100)
        }

        AuthActions.logout()
    }

    /**
     * Atualiza propriedades estaticas que guardam o token de autenticacao do usuario atual sincronizando-as
     * com o conteudo mantido no estado global da aplicacao (redux).
     */
    static refreshLoginData(token: string | null): void {
        if (token && AppStateUtils.getCurrentProject()) {
            RequestHelperOLD.addDefaultHeader('Authorization', AuthUtils.getBearerAuthHeaderValue(token))
            RequestHelperOLD.addDefaultHeader('on-behalf-of', AppStateUtils.getCurrentProject()!.code.toString())
            RequestHelper.addDefaultHeader('Authorization', AuthUtils.getBearerAuthHeaderValue(token))
            RequestHelper.addDefaultHeader('on-behalf-of', AppStateUtils.getCurrentProject()!.code.toString())
        }
    }
}
