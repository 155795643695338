import React from 'react'
import { WizardCP } from 'common/components/wizard/WizardCP'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { ReferralProgramThanksStepICP } from 'modules/referral-program/components/wizard-referral-program-config/inner/steps/ReferralProgramThanksStepICP'
import { ReferralProgramMainDataFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramMainDataFormModel'
import { ReferralProgramThanksFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramThanksFormModel'
import { ReferralProgramMainDataStepICP } from 'modules/referral-program/components/wizard-referral-program-config/inner/steps/ReferralProgramMainDataStepICP'
import { ReferralProgramConfigViewTP } from 'modules/referral-program/components/referral-program-config/ReferralProgramConfigCP'
import { ReferralProgramDescriptionFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramDescriptionFormModel'
import { FormStateManagerUtils } from 'common/form-state-manager/FormStateManagerUtils'
import { ReferralProgramDescriptionStepICP } from 'modules/referral-program/components/wizard-referral-program-config/inner/steps/ReferralProgramDescriptionStepICP'
import { ReferralProgramGoalsStepICP } from 'modules/referral-program/components/wizard-referral-program-config/inner/steps/ReferralProgramGoalsStepICP'
import { ReferralProgramGoalFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramGoalFormModel'

interface IWizardNpsSetupCPProps {
    mainDataFormStateManager: IFormStateManager<ReferralProgramMainDataFormModel>
    thanksFormStateManager: IFormStateManager<ReferralProgramThanksFormModel>
    descriptionFormStateManager: IFormStateManager<ReferralProgramDescriptionFormModel>
    goalFormStateManager: IFormStateManager<ReferralProgramGoalFormModel>
    onConfirm: () => void
    onChangeStep: (previewView: ReferralProgramConfigViewTP) => void
}

/**
 * Passo a passo para configurar a programa.
 */
export function WizardReferralProgramConfigCP(props: IWizardNpsSetupCPProps): JSX.Element {

    /**
     * Ao mudar de etapa define a pre-visualizacao a ser exibida no lado direito
     */
    function onChangeStep(currentStep: number): void {

        // Define a visao de preview de acordo com a etapa
        switch (currentStep) {
            case 0:
                props.onChangeStep('mainForm')
                break

            case 1:
                props.onChangeStep('goalForm')
                break

            case 2:
                props.onChangeStep('descriptionForm')
                break

            case 3:
                props.onChangeStep('thanksForm')
                break

            default:
                break
        }

    }

    return (
        <WizardCP
            loading={false}
            action={props.onConfirm}
            onChangeStep={onChangeStep}
            steps={[
                {
                    key: 'main',
                    title: 'Dados',
                    content: <ReferralProgramMainDataStepICP formStateManager={props.mainDataFormStateManager}/>,
                    nextAction: async () => FormStateManagerUtils.validateRequiredFields(props.mainDataFormStateManager)
                },
                {
                    key: 'goals',
                    title: 'Metas',
                    content: <ReferralProgramGoalsStepICP formStateManager={props.goalFormStateManager}/>,
                    nextAction: async () => FormStateManagerUtils.validateRequiredFields(props.goalFormStateManager)
                },
                {
                    key: 'desc',
                    title: 'Descrição',
                    content: <ReferralProgramDescriptionStepICP formStateManager={props.descriptionFormStateManager}/>,
                    nextAction: async () => FormStateManagerUtils.validateRequiredFields(props.descriptionFormStateManager)
                },
                {
                    key: 'thanks',
                    title: 'Thanks',
                    content: <ReferralProgramThanksStepICP formStateManager={props.thanksFormStateManager}/>,
                },
            ]}
        />
    )
}
