import * as React from 'react'
import { isChrome } from 'react-device-detect'
import { BrowserWarningContentICP } from 'submodules/nerit-framework-ui/common/components/browser-warning/inner/BrowserWarningContentICP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import styled from 'styled-components'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'

interface IBrowserWarningCPProps extends BasicStyleWrapperCPProps {
    type: 'alert' | 'topBar'
}

/**
 * Exibe mensagem estatica para notificacao de forma destacada.
 */
export function BrowserWarningCP(props: IBrowserWarningCPProps): JSX.Element {

    const screenSize = useScreenSize()

    if (isChrome)
        return <></>

    return (
        <BasicStyleWrapperCP margin={props.margin}>
            {
                props.type === 'alert'
                    ?
                    <AlertCP
                        message={<BrowserWarningContentICP/>}
                        type={'warning'}
                    />
                    :
                    <TopBarSCP isSmd={screenSize.smd}>
                        <BrowserWarningContentICP/>
                    </TopBarSCP>
            }
        </BasicStyleWrapperCP>
    )
}

const TopBarSCP = styled.div<{ isSmd: boolean }>`
    height: ${props => props.isSmd ? '70px' : '50px'};
    display: flex;
    justify-content: center;
    align-items: center;
`
