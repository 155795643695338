import React from 'react'
import { IFormStateManager } from 'common/form-state-manager/IFormStateManager'
import { TitleCP } from 'common/components/title/TitleCP'
import { ReferralProgramDescriptionFormModel } from 'modules/referral-program/components/wizard-referral-program-config/inner/models/ReferralProgramDescriptionFormModel'
import { TinyMceCP } from 'common/components/wysiwyg/tiny-mce/TinyMceCP'

interface ICPProps {
    formStateManager: IFormStateManager<ReferralProgramDescriptionFormModel>
}

/**
 * COMPOENENT Etapa de configuracoes do agradecimento.
 */
export function ReferralProgramDescriptionStepICP(props: ICPProps): JSX.Element {

    return (
        <>
            <TitleCP underLine={true} textSize={'normal'}>
                Descrição do Programa
            </TitleCP>

            <TinyMceCP
                onEditorContentChange={(text) => props.formStateManager.changeFieldValue('description', text)}
                editorContent={props.formStateManager.getFieldValue('description')}
            />
        </>
    )
}
