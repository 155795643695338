import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { PlanEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'

export class CreateAccountAccessFormModel extends FormModel {

    @IsRequired()
    accountName: string

    @IsRequired()
    password: string

    @IsRequired()
    confirmPassword: string

    @IsRequired()
    plan: PlanEnum

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
