import {
    registerDecorator,
    ValidationArguments,
    ValidationOptions,
    Validator,
    ValidatorConstraint,
    ValidatorConstraintInterface
} from 'class-validator'
import { ValidationTypes } from '../ValidationTypes'

/**
 * VALIDATOR
 * Valida se é um number
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint({ name: ValidationTypes.IS_NUMBER })
class IsNumberConstraint implements ValidatorConstraintInterface {
    validate(value: string, args: ValidationArguments): boolean {
        const validator = new Validator()
        return validator.isNumber(value)
    }

    defaultMessage(args: ValidationArguments): string {
        return 'Valor deve ser do tipo numérico!'
    }
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsNumber(validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsNumberConstraint
        })
    }
}
