import React from 'react'
import { ProjectsRequests } from 'submodules/space4leads-sdk/services/accounts/ProjectsRequests'
import { UserProjectResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/UserProjectResponseDTO'
import { PermissionLabelEnum } from 'submodules/space4leads-sdk/services/people/person/enums/PermissionEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'

interface ICPProps {
    projectCode: number
    reloadData?: number
    onEditProjectUser: (projectUser: UserProjectResponseDTO) => void
    onDelete: () => void
}

/**
 * Tabela de usuarios de um projeto.
 */
export function TableProjectUsersICP(props: ICPProps): JSX.Element {

    return (
        <TableFromApiCP<UserProjectResponseDTO>
            shouldLoadData={props.reloadData}
            apiConfig={{
                requestConfigTP: () => ProjectsRequests.searchUsers(props.projectCode)
            }}
            columns={[
                {
                    title: 'Usuário',
                    render: (text, record) => record.user.name
                },
                {
                    title: 'Telefone',
                    render: (text, record) => MaskUtils.applyMask2(InputMaskTypeEnum.PHONE, record.user.phone)
                },
                {
                    title: 'E-mail',
                    render: (text, record) => record.user.email
                },
                {
                    title: 'Permissão',
                    render: (text, record) => record.permissions.map((permission) => PermissionLabelEnum[permission]).join(',')
                },
                {
                    render: (text, record) => (
                        <FlexCP justifyContent={'flex-end'}>
                            <ButtonCP
                                icon={'edit'}
                                onClick={() => props.onEditProjectUser(record)}
                                size={'small'}
                                marginRight={5}
                            />
                            <ButtonDeleteRecordCP
                                requestConfigTP={() => ProjectsRequests.removeUser(record.code)}
                                onDelete={props.onDelete}
                            />
                        </FlexCP>
                    )
                },
            ]}
        />
    )
}
