import { FormModel } from 'common/form-state-manager/FormModel'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'

export class SurveyQuestionFormModel extends FormModel {

    @IsNotEmpty()
    mainQuestionLabel: string

    @IsNotEmpty()
    goodAnswerQuestionLabel: string

    @IsNotEmpty()
    badAnswerQuestionLabel: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
