import React from 'react'
import styled from 'styled-components'
import { SurveyQuestionBoxCP } from 'submodules/space4leads-components-ui/survey/components/survey-question-box/SurveyQuestionBoxCP'
import { SurveyTypeEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/SurveyTypeEnum'
import { HelpCP } from 'common/components/help/HelpCP'
import { ISurveyListItemResponseDTO } from 'modules/survey/services/survey/dtos/response/ISurveyListItemResponseDTO'

interface ICsatItemPreviewICPProps {
    csatCampaign: ISurveyListItemResponseDTO
}

/**
 * Campos de cada item da listagem de campanhas de CSAT.
 */
export function CsatItemPreviewICP(props: ICsatItemPreviewICPProps): JSX.Element {

    return (
        <PreviewSCP>
            <SurveyQuestionBoxCP
                questionLabels={{
                    mainQuestionLabel: props.csatCampaign.survey.mainQuestionLabel,
                    goodAnswerQuestionLabel: props.csatCampaign.survey.goodAnswerQuestionLabel,
                    badAnswerQuestionLabel: props.csatCampaign.survey.badAnswerQuestionLabel,
                }}
                type={SurveyTypeEnum.CSAT}
                disabled={true}
                hideObs={true}
                selectedScore={Math.floor(props.csatCampaign.statistics.averageScore)}
                showScoreAfterAnswer={false}
            />
            <HelpCP
                text={'A nota marcada corresponde a média de nota recebida na pesquisa'}
                type={'tooltip'}
            />
        </PreviewSCP>
    )

}

const PreviewSCP = styled.div`
  padding: 20px;
  flex: 1;
  background: white;
  border-right: solid 1px #dedede;
`
