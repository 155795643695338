import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ColumnCP } from 'common/components/grid/ColumnCP'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { CardCP } from 'common/components/card/CardCP'
import { ProfileFormModel } from 'modules/user/components/logged-user-data-card/inner/ProfileFormModel'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'
import { PersonResponseDTO } from 'submodules/space4leads-sdk/services/people/person/dtos/response/PersonResponseDTO'
import { PersonSaveRequestDTO } from 'submodules/space4leads-sdk/services/people/person/dtos/request/PersonSaveRequestDTO'
import { PeopleRequests } from 'submodules/space4leads-sdk/services/people/person/PeopleRequests'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'

/**
 * Card com detalhes do usuario logado e drawer para edicao dos dados.
 */
export function LoggedUserDataCardCP(): JSX.Element {

    const [formValidator, setFormValidator] = useState<ProfileFormModel>(new ProfileFormModel({}))
    const formStateManager = useFormStateManager<ProfileFormModel>(formValidator)

    const savePersonRequest = useRequest<PersonResponseDTO>()
    useEffect(onSavePersonRequestChange, [savePersonRequest.isAwaiting])

    const getPersonRequest = useRequest<ICustomerPersonByReferralProgramResponseDTO>()
    useEffect(onGetPersonRequestChange, [getPersonRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa Tela.
     */
    function init(): void {
        getPersonRequest.runRequest(PersonRequests.findOne(AppStateUtils.getLoggedUserData()!.user.code))
    }

    /**
     * Retorno ao buscar pessoa.
     */
    function onGetPersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getPersonRequest, 'Erro ao buscar dados da pessoa logada'))
            return

        const resultPerson = getPersonRequest.responseData!
        setFormValidator(new ProfileFormModel({
            name: resultPerson.name,
            email: resultPerson.email,
            phone: resultPerson.phone,
        }))

    }

    /**
     * Salva edição dos dados da pessoa
     */
    async function savePerson(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const dto: PersonSaveRequestDTO = {
            name: formValues.name,
            email: formValues.email,
            phone: formValues.phone,
        }
        savePersonRequest.runRequest(PeopleRequests.update(AppStateUtils.getLoggedUserData()!.user.code, dto))
    }

    /**
     * Retorno ao salvar pessoa.
     */
    function onSavePersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(savePersonRequest, 'Erro ao salvar dados.'))
            return

        NotificationHelper.success('Pronto!', 'Seus dados foram atualizados com sucesso')
        init()
    }

    return (
        <ContentWrapperSCP>
            <CardCP
                title={'Meus dados'}
                isLoading={getPersonRequest.isAwaiting}
                width={'500px'}
            >

                <RowCP>
                    <ColumnCP size={24}>
                        <InputCP<ProfileFormModel>
                            fieldName={'name'}
                            formStateManager={formStateManager}
                            label={'Nome '}
                            required={true}
                        />
                    </ColumnCP>
                </RowCP>

                <RowCP>
                    <ColumnCP size={24}>
                        <InputCP<ProfileFormModel>
                            label={'E-mail'}
                            fieldName={'email'}
                            required={true}
                            formStateManager={formStateManager}
                        />
                    </ColumnCP>
                </RowCP>

                <RowCP>
                    <ColumnCP size={12}>
                        <InputCP<ProfileFormModel>
                            label={'Celular'}
                            fieldName={'phone'}
                            required={true}
                            formStateManager={formStateManager}
                            mask={InputMaskTypeEnum.PHONE}

                        />
                    </ColumnCP>
                </RowCP>

                <BtnContainerSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={savePerson}
                        loading={savePersonRequest.isAwaiting}
                        disabled={AppStateUtils.getLoggedUserData()!.user.code === 1}
                    >
                        Salvar
                    </ButtonCP>
                </BtnContainerSCP>
            </CardCP>
        </ContentWrapperSCP>

    )
}

const ContentWrapperSCP = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 22px;
`

const BtnContainerSCP = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 7px;
`
