import React from 'react'
import { FlexCP } from 'common/components/flex/FlexCP'
import { TextCP } from 'common/components/text/TextCP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'

interface ITitleAndTextCPProps {
    title: string
    text: string
    align?: 'center'
    justifyContent?: 'center' | 'flex-end' | 'space-between'
    invertBold?: boolean
    noBold?: boolean

    size?: FontSizeTP
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

/**
 * COMPONTENTE Exibe um text em negrito como titulo e texto normal ao lado.
 */
export function TitleAndTextCP(props: ITitleAndTextCPProps): JSX.Element {

    return (
        <FlexCP
            alignItems={props.align}
            justifyContent={props.justifyContent}
            marginTop={props.marginTop}
            marginRight={props.marginRight}
            marginBottom={props.marginBottom}
            marginLeft={props.marginLeft}
        >
            <TextCP text={props.title} strong={!props.invertBold && (props.noBold === undefined || !props.noBold)} marginRight={5} size={props.size}/>
            <TextCP text={props.text} size={props.size} strong={props.invertBold}/>
        </FlexCP>
    )
}
