import { TextCP } from 'common/components/text/TextCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ProgressCP } from 'common/components/progress/ProgressCP'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { MathUtils } from 'common/utils/MathUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { CardDashboardWidgetWrapperCP } from 'modules/dashboard/components/card-dashboard-widget-wrapper/CardDashboardWidgetWrapperCP'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { IWidgetResponseDTO } from 'modules/dashboard/services/dtos/response/IWidgetResponseDTO'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { CardDashboardWidgetRateFooterICP, } from 'modules/dashboard/components/card-dashboard-widget/inner/CardDashboardWidgetRateFooterICP'
import { OrUndefTP } from 'common/types/OrUndefTP'

interface IScpProps {
    marginTop?: number
    marginRight?: number
    marginBottom?: number
    marginLeft?: number
}

interface ICPProps extends IScpProps {
    title?: string
    requestConfigGetter?: RequestConfigTP // @TODO tornar obrigatorio
    valueMask?: InputMaskTypeEnum.MONEY | string
    isZeroTarget?: boolean
    loading?: boolean
    help?: string
    footerHelp?: string
    footerTitle?: string
    isSecondTargetMainValue?: boolean
    widgetResponseDto?: IWidgetResponseDTO
    targetLabel?: string
}

export function CardDashboardWidgetCP(props: ICPProps): JSX.Element {

    const [widgetData, setWidgetData] = useState<OrUndefTP<IWidgetResponseDTO>>(props.widgetResponseDto)
    useEffect(() => setWidgetData(props.widgetResponseDto), [props.widgetResponseDto])

    const widgetRequest = useRequest<IWidgetResponseDTO>()
    useEffect(onWidgetRequestChange, [widgetRequest.isAwaiting])

    useEffect(init, [props.requestConfigGetter])

    /**
     * Busca valor do widget na API.
     */
    function init(): void {

        if (!props.requestConfigGetter)
            return

        widgetRequest.runRequest(props.requestConfigGetter)
    }

    /**
     * Retorno da requisicao para pegar valores do widget.
     */
    function onWidgetRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(widgetRequest, 'Erro ao obter valores do widget'))
            return

        setWidgetData(widgetRequest.responseData)
    }

    return (
        <CardDashboardWidgetWrapperCP
            className={'card-dashboard-widget'}
            title={props.title ?? widgetData?.label}
            marginTop={props.marginTop}
            footer={
                widgetData?.secondValue !== undefined && !!props.footerTitle
                    ?
                    <CardDashboardWidgetRateFooterICP
                        title={props.footerTitle}
                        help={props.footerHelp}
                        value={props.isSecondTargetMainValue ? widgetData.value : widgetData.secondValue}
                        target={props.isSecondTargetMainValue ? widgetData.secondValue : widgetData.secondTarget}
                        type={props.isSecondTargetMainValue ? 'ratio' : 'target'}
                    />
                    :
                    undefined
            }
            help={props.help}
            loading={props.loading ?? widgetRequest.isAwaiting}
        >
            <>
                {
                    !!widgetData && widgetData.value !== undefined &&
                    <>
                        <ValueSCP color={props.isZeroTarget && widgetData.value > 0 ? ThemeAnt.errorColor : undefined}>
                            {
                                props.valueMask === InputMaskTypeEnum.MONEY
                                    ? MaskUtils.applyMoneyMask(widgetData.value)
                                    : `${MaskUtils.applyNumberMask(widgetData.value, 2)}${props.valueMask ?? ''}`
                            }
                        </ValueSCP>
                        <>
                            {
                                !!widgetData?.target &&
                                <>
                                    <ProgressCP
                                        percent={MathUtils.getPercentage(widgetData.value, widgetData.target, true)}
                                        status={MathUtils.getPercentage(widgetData.value, widgetData.target, true) < 100 ? 'active' : undefined}
                                        gradientColor={true}
                                        size={'big'}
                                    />
                                    <TextCP
                                        text={`${props.targetLabel ?? 'Meta'}: ${props.valueMask === InputMaskTypeEnum.MONEY ? MaskUtils.applyMoneyMask(widgetData.value) : MaskUtils.applyNumberMask(widgetData.target, 2)}`}
                                        color={ThemeAnt.gray}
                                        marginTop={5}
                                    />
                                </>
                            }
                        </>
                    </>
                }
            </>
        </CardDashboardWidgetWrapperCP>
    )
}

const ValueSCP = styled.div<{ color?: string }>`
  font-size: 30px;
  color: ${props => props.color};
`
