import React, { useState } from 'react'
import { CardLoginCP } from 'modules/auth/components/card-login/CardLoginCP'
import { IReferralProgramResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramResponseDTO'
import { ProgramSubscribeResultCP } from 'modules/referral-program/components/program-subscribe/inner/ProgramSubscribeResultCP'
import { ProgramSubscribeWizardICP } from 'modules/referral-program/components/program-subscribe/inner/ProgramSubscribeWizardICP'
import { ICustomerPersonByReferralProgramResponseDTO } from 'modules/person/services/dtos/response/ICustomerPersonByReferralProgramResponseDTO'

interface ICPProps {
    referralProgram: IReferralProgramResponseDTO
    referredByToken?: string
}

/**
 * Card com com form de preenchimento de dados.
 */
export function ProgramSubscribeCP(props: ICPProps): JSX.Element {

    const [personRegistered, setPersonRegistered] = useState<ICustomerPersonByReferralProgramResponseDTO>()

    return (
        <CardLoginCP showCover={false}>
            <div className='form-login'>
                {
                    !!personRegistered
                        ?
                        <ProgramSubscribeResultCP
                            referralProgram={props.referralProgram}
                            personRegistered={personRegistered}
                        />
                        :
                        <ProgramSubscribeWizardICP
                            onSubscribe={setPersonRegistered}
                            referralProgram={props.referralProgram}
                            referredByToken={props.referredByToken}
                        />
                }
            </div>
        </CardLoginCP>
    )
}
