import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { SurveyQuestionFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyQuestionFormModel'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { SurveyThanksFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyThanksFormModel'
import { ChannelEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/ChannelEnum'
import { SurveyTypeEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/SurveyTypeEnum'
import { SiderCP } from 'common/components/screen-layout/sider/SiderCP'
import { WizardSurveyConfigCP } from 'modules/survey/components/wizard-survey-config/WizardSurveyConfigCP'
import styled from 'styled-components'
import { SurveyConfigPreviewICP } from 'modules/survey/components/survey-config/inner/SurveyConfigPreviewICP'
import { NpsScreenPreviewViewTP } from 'modules/survey/components/survey-config/inner/NpsScreenPreviewViewTP'
import { SurveyUtils } from 'modules/survey/utils/SurveyUtils'
import { SurveyWizardUtils } from 'modules/survey/components/wizard-survey-config/inner/SurveyWizardUtils'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { RoutingHelper } from 'config/RoutingHelper'
import { CsatRoutes } from 'modules/survey/routes/CsatRoutes'
import { NpsRoutes } from 'modules/survey/routes/NpsRoutes'
import { SurveySaveRequestDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/request/SurveySaveRequestDTO'
import { SurveyChannelsFormModel } from 'modules/survey/components/wizard-survey-config/inner/models/SurveyChannelsFormModel'
import { SurveysRequests } from 'submodules/space4leads-sdk/services/surveys/surveys/SurveysRequests'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface ISurveyConfigCPProps {
    type: SurveyTypeEnum
    survey?: SurveyResponseDTO
}

/**
 * TELA do modulo financeiro
 */
export function SurveyConfigCP(props: ISurveyConfigCPProps): JSX.Element {

    const [currentPreviewView, setCurrentPreviewView] = useState<NpsScreenPreviewViewTP>('surveyForm')

    const [questionFormModel, setQuestionFormModel] = useState<SurveyQuestionFormModel>((new SurveyQuestionFormModel()))
    const questionFormStateManager = useFormStateManager<SurveyQuestionFormModel>(questionFormModel)

    const [thanksFormModel, setThanksFormModel] = useState<SurveyThanksFormModel>((new SurveyThanksFormModel()))
    const thanksFormStateManager = useFormStateManager<SurveyThanksFormModel>(thanksFormModel)

    const [reloadChannelInfo, setReloadChannelInfo] = useState<number>(1)

    const [channelsFormModel, setChannelsFormModel] = useState<SurveyChannelsFormModel>((new SurveyChannelsFormModel()))
    const channelsFormStateManager = useFormStateManager<SurveyChannelsFormModel>(channelsFormModel)

    const saveSurveyRequest = useRequest<void>('none')
    useEffect(onSaveSurveyRequestChange, [saveSurveyRequest.isAwaiting])

    useEffect(init, [props.survey])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!!props.survey) {
            setQuestionFormModel(new SurveyQuestionFormModel({
                mainQuestionLabel: props.survey.mainQuestionLabel,
                goodAnswerQuestionLabel: props.survey.goodAnswerQuestionLabel,
                badAnswerQuestionLabel: props.survey.badAnswerQuestionLabel
            }))

            setThanksFormModel(new SurveyThanksFormModel({
                thanksMessage: props.survey.thanksMessage,
                thanksCtaUrl: props.survey.thanksCtaUrl,
                thanksCtaLabel: props.survey.thanksCtaLabel
            }))

            setChannelsFormModel(new SurveyChannelsFormModel({
                channels: props.survey.channels
            }))
            return
        }

        setQuestionFormModel(new SurveyQuestionFormModel({
            mainQuestionLabel: SurveyUtils.getDefaultMainQuestionLabel(props.type),
            goodAnswerQuestionLabel: SurveyUtils.getDefaultGoodQuestionLabel(props.type),
            badAnswerQuestionLabel: SurveyUtils.getDefaultBadQuestionLabel(props.type)
        }))

        setThanksFormModel(new SurveyThanksFormModel({
            thanksMessage: 'Obrigado pelo seu feedback!'
        }))

        setChannelsFormModel(new SurveyChannelsFormModel({
            channels: [
                {
                    channel: ChannelEnum.LINK
                },
                {
                    channel: ChannelEnum.QRCODE,
                    config: {
                        layoutConfig: {
                            title: 'Pesquisa de Satisfação',
                            subtitle: 'Nós queremos te ouvir',
                            footer: 'Aponte a câmera do seu celular para o QR CODE e deixe a sua avaliação',
                        }
                    }
                },
            ]
        }))
    }

    /**
     * Salva as configuracoes da pesquisa NPS
     */
    function save(): void {

        if (!SurveyWizardUtils.onFormSubmit(questionFormStateManager) || !SurveyWizardUtils.onFormSubmit(thanksFormStateManager) || !SurveyWizardUtils.onFormSubmit(channelsFormStateManager))
            return

        const questionFormValues = questionFormStateManager.getFormValues()!
        const thanksFormValues = thanksFormStateManager.getFormValues()!
        const channelsFormValues = channelsFormStateManager.getFormValues()!

        const dto: SurveySaveRequestDTO = {
            type: props.type,
            channels: channelsFormValues.channels,
            mainQuestionLabel: questionFormValues.mainQuestionLabel,
            goodAnswerQuestionLabel: questionFormValues.goodAnswerQuestionLabel,
            badAnswerQuestionLabel: questionFormValues.badAnswerQuestionLabel,
            thanksMessage: thanksFormValues.thanksMessage,
            thanksCtaUrl: thanksFormValues.thanksCtaUrl,
            thanksCtaLabel: thanksFormValues.thanksCtaLabel
        }

        if (!!props.survey)
            saveSurveyRequest.runRequest(SurveysRequests.update(props.survey.code, dto))
        else
            saveSurveyRequest.runRequest(SurveysRequests.create(dto))

    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveSurveyRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveSurveyRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        RoutingHelper.historyPush(props.type === SurveyTypeEnum.CSAT ? CsatRoutes.ROOT : NpsRoutes.ROOT)
    }

    return (
        <>
            <LoadingOverlayCP show={saveSurveyRequest.isAwaiting}/>
            <LayoutCP
                sider={
                    <SiderCP width={450} scrollable={true}>
                        <SiderWrapperSCP>
                            <WizardSurveyConfigCP
                                survey={props.survey}
                                questionsFormStateManager={questionFormStateManager}
                                thanksFormStateManager={thanksFormStateManager}
                                channelsFormStateManager={channelsFormStateManager}
                                onConfirm={save}
                                onChangeStep={setCurrentPreviewView}
                                type={props.type}
                                onEditingChannel={() => setReloadChannelInfo(TableUtils.getReloadNumber())}
                            />

                        </SiderWrapperSCP>
                    </SiderCP>
                }
                content={
                    <SurveyConfigPreviewICP
                        questionConfigFormStateManager={questionFormStateManager}
                        thanksConfigFormStateManager={thanksFormStateManager}
                        previewView={currentPreviewView}
                        type={props.type}
                        survey={props.survey}
                        channelsConfigFormStateManager={channelsFormStateManager}
                        reloadChannelInfo={reloadChannelInfo}
                    />
                }
            />
        </>
    )
}

const SiderWrapperSCP = styled.div`
    padding: 20px;
`
