import React from 'react'
import styled from 'styled-components'
import { HelmetCP } from 'common/components/helmet/HelmetCP'

interface IPageHeaderCPProps {
    title: string
    children?: JSX.Element
}

/**
 * COMPONENTE Titulo de uma pagina. Adiciona o menuTitle do navegador tambem
 */
export function PageHeaderCP(props: IPageHeaderCPProps): JSX.Element {

    return (
        <HeaderContainerSCP>
            <HelmetCP
                title={props.title}
            />
            <TitleContainerSCP>
                <TitleSCP>
                    {props.title}
                </TitleSCP>
            </TitleContainerSCP>
            <ChildrenWrapperSCP>
                {props.children}
            </ChildrenWrapperSCP>
        </HeaderContainerSCP>
    )
}

const HeaderContainerSCP = styled.div`
    height: 65px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
`

const TitleContainerSCP = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    
    &::before{
        content: '';
        position: absolute;
        background: ${props => props.theme.secondaryColor};
        border-radius: 8px 8px 0 0;
        width: 50px;
        height: 4px;
        bottom: 0;
        left: 0;
    }
`

const TitleSCP = styled.h3`
  font-weight: 600;
  font-size: 1.3rem;
  color: ${props => props.theme.primaryColor};
`

const ChildrenWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    
    .ant-select{
        margin-left: 20px;
    }
`
