import { Menu } from 'antd'
import { ObjectPropsTP } from 'common/types/ObjectPropsTP'
import { ObjectUtils } from 'common/utils/ObjectUtils'
import styled from 'styled-components'
import React from 'react'

export interface ITitleEventEntity {
    key: string
    domEvent: Event
}

interface IScpProps {
    hideArrow?: boolean
    padding?: string
}

type _AntPropsTP = {
    title?: string | JSX.Element,
    onTitleClick?: (e: ITitleEventEntity) => void,
    openKeys?: string[],
}

interface ISubMenuICPProps extends React.PropsWithChildren<_AntPropsTP>, IScpProps {}

/**
 * COMPONENTE: Sub Menu.
 */
export function SubMenuCP(props: ISubMenuICPProps): JSX.Element {

    function onTitleClick(e: ITitleEventEntity): void {
        if (!!props.onTitleClick)
            props.onTitleClick(e)
    }

    function getAntProps(): ObjectPropsTP<ISubMenuICPProps> {
        const customProps: Array<keyof ISubMenuICPProps> = ['children']
        return ObjectUtils.getObjectsWithoutSomeProps(props, customProps)
    }

    return (
        <WrapperSCP
            hideArrow={props.hideArrow}
            padding={props.padding}
        >
            <Menu.SubMenu
                {...getAntProps()}  // eslint-disable-line react/jsx-props-no-spreading
                onTitleClick={onTitleClick}
            >
                {props.children}
            </Menu.SubMenu>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<IScpProps>`
 
    .ant-menu-submenu > .ant-menu {
        background-color: transparent;
    }

    .ant-menu-submenu-title {
        font-weight: bold !important;
        color:  ${props => props.theme.primaryColor};
        padding: ${props => props.padding ?? undefined} !important;
    }

    .ant-menu-item-selected {
      background: #F1F1F1 !important;
    }
  
    .ant-menu-submenu-arrow {
      display: ${props => !!props.hideArrow ? 'none' : undefined};
    }
`
