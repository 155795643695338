import { ReducersMapObject } from 'redux'
import { ReduxUtils } from 'common/redux/ReduxUtils'
import { DomainDataTP } from 'common/redux/types/DomainDataTP'
import { AuthActions } from 'modules/auth/AuthActions'
import { UserDataTP } from 'common/redux/types/UserDataTP'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { AuthUserProjectsResponseDTO } from 'submodules/space4leads-sdk/services/auth/dtos/responses/inner/AuthUserProjectsResponseDTO'
import { AuthUserAccountResponseDTO } from 'submodules/space4leads-sdk/services/auth/dtos/responses/inner/AuthUserAccountResponseDTO'

/**
 * REDUX
 * Determina mapa de reducers para gestao de estado da aplicacao via redux.
 */
export const Reducers: ReducersMapObject<IReduxState> = {
    userData: ReduxUtils.getPayloadReplacingReducer<UserDataTP>(AuthActions.TYPE_UPDATE_USER),
    domainData: ReduxUtils.getPayloadReplacingReducer<DomainDataTP>(AuthActions.TYPE_UPDATE_DOMAIN),
    currentAccount: ReduxUtils.getPayloadReplacingReducer<AuthUserAccountResponseDTO>(AuthActions.TYPE_UPDATE_CURRENT_ACCOUNT),
    currentProject: ReduxUtils.getPayloadReplacingReducer<AuthUserProjectsResponseDTO>(AuthActions.TYPE_UPDATE_CURRENT_PROJECT),
}
