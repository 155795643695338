import { ProgressCP } from 'common/components/progress/ProgressCP'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { MaskUtils } from 'common/utils/MaskUtils'
import React from 'react'
import styled from 'styled-components'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'

interface ICsatItemStatisticColumnICPProps {
    icon: JSX.Element
    percent?: number
    total: number
    label: string
}

/**
 *
 *
 * @author renatofs
 */
export function CsatItemStatisticColumnICP(props: ICsatItemStatisticColumnICPProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <ColumnSCP isSmallDevice={screenSize.smd}>

            <IconSCP>
                {props.icon}
            </IconSCP>

            <div>
                <ConditionalRenderCP shouldRender={!props.percent}>
                    <PercentageSCP>
                        <h1>
                            {props.total}
                        </h1>
                    </PercentageSCP>
                </ConditionalRenderCP>

                <ConditionalRenderCP shouldRender={!!props.percent}>
                    <PercentageSCP>
                        <h1>
                            {Math.ceil(props.percent!)}
                            %
                        </h1>
                        <span>{MaskUtils.applyNumberMask(props.total, 0)}</span>
                    </PercentageSCP>
                    <ProgressCP
                        percent={props.percent!}
                        showInfo={false}
                    />
                </ConditionalRenderCP>
            </div>

            <span>{props.label}</span>
        </ColumnSCP>
    )

}

const ColumnSCP = styled.div<{ isSmallDevice: boolean }>`

  width: ${props => (props.isSmallDevice ? '100%' : '140px')};
  flex-direction: column;
  padding: 10px;
  align-items: center;
  border-left: 1px solid #dedede;
  display: flex;
  background: #f5f5f5;
  justify-content: space-between;
`

const IconSCP = styled.div`

  border: 1px solid #a9a9a9;
  border-radius: 100px;
  width: 42px;
  height: 42px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PercentageSCP = styled.div`

  text-align: center;

  span {
    display: block;
    font-weight: bold;
    padding: 0.5rem;
  }

  h1 {
    font-weight: 800;
    border-bottom: 1px solid rgba(222, 222, 222, 0.8);
    padding-bottom: 10px;
    font-size: 2.2rem;
    margin-bottom: 0px;
  }
`
