import React, { useState } from 'react'
import styled from 'styled-components'
import { TinyMceCP } from 'common/components/wysiwyg/tiny-mce/TinyMceCP'
import { ReferralProgramCollpaseItemContentICP } from 'modules/referral-program/components/collapse-referral-program-messages/inner/ReferralProgramCollpaseItemContentICP'
import { INotificationByRecipientMessage } from 'modules/referral-program/components/drawer-referral-program-notifications/inner/INotificationByRecipientMessage'
import {
    NotificationChannelEnum,
    NotificationChannelLabelEnum
} from 'modules/marketing/services/notification/enums/NotificationChannelEnum'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'
import { SmsTextAreaCP } from 'common/components/sms-textarea/SmsTextAreaCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { NOTIFICATION_CONTENT_VARS } from 'modules/marketing/types/NotificationContentVarsConfig'
import { DrawerReferralProgramNotificationVarsCP } from 'modules/referral-program/components/drawer-referral-program-notification-vars/DrawerReferralProgramNotificationVarsCP'

interface ICPProps {
    notificationMessages: INotificationByRecipientMessage[]
}

/**
 * Conteudo de cada painel do collapse.
 */
export function ReferralProgramCollpaseItemICP(props: ICPProps): JSX.Element {

    const [isVarsDrawerVisible, setIsVarsDrawerVisible] = useState<boolean>(false)

    return (
        <>
            {
                props.notificationMessages.map((notification, index) => (
                    <ReferralProgramCollpaseItemContentICP
                        key={`ReferralProgramCollpaseItemContentICP-${index}`}
                        title={NotificationChannelLabelEnum[notification.channel]}
                        notificationMessage={notification}
                        content={
                            <>
                                <ConditionalRenderCP shouldRender={notification.channel === NotificationChannelEnum.SMS}>
                                    <SmsTextAreaCP
                                        label={'Mensagem enviada por SMS'}
                                        onChange={(text) => notification.message = text}
                                        considerCharLimit={true}
                                        value={notification.message}
                                        mentionOptions={NOTIFICATION_CONTENT_VARS}
                                    />
                                </ConditionalRenderCP>
                                <ConditionalRenderCP shouldRender={notification.channel === NotificationChannelEnum.WHATSAPP}>
                                    <SmsTextAreaCP
                                        label={'Mensagem enviada por Whatsapp'}
                                        onChange={(text) => notification.message = text}
                                        considerCharLimit={false}
                                        value={notification.message}
                                        mentionOptions={NOTIFICATION_CONTENT_VARS}
                                    />
                                </ConditionalRenderCP>
                                <ConditionalRenderCP shouldRender={notification.channel === NotificationChannelEnum.EMAIL}>
                                    <InputCP
                                        label={'Assunto:'}
                                        value={notification.subject}
                                        onChange={(val) => notification.subject = val}
                                    />
                                    <EditorWrapper>
                                        <TinyMceCP
                                            onEditorContentChange={(content) => notification.message = content}
                                            initialEditorContent={notification.message}
                                            onSetup={function(editor) {

                                                editor.ui.registry.addButton('showVars', {
                                                    text: 'VAR',
                                                    onAction() {
                                                        setIsVarsDrawerVisible(true)
                                                    }
                                                })
                                            }}
                                        />
                                    </EditorWrapper>
                                </ConditionalRenderCP>
                            </>
                        }
                    />
                ))
            }

            <DrawerReferralProgramNotificationVarsCP
                onClose={() => setIsVarsDrawerVisible(false)}
                visible={isVarsDrawerVisible}
            />
        </>
    )

}

const EditorWrapper = styled.div`
  .editor-btn {
    right: 16px;
    bottom: 79px;
  }
`
