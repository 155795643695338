import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { RadioGroupCP } from 'common/components/form-fields/radio-group/RadioGroupCP'
import { FlexCP } from 'common/components/flex/FlexCP'
import { CollapseReferralProgramMessagesCP } from 'modules/referral-program/components/collapse-referral-program-messages/CollapseReferralProgramMessagesCP'
import { TimelineCP } from 'submodules/nerit-framework-ui/common/components/timeline/TimelineCP'
import { IconICP } from 'common/components/icons/inner/IconICP'
import styled from 'styled-components'
import { NotificationRecipientEnum } from 'modules/marketing/services/notification/enums/NotificationRecipientEnum'
import { INotificationByRecipient } from 'modules/referral-program/components/drawer-referral-program-notifications/inner/INotificationByRecipient'
import { DEV_NOTIFICATIONS } from 'common/screens/screen-dev-test/inner/DevMocks'
import { INotificationResponseDTO } from 'modules/marketing/services/notification/dtos/responses/INotificationResponseDTO'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { NotificationRequests } from 'modules/marketing/services/notification/NotificationRequests'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { ReferralProgramRequests } from 'modules/referral-program/services/referral-program/ReferralProgramRequests'
import { ReferralProgramNotificationsDrawerUtils } from 'modules/referral-program/components/drawer-referral-program-notifications/inner/ReferralProgramNotificationsDrawerUtils'
import { INotificationBulkSaveRequestDTO } from 'modules/marketing/services/notification/dtos/resquests/INotificationBulkSaveRequestDTO'

interface IDrawerBankAccountCPProps {
    visible: boolean
    onClose: (dataChanged: boolean) => void
    referralProgramCode: number
}

/**
 * Drawer de mensagens de um programa de indicacao.
 */
export function DrawerReferralProgramNotificationsCP(props: IDrawerBankAccountCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [selectedRecipient, setSelectedRecipient] = useState<NotificationRecipientEnum>(NotificationRecipientEnum.WHO_INDICATES)
    const [msgConfigByRecepient, setMsgConfigByRecepient] = useState<Map<NotificationRecipientEnum, INotificationByRecipient[]>>()

    const getReferralProgramMessagesRequest = useRequest<IGenericListResponseDTO<INotificationResponseDTO>>()
    useEffect(onGetReferralProgramMessagesRequestChange, [getReferralProgramMessagesRequest.isAwaiting])

    const saveReferralProgramMessagesRequest = useRequest<void>('none')
    useEffect(onSaveReferralProgramMessagesRequestChange, [saveReferralProgramMessagesRequest.isAwaiting])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        if (!props.visible)
            return

        getReferralProgramMessagesRequest.runRequest(ReferralProgramRequests.getNotifications(props.referralProgramCode))

        // @TODO REMOVER
        setMsgConfigByRecepient(ReferralProgramNotificationsDrawerUtils.fromDtoToMap(DEV_NOTIFICATIONS))
    }

    /**
     * Retorno da requisicao para pegar as mensagens
     */
    function onGetReferralProgramMessagesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getReferralProgramMessagesRequest, 'Erro obter mensagens do programa'))
            return

        setMsgConfigByRecepient(ReferralProgramNotificationsDrawerUtils.fromDtoToMap(getReferralProgramMessagesRequest.responseData!.list))
    }

    /**
     * Salva configuracao das notificacoes
     */
    async function saveNotifications(): Promise<void> {

        if (!msgConfigByRecepient)
            return

        const dto: INotificationBulkSaveRequestDTO = {
            referralProgramCode: props.referralProgramCode,
            notifications: ReferralProgramNotificationsDrawerUtils.fromMapToDto(msgConfigByRecepient)
        }
        saveReferralProgramMessagesRequest.runRequest(NotificationRequests.bulkSave(dto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveReferralProgramMessagesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveReferralProgramMessagesRequest, 'Ocorreu algun erro ao salvar mensagens'))
            return

        NotificationHelper.info('Yes', 'Notificações salvas com sucesso')
        props.onClose(true)
    }

    return (
        <DrawerCP
            title={'Mensagens Automatizadas do Programa'}
            width={800}
            visible={props.visible}
            loading={getReferralProgramMessagesRequest.isAwaiting}
            onClose={() => props.onClose(false)}
            footer={
                <ButtonCP
                    type={'primary'}
                    onClick={saveNotifications}
                    loading={saveReferralProgramMessagesRequest.isAwaiting}
                >
                    Salvar
                </ButtonCP>
            }

        >

            <FlexCP justifyContent={'center'}>
                <RadioGroupCP
                    buttonHeight={32}
                    buttonWidth={200}
                    paddingTop={0}
                    onChange={setSelectedRecipient}
                    selected={selectedRecipient}
                    options={[
                        {
                            value: NotificationRecipientEnum.WHO_INDICATES,
                            content: 'Para quem Indicou'
                        },
                        {
                            value: NotificationRecipientEnum.INDICATED,
                            content: 'Para o Indicado'
                        }
                    ]}
                />
            </FlexCP>

            <WrapperSCP>
                <TimelineCP
                    loading={getReferralProgramMessagesRequest.isAwaiting}
                    items={
                        msgConfigByRecepient?.get(selectedRecipient)?.map((notification) => ({
                            icon:
                                <IconICP
                                    iconName={'clock-circle'}
                                />,
                            content:
                                <WrapperTimelineItemSCP>
                                    <CollapseReferralProgramMessagesCP
                                        notification={notification}
                                    />
                                </WrapperTimelineItemSCP>
                        })) ?? []
                    }
                />
            </WrapperSCP>

        </DrawerCP>
    )
}

const WrapperSCP = styled.div`
  padding: 20px;

  .ant-timeline-item-head {
    background: none;
    padding-right: 10px;
    margin-top: 10px;
  }

  .ant-timeline-item-tail {
    top: 31px;
    left: 0;
  }
`

const WrapperTimelineItemSCP = styled.div`
  width: 100%;

  .ant-collapse-item {
    background: #fff;
    margin-top: 20px;
    border-radius: 4px !important;
  }
`
