import { Popover } from 'antd'
import styled from 'styled-components'
import React from 'react'
import { AvatarCP } from 'common/components/avatar/AvatarCP'

interface IProfileMenuItemICPProps {
    profileImage?: string
    profileOptions: Array<{ text: string, action: () => void }>
    optionTitle: string
}

/**
 * Foto e menu de profile no final do menu.
 */
export function ProfileMenuItemICP(props: IProfileMenuItemICPProps): JSX.Element {

    return (
        <Popover
            trigger={'click'}
            placement={'rightTop'}
            title={<TitleSCP>{props.optionTitle}</TitleSCP>}
            content={
                <OptionsWrapperSCP>
                    {props.profileOptions.map(opt =>
                        <OptionSCP onClick={opt.action} key={opt.text}>{opt.text}</OptionSCP>)}
                </OptionsWrapperSCP>
            }
        >

            <ProfileAvatarSCP>
                <AvatarCP
                    size={'large'}
                    src={props.profileImage}
                    name={props.optionTitle}
                />
            </ProfileAvatarSCP>

        </Popover>
    )
}

const TitleSCP = styled.div`
    font-weight: 700;
    padding: 0.6rem 0;
    color: ${props => props.theme.primaryColor};
`

const OptionSCP = styled.p`
    cursor: pointer;
    font-weight: 700;
`

const OptionsWrapperSCP = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`

const ProfileAvatarSCP = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
`
