import React, { useState } from 'react'
import styled from 'styled-components'
import { WidgetsNpsScore } from 'submodules/space4leads-components-ui/report/components/widgets-nps-score/WidgetsNpsScore'
import { WidgetNpsPerPeriod } from 'submodules/space4leads-components-ui/report/components/widget-nps-per-period/WidgetNpsPerPeriod'
import { TimelineSurveyAnswersCP } from 'submodules/space4leads-components-ui/report/components/timeline-survey-answers/TimelineSurveyAnswersCP'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { Select } from 'antd'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'

interface ICPProps {
    surveyCode: number
}

/**
 * TELA de listagem de Disparos
 */
export function ScreenContentNpsHome(props: ICPProps): JSX.Element {

    const [reload, setReload] = useState<number>(1)
    const [tagsFilter, setTagsFilter] = useState<string[]>([])
    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>()

    return (
        <ContentSCP>

            <FlexCP justifyContent={'center'} margin={{ bottom: 20 }}>

                <DateWrapperSCP>
                    <DateRangePickerCP
                        value={dateFilters}
                        onChange={setDateFilters}
                        fastFilter={'all'}
                        allowClear={false}
                    />
                </DateWrapperSCP>

                <Select
                    mode={'tags'}
                    value={tagsFilter}
                    onChange={setTagsFilter}
                    style={{ width: 500 }}
                    placeholder={'Filtre o NPS por tags'}
                />

                <ButtonCP
                    marginLeft={10}
                    icon={'search'}
                    onClick={() => setReload(TableUtils.getReloadNumber())}
                >
                    Filtrar
                </ButtonCP>
            </FlexCP>

            <WidgetsNpsScore
                type={'project'}
                filters={{
                    tags: tagsFilter,
                    dateRange: dateFilters,
                }}
                reload={reload}
            />
            <WidgetNpsPerPeriod
                tags={tagsFilter}
                dateRange={dateFilters}
                reload={reload}
            />

            <CardCP title={'Últimas respostas'}>
                <TimelineSurveyAnswersCP
                    surveyCode={props.surveyCode}
                    dateRange={dateFilters}
                    reload={reload}
                    tags={tagsFilter}
                />
            </CardCP>
        </ContentSCP>
    )

}

const ContentSCP = styled.div`
  padding: 20px;
  margin-bottom: 50px;
  max-width: 100%;
`

const DateWrapperSCP = styled.div`
  margin-right: 5px;
  
  .ant-calendar-picker {
    background-color: #fff;
    border: solid 1px #000;
    border-radius: 5px;
  }
`
