import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ListCP } from 'common/components/list/ListCP'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { ListItemReferralProgramsCP } from 'modules/referral-program/components/list-referral-programs/inner/list-item-referral-programs/ListItemReferralProgramsCP'
import { IReferralProgramListItemResponseDTO } from 'modules/referral-program/services/referral-program/dtos/responses/IReferralProgramListItemResponseDTO'
import { ReferralProgramRequests } from 'modules/referral-program/services/referral-program/ReferralProgramRequests'
import { DrawerReferralProgramNotificationsCP } from 'modules/referral-program/components/drawer-referral-program-notifications/DrawerReferralProgramNotificationsCP'

/**
 * COMPONENTE Listagem dos pogramas de indicacao.
 */
export function ListReferralProgramsCP(): JSX.Element {

    const [isNotificationDrawerVisible, setIsNotificationDrawerVisible] = useState<boolean>(false)
    const [selectedReferralProgramCode, setSelectedReferralProgramCode] = useState<number>()

    const [referralPrograms, setReferralPrograms] = useState<IReferralProgramListItemResponseDTO[]>()

    const referralProgramsRequest = useRequest<IGenericListResponseDTO<IReferralProgramListItemResponseDTO>>()
    useEffect(onSearchReferralProgramsRequestChange, [referralProgramsRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {
        referralProgramsRequest.runRequest(ReferralProgramRequests.search())
    }

    /**
     * Retorno da API
     */
    function onSearchReferralProgramsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(referralProgramsRequest, 'Não foi possível obter os programas de indicação'))
            return

        setReferralPrograms(referralProgramsRequest.responseData?.list ?? [])
    }

    return (
        <ContentSCP>
            <ListCP
                bordered={false}
                loading={referralProgramsRequest.isAwaiting}
                dataSource={referralPrograms}
                renderItem={(referralProgram) => (
                    <ListItemICP
                        content={
                            <ListItemReferralProgramsCP
                                showActions={true}
                                onUpdateList={init}
                                referralProgram={referralProgram}
                                onSelectReferralProgram={() => {
                                    setSelectedReferralProgramCode(referralProgram.code)
                                    setIsNotificationDrawerVisible(true)
                                }}
                            />
                        }
                    />
                )}
            />

            {
                !!selectedReferralProgramCode &&
                <DrawerReferralProgramNotificationsCP
                    visible={isNotificationDrawerVisible}
                    onClose={() => {
                        setIsNotificationDrawerVisible(false)
                        setSelectedReferralProgramCode(undefined)
                    }}
                    referralProgramCode={selectedReferralProgramCode}
                />
            }
        </ContentSCP>
    )

}

const ContentSCP = styled.div`
    .ant-list-item {
        padding: 0;
        border-radius: 10px;
        margin-bottom: 20px;
        justify-content: center;
        border: none;
        .ant-list-item-meta{
            flex: inherit;
        }
    }
`
