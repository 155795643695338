import { registerDecorator, ValidationArguments, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { ValidationTypes } from 'common/validation/ValidationTypes'

/**
 * VALIDATOR
 * Valida se é um number
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint({ name: ValidationTypes.IS_NOT_EMPTY })
class IsNotEmptyConstraint implements ValidatorConstraintInterface {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validate(value: string, args: ValidationArguments): boolean {
        const validator = new Validator()
        return validator.isNotEmpty(value)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    defaultMessage(args: ValidationArguments): string {
        return 'Campo Obrigatório'
    }
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsNotEmpty(validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsNotEmptyConstraint,
        })
    }
}
