import React from 'react'
import ButtonGroup from 'antd/es/button/button-group'
import styled from 'styled-components'

interface ICPProps {
    children: React.ReactNode
    mode: 'close' | 'separeted'
}

/**
 * Botao generico customizavel.
 */
export function ButtonGroupCP(props: ICPProps): JSX.Element {

    if (props.mode === 'separeted') {
        return (
            <WrapperSeparetedSCP>
                { props.children }
            </WrapperSeparetedSCP>
        )
    }

    return (
        <ButtonGroup>
            { props.children }
        </ButtonGroup>
    )

}

const WrapperSeparetedSCP = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-left: 5px;
  }
`
