import React, { useRef, useState } from 'react'
import { WidgetsRowAdhesionCP } from 'modules/reports/components/widgets-row-adhesion/WidgetsRowAdhesionCP'
import { WidgetsRowEventsConversionCP } from 'modules/reports/components/widgets-row-events-conversion/WidgetsRowEventsConversionCP'
import { WidgetsRowGoalCP } from 'modules/reports/components/widgets-row-goal/WidgetsRowGoalCP'
import styled from 'styled-components'
import { DrawerReferralProgramReportFiltersCP } from 'modules/reports/components/drawer-referral-program-report-filters/DrawerReferralProgramReportFiltersCP'
import { ReportsCommonFiltersFormModel } from 'modules/reports/components/drawer-referral-program-report-filters/inner/ReportsCommonFiltersFormModel'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { HeaderButtonICP } from 'common/components/screen-layout/header/inner/HeaderButtonICP'
import moment from 'moment'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { useReactToPrint } from 'react-to-print'
import { PrintAreaCP } from 'submodules/nerit-framework-ui/common/components/print-area-cp/PrintAreaCP'

/**
 * Analise do programa de indicacao
 */
export function ScreenContentReferralProgramAnalysisCP(): JSX.Element {

    const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState<boolean>(true)

    const [formValidator, setFormValidator] = useState<ReportsCommonFiltersFormModel>((new ReportsCommonFiltersFormModel({ dateRange: { beginDate: moment().startOf('month').toDate(), endDate: new Date() } })))
    const formStateManager = useFormStateManager<ReportsCommonFiltersFormModel>(formValidator)

    const [shouldLoadReport, setShouldLoadReport] = useState<boolean>(false)

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        documentTitle: 'nc-dashboard',
        content: () => componentRef.current as any,
    })

    return (
        <>
            <HeaderCP title={'Relatório de Adesão'}>
                <HeaderButtonICP
                    onClick={() => setIsFilterDrawerVisible(!isFilterDrawerVisible)}
                    icon={'filter'}
                    label={'Filtros'}
                />
                <ButtonCP
                    onClick={handlePrint}
                    icon={'printer'}
                    marginLeft={10}
                    type={'ghost'}
                >
                    Imprimir
                </ButtonCP>
            </HeaderCP>

            <ContentCP overflow={'auto'}>
                <WrapperSCP>
                    <PrintAreaCP ref={componentRef}>
                        <WrapperPrintSCP>
                            <WidgetsRowAdhesionCP
                                shouldLoadReport={shouldLoadReport}
                                onLoadReport={() => setShouldLoadReport(false)}
                                filters={formStateManager}
                            />
                            <WidgetsRowEventsConversionCP
                                shouldLoadReport={shouldLoadReport}
                                onLoadReport={() => setShouldLoadReport(false)}
                                filters={formStateManager}
                            />
                            <WidgetsRowGoalCP
                                shouldLoadReport={shouldLoadReport}
                                onLoadReport={() => setShouldLoadReport(false)}
                                filters={formStateManager}
                            />
                        </WrapperPrintSCP>
                    </PrintAreaCP>
                </WrapperSCP>
            </ContentCP>

            <DrawerReferralProgramReportFiltersCP
                formStateManager={formStateManager}
                visible={isFilterDrawerVisible}
                onClearFilters={() => {
                    setFormValidator(new ReportsCommonFiltersFormModel({}))
                    setShouldLoadReport(true)
                }}
                onFilter={() => setShouldLoadReport(true)}
                onClose={() => setIsFilterDrawerVisible(false)}
                config={{ showDateInterval: true, showFranchises: true }}
                loading={shouldLoadReport}
            />
        </>
    )
}

const WrapperSCP = styled.div`
  margin-bottom: 100px;
`

const WrapperPrintSCP = styled.div`
  padding: 20px;
`
