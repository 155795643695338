import React from 'react'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface IAuthScreenCoverCPProps {
    imageUrl: string
    width?: number | string
}

/**
 * Imagem de fundo nas telas do modulo de AUTENTICACAO.
 */
export function ImageLayoutImageSiderICP(props: IAuthScreenCoverCPProps): JSX.Element {
    return (
        <SiderCP width={props.width ?? '50%'}>
            <WrapperSCP>

                <CoverSCP cover={props.imageUrl}>
                    <ColorSCP/>
                </CoverSCP>

                <BarSCP/>
            </WrapperSCP>
        </SiderCP>
    )
}

const WrapperSCP = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const CoverSCP = styled.div<{ cover: string }>`
  height: 100%;
  background-image: url(${props => props.cover});
  background-position: center;
  background-size: cover;
  display: flex;
`

const ColorSCP = styled.div`
  background: ${ThemeProject.loginCoverColor};
  opacity: 0.7;
  flex: 1;
`

const BarSCP = styled.div`
  height: 60%;
  width: 5px;
  background-color: ${ThemeProject.titleBarColor};
  position: absolute;
  right: -2px;
  top: 20%;
`
