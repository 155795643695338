import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LayoutImageSiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-image-sider/LayoutImageSiderCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { CreateAccountPersonFormModel } from 'modules/admin/components/wizard-create-account/inner/CreateAccountPersonFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { CreateAccountAccessFormModel } from 'modules/admin/components/wizard-create-account/inner/CreateAccountAccessFormModel'
import { WizardCreateAccountCP } from 'modules/admin/components/wizard-create-account/WizardCreateAccountCP'
import { ResultCreateAccountCP } from 'modules/admin/components/result-create-account/ResultCreateAccountCP'
import { useLocation } from 'react-router-dom'
import defaultImageUrl from 'submodules/nerit-framework-ui/common/components/screen/layout-image-sider/inner/images/rocket_building.gif'

/**
 * Tela para criacao de conta
 */
export function ScreenCreateAccount(): JSX.Element {

    const [personFormValidator, setPersonFormValidator] = useState<CreateAccountPersonFormModel>(new CreateAccountPersonFormModel())
    const personFormStateManager = useFormStateManager<CreateAccountPersonFormModel>(personFormValidator)

    const [accessFormValidator, setAccessFormValidator] = useState<CreateAccountAccessFormModel>(new CreateAccountAccessFormModel())
    const accessFormStateManager = useFormStateManager<CreateAccountAccessFormModel>(accessFormValidator)

    const [wasAccountCreated, setWasAccountCreated] = useState<boolean>(false)

    const routeLocation = useLocation()
    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {

        const params = new URLSearchParams(routeLocation.search)

        setPersonFormValidator(new CreateAccountPersonFormModel({
            name: params.get('name'),
            email: params.get('email'),
            cellphone: params.get('phone'),
        }))

        setAccessFormValidator(new CreateAccountAccessFormModel({
            plan: params.get('plan')
        }))
    }

    return (
        <LayoutImageSiderCP
            sider={{
                imageUrl: defaultImageUrl
            }}
            content={{
                logoUrl: `${process.env.PUBLIC_URL}/logo-sistema.png`,
                logoWidth: 200,
            }}
        >
            {
                wasAccountCreated
                    ?
                    <ResultCreateAccountCP
                        personFormStateManager={personFormStateManager}
                        accessFormStateManager={accessFormStateManager}
                    />
                    :
                    <>
                        <TitleWrapperSCP>
                            <TitleCP textSize={'large'}>
                                Crie sua conta agora!
                            </TitleCP>

                            <DescriptionSCP>
                                Olá! Seja bem-vindo, falta muito pouco para você começar a utilizar o Space4Leads. Por aqui vamos configurar a sua Conta!
                            </DescriptionSCP>
                        </TitleWrapperSCP>

                        <WizardCreateAccountCP
                            personFormStateManager={personFormStateManager}
                            accessFormStateManager={accessFormStateManager}
                            onCreateAccount={() => setWasAccountCreated(true)}
                        />
                    </>
            }
        </LayoutImageSiderCP>
    )
}

const TitleWrapperSCP = styled.div`
    text-align: center;
`

const DescriptionSCP = styled.div`
    margin-top: 5px;
    font-style: italic;
`
