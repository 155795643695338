import { Skeleton, Table } from 'antd'
import { PaginationConfig } from 'antd/lib/pagination'
import { ColumnProps, SorterResult, SortOrder, TableRowSelection } from 'antd/lib/table'
import { TableEventListeners } from 'antd/lib/table/interface'
import { DateUtilsOLD } from 'common/utils/DateUtilsOLD'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { OrUndefinedTP } from 'common/types/OrUndefinedTP'
import styled from 'styled-components'
import React from 'react'

/**
 * TODO: Mover para 01 arquivo proprio
 */
export type TablePaginationTP = {
    total: number,
    current: number,
    pageSize: number,
    showTotal?: (total: number, range: [number, number]) => React.ReactNode,
}

type _CustomPropsTP<RowTP> = {
    rowKeyGetter?: (row: RowTP, index: number) => string,
}

interface ITableCPProps<RowTP> {
    data?: RowTP[]
    columns?: Array<ColumnProps<RowTP>>
    pagination?: TablePaginationTP
    onPaginationChange?: (pagination: PaginationConfig) => void
    onSortingChange?: (sorting: SorterResult<RowTP>) => void
    rowKey?: (row: RowTP, index: number) => string
    bordered?: boolean
    rowSelection?: TableRowSelection<RowTP>
    loading?: boolean
    children?: React.ReactNode
    sortDirections?: SortOrder[]
    rowClassName?: (record: RowTP, index: number) => string
    showHeader?: boolean
    onRow?: (record: RowTP, index: number) => TableEventListeners
    showLoadingAsSkeleton?: boolean

    expandedRowRender?: (record: RowTP, index: number, indent: number, expanded: boolean) => React.ReactNode
    onExpand?: (expanded: boolean, record: RowTP) => void
}

/**
 * Tabela com estilo do sistema.
 *
 * TODO: Nao expor elementos do ant
 * TODO: Forcar definicao de key
 * TODO: Concluir refatoracao
 */
export function TableCP<RowTP = any>(props: _CustomPropsTP<RowTP> & ITableCPProps<RowTP>): JSX.Element {

    function onChangeHandler(
        pagination: PaginationConfig,
        filters: Partial<Record<keyof RowTP, string[]>>,
        sorter: SorterResult<RowTP>
    ): void {
        if (!!props.onPaginationChange)
            props.onPaginationChange(pagination)

        if (!!props.onSortingChange)
            props.onSortingChange(sorter)
    }

    function rowKeyGetter(row: RowTP, index: number): string {

        if (!!props.rowKeyGetter)
            return props.rowKeyGetter(row, index)

        const _row = (row as any)

        const defaultRowKey: number | string = (
            ((_row.code ?? _row.key ?? _row.id) as OrUndefinedTP<number | string>)
            ?? `table_${DateUtilsOLD.getFormatted(new Date(), DateFormatEnum.US_WITHOUT_TIME)}_${index}`
        )

        return defaultRowKey.toString()
    }

    /** Definicao do componente de tabela. */
    const tableAntComponent = (
        <TableComponent<RowTP>
            rowSelection={props.rowSelection}
            rowKey={rowKeyGetter}
            dataSource={props.data}
            columns={props.columns}
            pagination={props.pagination ? { position: 'bottom', size: 'small', ...props.pagination } : false}
            onChange={onChangeHandler}
            sortDirections={props.sortDirections}
            rowClassName={props.rowClassName}
            size={'small'}
            locale={{ emptyText: <EmptyCP description={'Nenhum dado encontrado para os filtros selecionados'}/> }}
            loading={props.loading}
            showHeader={props.showHeader !== false}
            onRow={props.onRow}
            expandedRowRender={props.expandedRowRender}
            onExpand={props.onExpand}
        >
            {props.children}
        </TableComponent>
    )

    return (
        <>
            {
                !!props.showLoadingAsSkeleton &&
                <SkeletonContentSCP loading={props.loading}>
                    <Skeleton loading={props.loading} active={true}>
                        {tableAntComponent}
                    </Skeleton>
                </SkeletonContentSCP>
            }
            {
                !props.showLoadingAsSkeleton &&
                tableAntComponent
            }
        </>
    )
}

const SkeletonContentSCP = styled.div<{loading?: boolean}>`
    margin: ${props => (props.loading ? '10px' : '0')};
`

const TableSCP = styled(Table)<{ bordered: boolean }>`
    width: 100%;
    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
        border: none;
    }
    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-placeholder {
        ${props => (props.bordered ? 'border: 1px solid #e8e8e8;' : 'border: none;')}
        ${props => (props.bordered ? 'border-radius: 10px;' : '')}
        background: none;
    }
    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body > table > thead {
        tr {
            th {
                color: ${props => props.theme.primaryColor};
                font-weight: bold;
                font-size: 0.8rem;
                border: none;
                padding-top: 11px;
                padding-bottom: 11px;
            }
        }
    }

    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body > table > tbody {
        tr:hover td {
            background: rgba(218, 225, 239, 0.3);
        }
        ${props => (props.bordered ? 'box-shadow: 0 0 0 1px #e8e8e8;' : '')}
        ${props => (props.bordered ? 'border-radius: 10px;' : '')}

    }
    &.ant-table-wrapper .ant-spin-nested-loading  .ant-pagination {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .ant-table-small{
        .ant-table-content {
            .ant-table-body {
                margin: 0;
                table > .ant-table-tbody > tr > td {
                    padding: 6px 6px;
                }
            }
        }
    }
`

const TableComponent = TableSCP as React.ComponentType as new <RowTP>() => Table<RowTP>
