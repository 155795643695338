import styled from 'styled-components'
import React from 'react'
import { HelpIconCP } from 'common/components/icons/HelpIconCP'

interface IHelpCPProps {
    tourId: number
    dataTour?: string
}

/**
 * Componente de ajuda do sistema, linkado com o intercom para mostrar o conteudo da ajuda.
 *
 * @author renatofs
 */
export function HelpCPOLD(props: IHelpCPProps): JSX.Element {

    return (
        <HelpSCP
            data-tour={props.dataTour}
            // onClick={() => IntercomUtils.startTour(props.tourId)}
        >
            <HelpIconCP
                size={14}
                color={'#FFF'}
            />
        </HelpSCP>
    )
}

const HelpSCP = styled.div`
    margin-left: 15px;
    cursor: pointer;
`
