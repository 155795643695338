import { MarketingContentVarsConfigTP } from 'modules/marketing/types/MarketingContentVarsConfigTP'

/**
 * TODO: Add desc
 * NOTE: Cuidado ao atualizar! Valores sao identificadores usados pelo modulo marketing
 */
export const MARKETING_CONTENT_VARS: MarketingContentVarsConfigTP[] = [
    {
        label: 'Nome',
        value: '#nome##'
    },
    {
        label: 'Sobrenome',
        value: '#sobrenome##'
    },
    {
        label: 'Nome Completo',
        value: '#nome_completo##'
    },
    {
        label: 'e-mail',
        value: '#email##'
    },
    {
        label: 'Data Atual',
        value: '#data_corrente##'
    }
]
