import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { TableSurveyAnswersCP } from 'modules/survey/components/table-survey-answers/TableSurveyAnswersCP'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { SurveysRequests } from 'submodules/space4leads-sdk/services/surveys/surveys/SurveysRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'

/**
 * Relatorio de respostas de NPS
 */
export function ScreenContentNpsAnswersReportCP(): JSX.Element {

    const [nps, setNps] = useState<SurveyResponseDTO>()

    const getNpsRequest = useRequest<SurveyResponseDTO>()
    useEffect(onGetNpsRequestChange, [getNpsRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa dados da tela
     */
    function init(): void {
        getNpsRequest.runRequest(SurveysRequests.getNps())
    }

    /**
     * Retorno da requisicao para pegar campanha de NPS
     */
    function onGetNpsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getNpsRequest, 'Não encontramos um NPS configurado'))
            return

        setNps(getNpsRequest.responseData)
    }

    return (
        <>
            <HeaderCP title={'Respostas do NPS'}/>

            <ContentCP overflow={'auto'}>
                <ContentSCP>
                    <LoadingOverlayCP show={getNpsRequest.isAwaiting}/>
                    {
                        !!nps &&
                        <TableSurveyAnswersCP
                            surveyCode={nps?.code}
                        />
                    }
                </ContentSCP>
            </ContentCP>
        </>
    )
}

const ContentSCP = styled.div`
  margin-bottom: 80px;
`
