export enum PlanEnum {
    BASIC = 'BASIC',
    IDEAL = 'IDEAL',
    PREMIUM = 'PREMIUM',
}

export enum PlanLabelEnum {
    BASIC = 'Essencial',
    IDEAL = 'Ideal',
    PREMIUM = 'Premium',
}
