import React, { useEffect, useState } from 'react'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { ProjectsRequests } from 'submodules/space4leads-sdk/services/accounts/ProjectsRequests'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { ProjectUsersFormModel } from 'modules/admin/components/modal-edit-project-user/inner/ProjectUsersFormModel'
import { AddUserToProjectSaveRequestDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/requests/AddUserToProjectSaveRequestDTO'
import { ProjectUsersFormICP } from 'modules/admin/components/modal-edit-project-user/inner/ProjectUsersFormICP'
import { UserProjectResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/UserProjectResponseDTO'

interface ICPProps {
    visible: boolean
    onSave: () => void
    onCancel: () => void
    projectUser: UserProjectResponseDTO
}

/**
 * Drawer de projeto.
 */
export function ModalEditProjectUserCP(props: ICPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<ProjectUsersFormModel>((new ProjectUsersFormModel()))
    const formStateManager = useFormStateManager<ProjectUsersFormModel>(formValidator)

    const addProjectUserRequest = useRequest<void>('none')
    useEffect(onAddProjectUserRequestChange, [addProjectUserRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        if (!props.visible)
            return

        setFormValidator(new ProjectUsersFormModel({
            name: props.projectUser.user.name,
            email: props.projectUser.user.email,
            permission: props.projectUser.permissions[0]
        }))
    }

    /**
     * Salva.
     */
    async function saveProjectUser(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const dto: AddUserToProjectSaveRequestDTO = {
            projectCode: props.projectUser.project.code,
            permission: formValues.permission,
            user: {
                name: formValues.name,
                email: formValues.email
            }
        }
        addProjectUserRequest.runRequest(ProjectsRequests.addUser(dto))

        // Coloca aqui para resetar o campo depois que voltar da API
        setFormValidator(new ProjectUsersFormModel())
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onAddProjectUserRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(addProjectUserRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            title={'Editar permissão'}
            visible={props.visible}
            onCancel={props.onCancel}
            width={450}
            onOk={saveProjectUser}
            actionLoading={addProjectUserRequest.isAwaiting}
        >
            <ProjectUsersFormICP
                formStateManager={formStateManager}
                isSaving={addProjectUserRequest.isAwaiting}
                isEdit={true}
            />
        </ModalCP>
    )
}
