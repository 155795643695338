import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { INotificationBulkSaveRequestDTO } from 'modules/marketing/services/notification/dtos/resquests/INotificationBulkSaveRequestDTO'

/**
 * Classe auxiliar para realizar requisiçoes do modulo de Notificacoes.
 */
export class NotificationRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/notifications`

    static bulkSave = (dto: INotificationBulkSaveRequestDTO): RequestConfigTP => ({
        url: `${NotificationRequests._MODULE_BASE}/bulk-save`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

}
