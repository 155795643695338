import { RoutingHelper } from 'config/RoutingHelper'
import { DevTestScreen } from 'common/screens/screen-dev-test/DevTestScreen'
import { Error404Screen } from 'common/screens/Error404Screen'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { StringUtils } from 'common/utils/StringUtils'
import { useEffect } from 'react'
import { NpsRoutes } from 'modules/survey/routes/NpsRoutes'
import { CsatRoutes } from 'modules/survey/routes/CsatRoutes'
import { ReferralProgramRoutes } from 'modules/referral-program/routes/ReferralProgramRoutes'
import { ReportsRoutes } from 'modules/reports/routes/ReportsRoutes'
import { UserProfileScreen } from 'modules/user/screens/screen-user-profile/UserProfileScreen'
import { PersonRoutes } from 'modules/person/routes/PersonRoutes'
import { AdminRoutes } from 'modules/admin/AdminRoutes'
import { AdminSpaceRoutes } from 'modules/admin-space/AdminSpaceRoutes'

const PRIVATE_ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(NpsRoutes.ROOT),
        path: NpsRoutes.ROOT,
        component: NpsRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(CsatRoutes.ROOT),
        path: CsatRoutes.ROOT,
        component: CsatRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(ReferralProgramRoutes.ROOT),
        path: ReferralProgramRoutes.ROOT,
        component: ReferralProgramRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(ReportsRoutes.ROOT),
        path: ReportsRoutes.ROOT,
        component: ReportsRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(PersonRoutes.USER_PROFILE),
        path: PersonRoutes.USER_PROFILE,
        component: UserProfileScreen,
    },
    {
        key: StringUtils.getSlugStyleString(AdminSpaceRoutes.ROOT),
        path: AdminSpaceRoutes.ROOT,
        component: AdminSpaceRoutes,
    },
    {
        key: StringUtils.getSlugStyleString(AdminRoutes.ROOT),
        path: AdminRoutes.ROOT,
        component: AdminRoutes,
    },
    {
        key: 'dev-test',
        path: 'dev-test',
        component: DevTestScreen,
    },
    {
        key: 'starnps',
        component: Error404Screen,
    },
]

/**
 * ROTEADOR
 * Define rotas que so podem ser acessadas apos realizar login.
 */
export function PrivateRouter(): JSX.Element {
    useEffect(() => RoutingHelper.setDefaultRoute(NpsRoutes.ROOT), [])
    return RoutingHelper.renderRoutingSwitch(PRIVATE_ROUTES_CONFIG)
}
