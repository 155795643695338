import React from 'react'
import { Collapse } from 'antd'
import styled from 'styled-components'
import { CollapseCPPanelTP } from 'common/components/collapse/inner/CollapseCPPanelTP'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { ExpandIconPosition } from 'antd/lib/collapse/Collapse'

interface ICollapseCPProps {
    panels: CollapseCPPanelTP[]
    defaultPanelKeys?: string[]
    bordered?: boolean
    bgColor?: string
    loading?: boolean
    lockPanels?: boolean
    showExpandArrow?: boolean
    expandIconPosition?: ExpandIconPosition
    accordion?: boolean
}

/**
 * COMPONENTE
 * Painel com blocos de conteudo colapsavel.
 */
export function CollapseCP(props: ICollapseCPProps): JSX.Element {
    return (
        <WrapperSCP>

            <LoadingOverlayCP show={props.loading ?? false}/>

            <Collapse
                accordion={props.accordion}
                bordered={props.bordered ?? false}
                defaultActiveKey={props.defaultPanelKeys ?? []}
                style={{ backgroundColor: props.bgColor ?? 'transparent' }}
                expandIconPosition={props.expandIconPosition}
            >
                {
                    props.panels.map((panel, i) => (
                        <Collapse.Panel
                            key={panel.key ?? `panel=${i}`}
                            header={panel.title}
                            extra={panel.extra}
                            disabled={panel.disabled ?? props.lockPanels}
                            showArrow={props.showExpandArrow ?? false}
                        >
                            {panel.content}
                        </Collapse.Panel>
                    ))
                }
            </Collapse>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

  .ant-collapse-content {
    
  }

  .ant-collapse-item {
    
    border: solid 1px #f9f9f9 !important;
    //background: #f9f9f9;
    margin-top: 7px;
    border-radius: 5px;

    .ant-collapse-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background: #f9f9f9;
    }
    
  }

  .ant-collapse-item-disabled {
    .ant-collapse-header {
      cursor: auto !important;
    }
  }
`
