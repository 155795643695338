import React from 'react'
import { IAutocompleteCommonProps } from 'common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { FormModel } from 'common/form-state-manager/FormModel'
import { INameAndCodeResponseDTO } from 'common/dtos/responses/INameAndCodeResponseDTO'
import { SurveysRequests } from 'submodules/space4leads-sdk/services/surveys/surveys/SurveysRequests'
import { AutocompletePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'

interface ICPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<INameAndCodeResponseDTO, FModelTP> {
    label: string
}

/**
 * Seletor de pesquisas CSAT
 */
export function SelectCsatsCP<FModelTP extends FormModel = any>(props: ICPProps<FModelTP>): JSX.Element {

    return (
        <AutocompletePickerCP<INameAndCodeResponseDTO, FModelTP>
            requestConfigGetter={SurveysRequests.csatNames}
            showOptionsOnLoad={true}
            // NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
            {...props/* eslint-disable-line react/jsx-props-no-spreading */}
        />
    )
}
