import React from 'react'
import styled from 'styled-components'

interface ICPProps {
    leftContent?: string | JSX.Element
}

/**
 * Footer das Landing Pages
 */
export function LandingPageFooter(props: ICPProps): JSX.Element {

    return (
        <FooterSCP>
            <FooterContentButtonsSCP hasLeftContent={!!props.leftContent}>
                <>
                    {
                        !!props.leftContent &&
                        <span>{ props.leftContent }</span>
                    }
                </>
                <span>Powered by Space4Leads</span>
            </FooterContentButtonsSCP>
        </FooterSCP>
    )
}

const FooterSCP = styled.div`
  margin-top: 80px;
  flex-shrink: 0;
  padding-bottom: 15px;
`

const FooterContentButtonsSCP = styled.div<{ hasLeftContent: boolean }>`
  display: flex;
  align-items: center;

  @media (min-width: 600px) {
    justify-content: ${props => props.hasLeftContent ? 'space-between' : 'flex-end'};
  }

  @media (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
    
    .ant-radio-group {
      margin-top: 30px;
    }
  }
`
