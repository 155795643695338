import React from 'react'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { TitleCP } from 'common/components/title/TitleCP'
import { HelmetCP } from 'common/components/helmet/HelmetCP'

interface IHeaderTitleICPProps {
    title: string | JSX.Element
    titleSize?: FontSizeTP
    noBackground?: boolean
}

/**
 * COMPONENTE
 * Titulo para cabecalho.
 *
 * @see HeaderCP
 */
export function HeaderTitleICP(props: IHeaderTitleICPProps): JSX.Element {

    if (typeof props.title !== 'string')
        return props.title

    return (
        <>
            <HelmetCP
                title={props.title}
            />
            <TitleCP
                textSize={props.titleSize}
                color={!props.noBackground ? ThemeAnt.primaryColor : ThemeAnt.tertiaryColor}
                marginBottom={2}
            >
                {props.title}
            </TitleCP>
        </>
    )
}
