import { FormModel } from 'common/form-state-manager/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { PlanEnum } from 'submodules/space4leads-sdk/services/accounts/enums/PlanEnum'
import { IsOptional } from 'class-validator'
import { IIntegrationKeys } from 'submodules/space4leads-sdk/services/accounts/types/IIntegrationKeys'

export class AccountFormModel extends FormModel {

    @IsRequired()
    name: string

    @IsRequired()
    isActive: boolean

    @IsRequired()
    plan: PlanEnum

    @IsOptional()
    integrationKeys: IIntegrationKeys

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
