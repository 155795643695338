import React from 'react'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import styled from 'styled-components'

interface ICPProps {
    isAccountActive: boolean
    disabledDate?: Date
    loggedUserName: string
    signNowLink?: string
}

/**
 * Area para mensagens de quem é do plano trial
 */
export function UserSystemAccountStatusMessageICP(props: ICPProps): JSX.Element {

    const screenSize = useScreenSize()

    let remaingDaysMsg: JSX.Element
    const remainingDays = DateUtils.getDiff(TimeBaseEnum.DAY, new Date(), props.disabledDate)

    // Caso não tenha data de desativacao ou ja tenha passado
    if (!!props.disabledDate && remainingDays < 0) {
        remaingDaysMsg = <>Seu período de avaliação <strong>terminou</strong>. Seu acesso será cancelado</>

    } else {
        let daysMsg: string
        switch (remainingDays) {
            case 0:
                daysMsg = 'Hoje'
                break
            case 1:
                daysMsg = 'Amanhã'
                break

            default:
                daysMsg = `em ${remainingDays} dias`
                break
        }
        remaingDaysMsg = <>Seu período de avaliação termina <strong>{daysMsg}</strong></>
    }

    if (props.isAccountActive && !props.disabledDate)
        return <></>

    return (
        <WrapperSCP isSmd={screenSize.smd}>
            {`Olá, ${StringUtils.getFirstName(props.loggedUserName)}. `}
            {remaingDaysMsg}
            {
                !!props.signNowLink &&
                <ButtonCP
                    type={'danger'}
                    onClick={() => NeritFrameworkRoutingHelper.openInNewTab(props.signNowLink!)}
                >
                    Assinar agora
                </ButtonCP>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ isSmd: boolean }>`
    height: ${props => props.isSmd ? '70px' : '50px'};
    display: flex;
    justify-content: center;
    align-items: center;
    
    strong {
      margin-left: 5px;
    }
    button {
        margin-left: 10px;
    }
`
