import { registerDecorator, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { ValidationTypes } from 'common/validation/ValidationTypes'
import * as _ from 'lodash'

@ValidatorConstraint({ name: ValidationTypes.IS_EMAIL })
class IsEmailConstraint implements ValidatorConstraintInterface {

    validate(value: string): boolean {
        const validator = new Validator()
        return _.isEmpty(value) || validator.isEmail(value)
    }

    defaultMessage(): string {
        return 'Email inválido'
    }
}

/**
 * DECORATOR de VALIDACAO
 * Valida e-mail
 *
 * @author guilherme.diniz
 * @author hjcostabr
 */
export function IsEmail(validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsEmailConstraint
        })
    }
}
