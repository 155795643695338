import React from 'react'
import { DragAndDropListCP } from 'common/components/drag-and-drop-list/DragAndDropListCP'
import { DragAndDropListItemICP } from 'common/components/drag-and-drop-list/inner/DragAndDropListItemICP'
import { DnDGoalConfigItemICP } from 'modules/referral-program/components/dnd-referral-program-goal-config/inner/DnDGoalConfigItemICP'
import { GoalFormModel } from 'modules/referral-program/components/modal-goal/inner/GoalFormModel'
import { SystemUtils } from 'common/utils/SystemUtils'
import { EmptyCP } from 'common/components/empty/EmptyCP'

interface ICPProps {
    goals: GoalFormModel[]
    onRemoveGoal: (index: number) => void
    onEditGoal: (goal: GoalFormModel, index: number) => void
    onChangeOrder: (goals: GoalFormModel[]) => void
}

/**
 * COMPONENTE da tabela de signatarios.
 */
export function DnDReferralProgramGoalConfigCP(props: ICPProps): JSX.Element {

    if (SystemUtils.isEmpty(props.goals))
        return <EmptyCP description={'Nenhuma meta adicionada'}/>

    return (
        <DragAndDropListCP
            dataSource={props.goals}
            renderContent={(item: GoalFormModel, index) => (
                <DragAndDropListItemICP id={index} index={index}>
                    <DnDGoalConfigItemICP
                        dataSourceItem={item}
                        itemOrder={index + 1}
                        onRemove={() => props.onRemoveGoal(index)}
                        onEdit={() => props.onEditGoal(item, index)}
                    />
                </DragAndDropListItemICP>
            )}
            onListOrderChange={(list) => props.onChangeOrder(list.map(dnd => dnd.item))}
        />
    )
}
