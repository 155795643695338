import React, { useEffect, useState } from 'react'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { SurveyConfigCP } from 'modules/survey/components/survey-config/SurveyConfigCP'
import { SurveyTypeEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/SurveyTypeEnum'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { RoutingHelper } from 'config/RoutingHelper'
import { NpsRoutes } from 'modules/survey/routes/NpsRoutes'
import { LoadingOverlayCP } from 'common/components/loading/loading-overlay/LoadingOverlayCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { SurveysRequests } from 'submodules/space4leads-sdk/services/surveys/surveys/SurveysRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'

/**
 * TELA do modulo NPS
 */
export function ScreenNpsConfig(): JSX.Element {

    const [survey, setSurvey] = useState<SurveyResponseDTO>()

    const getSurveyRequest = useRequest<SurveyResponseDTO>()
    useEffect(onGetSurveyRequestChange, [getSurveyRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa dados da tela
     */
    function init(): void {
        getSurveyRequest.runRequest(SurveysRequests.getNps())
    }

    /**
     * Retorno da requisicao para pegar campanha de NPS
     */
    function onGetSurveyRequestChange(): void {

        if (getSurveyRequest.isAwaiting || !getSurveyRequest.wasTried)
            return

        const isSuccess = (getSurveyRequest.isSuccess && !!getSurveyRequest.responseData)
        if (!isSuccess && getSurveyRequest.responseStatus !== HttpStatusEnum.NOT_FOUND) {
            NotificationHelper.error('Ops!', 'Erro ao obter nps')
            return
        }

        setSurvey(getSurveyRequest.responseData)
    }

    return (
        <>
            <HeaderCP
                title={'Configurações da Pesquisa de NPS'}
                onClickBack={() => RoutingHelper.historyPush(NpsRoutes.ROOT)}
            />

            {
                getSurveyRequest.wasTried
                    ?
                    <>
                        <AlertCP
                            message={'Não encontramos um NPS configurado ainda, siga os passos abaixo para criar um novo NPS.'}
                            type={'warning'}
                            show={!survey}
                        />
                        <SurveyConfigCP
                            type={SurveyTypeEnum.NPS}
                            survey={survey}
                        />
                    </>
                    :
                    <LoadingOverlayCP show={true}/>
            }
        </>
    )
}
