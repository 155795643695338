import React, { useEffect, useState } from 'react'
import md5 from 'md5'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { LockIconCP } from 'common/components/icons/LockIconCP'
import { MailIconCP } from 'common/components/icons/MailIconCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { AuthRequests } from 'modules/auth/services/AuthRequests'
import { AuthFormFooterCP } from 'modules/auth/components/auth-form-footer/AuthFormFooterCP'
import { UserLoginFormValidator } from 'modules/auth/components/user-login-form/inner/UserLoginFormValidator'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { IUserLoginRequestDTO } from 'modules/auth/services/dtos/request/IUserLoginRequestDTO'
import { AuthActions } from 'modules/auth/AuthActions'
import styled from 'styled-components'
import { SwitchCP } from 'common/components/switch/SwitchCP'
import { TextCP } from 'common/components/text/TextCP'
import { AuthResponseDTO } from 'submodules/space4leads-sdk/services/auth/dtos/responses/AuthResponseDTO'

const LOCAL_STORAGE_EMAIL_KEY = 'LOCAL_STORAGE_EMAIL_KEY'

interface ILoginFormICPProps {
    onRecoverPassword: () => void
    onLoginSuccess: () => void
}

/**
 * Formulario de login de usuario do sistema.
 */
export function LoginFormCP(props: ILoginFormICPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<UserLoginFormValidator>(new UserLoginFormValidator())
    const formStateManager = useFormStateManager(formValidator)

    const [shouldRememberMe, setShouldRememberMe] = useState<boolean>(true)

    const loginRequest = useRequest<AuthResponseDTO>()
    useEffect(onLoginRequestChange, [loginRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa dados se tiver no localstorage, ou seja, marcado no lembrar-me
     */
    function init(): void {

        // Se dominio ja tiver enviado da url, nao pega do localstorage
        const rememberedEmail = localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY) ?? undefined

        setFormValidator(new UserLoginFormValidator({
            email: rememberedEmail
        }))

    }

    /**
     * Realiza login
     */
    async function doLogin(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()!

        const loginDto: IUserLoginRequestDTO = {
            email: formValues.email,
            password: md5(formValues.password)
        }

        loginRequest.runRequest(AuthRequests.login(loginDto))
    }

    /**
     * Ao receber retorno da API no login
     */
    function onLoginRequestChange(): void {

        if (loginRequest.isAwaiting || !loginRequest.wasTried)
            return

        const isSuccess = (loginRequest.isSuccess && !!loginRequest.responseData)
        if (!isSuccess)
            return handleLoginFailure()

        const resDto = loginRequest.responseData as AuthResponseDTO
        return onLoginSuccess(resDto)
    }

    /** Se deu certo o login. */
    function onLoginSuccess(_loggedUser: AuthResponseDTO): void {
        AuthActions.login(_loggedUser.user, _loggedUser.token)
        props.onLoginSuccess()
    }

    /** Trata casos de erro no login. */
    function handleLoginFailure(): void {

        let errorNotification = 'Falha ao tentar realizar login'

        if (loginRequest.responseStatus === HttpStatusEnum.FORBIDDEN)
            errorNotification = 'Domínio inativo ou não reconhecido'

        else if ([HttpStatusEnum.UNAUTHORIZED, HttpStatusEnum.NOT_FOUND].includes(loginRequest.responseStatus!))
            errorNotification = 'e-mail ou senha inválidos'
        else
            console.error('FALHA - UserLoginFormCP.handleLoginFailure: ', 'Falha inesperada', loginRequest.responseData, loginRequest.error)

        RequestUtils.showDefaultErrorNotification(loginRequest.error, errorNotification)
    }

    return (
        <>
            <InputCP
                label={'E-mail'}
                fieldName={'email'}
                formStateManager={formStateManager}
                required={true}
                onFormSubmit={doLogin}
                icon={<MailIconCP/>}
            />

            <InputCP
                label={'Senha'}
                type={'password'}
                fieldName={'password'}
                formStateManager={formStateManager}
                required={true}
                onFormSubmit={doLogin}
                icon={<LockIconCP/>}
            />

            <RememberMeSCP>
                <TextCP text={'Lembrar-me'}/>
                <SwitchCP
                    isTextInside={false}
                    isChecked={shouldRememberMe}
                    onChange={(isChecked) => setShouldRememberMe(isChecked)}
                />
            </RememberMeSCP>

            <AuthFormFooterCP
                link={{
                    text: 'Esqueci minha senha',
                    onClick: props.onRecoverPassword,
                }}
                button={{
                    text: 'Entrar',
                    onClick: doLogin,
                }}
                awaiting={loginRequest.isAwaiting}
            />
        </>
    )
}

const RememberMeSCP = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  .ant-typography {
    margin-right: 10px;
  }
`
