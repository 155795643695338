import React from 'react'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { RouteConfigTP } from 'common/types/RouteConfigTP'

PersonRoutes.USER_PROFILE = '/profile'

const ROUTES_CONFIG: RouteConfigTP[] = []

/**
 * Componente que define as rotas para acesso ao CRM
 */
export function PersonRoutes(): JSX.Element {

    return (
        <AccessControlCP>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}
