import React from 'react'
import styled from 'styled-components'
import { CsatItemDateColumnICP } from 'modules/survey/components/list-item-csat-campaigns/inner/CsatItemDateColumnICP'
import { IconCP } from 'common/components/icons/IconCP'
import { CsatItemStatisticColumnICP } from 'modules/survey/components/list-item-csat-campaigns/inner/CsatItemStatisticColumnICP'
import { MathUtils } from 'common/utils/MathUtils'
import { CsatListItemActionsColumnICP } from 'modules/survey/components/list-item-csat-campaigns/inner/CsatListItemActionsColumnICP'
import { CsatItemPreviewICP } from 'modules/survey/components/list-item-csat-campaigns/inner/CsatItemPreviewICP'
import { ChartRadialCP } from 'submodules/nerit-framework-ui/common/components/chart/ChartRadialCP'
import { ISurveyListItemResponseDTO } from 'modules/survey/services/survey/dtos/response/ISurveyListItemResponseDTO'
import { ConditionalRenderCP } from 'common/components/conditional-render/ConditionalRenderCP'
import * as _ from 'lodash'
import { MaskUtils } from 'common/utils/MaskUtils'

interface IListItemCsatCampaignsCPProps {
    showActions: boolean
    onUpdateList?: () => void
    csatCampaign: ISurveyListItemResponseDTO
}

/**
 * Campos de cada item da listagem de campanhas de CSAT.
 */
export function ListItemCsatCampaignsCP(props: IListItemCsatCampaignsCPProps): JSX.Element {

    const goodScores = props.csatCampaign.statistics.totalAnsweredByScore.filter(score => score.score > 3)
    const okScores = props.csatCampaign.statistics.totalAnsweredByScore.filter(score => score.score === 3)
    const badScores = props.csatCampaign.statistics.totalAnsweredByScore.filter(score => score.score < 3)

    const totalAnswers = _.sumBy(props.csatCampaign.statistics.totalAnsweredByScore, score => score.total)

    return (
        <ListItemContainerSCP>

            <CsatItemDateColumnICP
                createDate={props.csatCampaign.survey.creationDate}
                isActive={true}
            />

            <CsatItemPreviewICP
                csatCampaign={props.csatCampaign}
            />

            <ChartContainerSCP>
                <ChartRadialCP
                    mainValue={MaskUtils.applyNumberMask(props.csatCampaign.statistics.averageScore, 2)}
                    data={[
                        MathUtils.getPercentage(_.sumBy(goodScores, score => score.total), totalAnswers, true),
                        MathUtils.getPercentage(_.sumBy(okScores, score => score.total), totalAnswers, true),
                        MathUtils.getPercentage(_.sumBy(badScores, score => score.total), totalAnswers, true),
                    ]}
                    height={250}
                />
            </ChartContainerSCP>

            <CsatItemStatisticColumnICP
                total={props.csatCampaign.statistics.totalAnsweredContacts}
                icon={<IconCP antIcon={'form'}/>}
                percent={MathUtils.getPercentage(props.csatCampaign.statistics.totalAnsweredContacts, props.csatCampaign.statistics.totaSentContacts)}
                label={'RESPONDERAM'}
            />

            <ConditionalRenderCP shouldRender={props.showActions}>
                <CsatListItemActionsColumnICP
                    onUpdateList={props.onUpdateList}
                    code={props.csatCampaign.survey.code}
                    surveyLink={props.csatCampaign.survey.mainSurveyLink}
                />
            </ConditionalRenderCP>
        </ListItemContainerSCP>
    )

}

const ListItemContainerSCP = styled.div`
  display: flex;
`

const ChartContainerSCP = styled.div`
  background: white;
`
